import { Recording, Transcription } from '@streem/sdk-core';
import { StreemshotId } from '@streem/domain-id';
import { useMediaBreakpoint, useObservable } from '@streem/sdk-react';
import { AppIcon, AppText, Box, Row, Skeleton, styled, Tooltip, useTheme } from '@streem/ui-react';
import {
    FC,
    KeyboardEvent as ReactKeyboardEvent,
    useEffect,
    useMemo,
    useState,
    useRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import { WallItem } from 'streem-sdk-protobuf';
import { useDetailSession } from '../../hooks/detail_session_hooks';
import { StreemshotData, useFetchStreemshotData } from '../../hooks/use_fetch_streemshot_data';
import { joinPaths } from '../../util/routing';
import appLogger from '../../util/logging/app_logger';
import { useTimeStampController } from '../../hooks/use_timestamp_controller';
import { WallItemSubtype } from '@streem/sdk-types';

const log = appLogger.extend('recording thumbnail');

function extractTranscriptionUrl(transcriptionArtifact: Transcription) {
    return transcriptionArtifact?.transcription?.url;
}

export const RecordingThumbnail: FC<{
    artifact: Recording;
    transcriptionArtifact?: Transcription | null;
    canSelect: boolean;
    isSelected: boolean;
    isSelectVisible: boolean;
    onSelect?: (artifactSid: string) => void;
    onPlay?: () => void;
}> = ({ artifact, transcriptionArtifact, ...props }) => {
    if (!artifact.recording) {
        log.error('Attempted to render non-recording artifact as a recording');
        return null;
    }

    return (
        <VideoThumbnail
            {...props}
            artifactSid={artifact.id}
            videoUrl={artifact.recording.downloadUrl}
            transcriptionUrl={extractTranscriptionUrl(transcriptionArtifact)}
        />
    );
};

export const RecordingTrackThumbnail: FC<{
    artifact: Recording;
    canSelect: boolean;
    isSelected: boolean;
    isSelectVisible: boolean;
    onSelect?: (artifactSid: string) => void;
    onPlay?: () => void;
}> = ({ artifact, ...props }) => (
    <VideoThumbnail
        {...props}
        artifactSid={artifact.id}
        videoUrl={artifact.recording.previewDownloadUrl}
    />
);

const VideoThumbnail: FC<{
    videoUrl?: string;
    transcriptionUrl?: string;
    artifactSid: string;
    canSelect: boolean;
    isSelected: boolean;
    isSelectVisible: boolean;
    onSelect?: (artifactSid: string) => void;
    onPlay?: () => void;
}> = ({
    videoUrl,
    transcriptionUrl,
    artifactSid,
    canSelect,
    isSelected,
    isSelectVisible: isAllSelectsVisible,
    onSelect,
    onPlay,
}) => {
    const [isSelectVisible, setIsSelectVisible] = useState(false);
    const [isWrapperFocused, setIsWrapperFocused] = useState(false);
    const [isSelectFocused, setIsSelectFocused] = useState(false);
    const [isMouseOver, setIsMouseOver] = useState(false);
    const timeStampController = useTimeStampController();

    useEffect(() => {
        if (!isMouseOver) {
            setIsSelectVisible(isAllSelectsVisible);
        }
    }, [isAllSelectsVisible, isMouseOver]);
    return (
        <ThumbnailWrapper
            aspectRatio={1}
            onMouseOver={() => setIsMouseOver(true)}
            onMouseEnter={() => !isAllSelectsVisible && setIsSelectVisible(true)}
            onMouseLeave={() => {
                setIsMouseOver(false);
                !isAllSelectsVisible && setIsSelectVisible(false);
            }}
            onFocus={() => setIsWrapperFocused(true)}
            onBlur={() => setIsWrapperFocused(false)}
            aria-label={isAllSelectsVisible ? 'select artifact' : 'view artifact details'}
        >
            <VideoWithPlayButton
                onMount={(videoElement: HTMLVideoElement) => {
                    timeStampController.registerVideoElement(videoElement);
                }}
                onPlay={onPlay}
                key={artifactSid}
                controls
                style={{
                    gridColumn: 'span 2',
                    width: '100%',
                    height: '100%',
                    maxHeight: '400px',
                    backgroundColor: '#000',
                }}
                data-testid={`recording-${artifactSid}`}
            >
                <source src={videoUrl} type="video/mp4" />
                <source src={videoUrl} type="video/3gpp" />
                <track default kind="captions" src={transcriptionUrl} srcLang="en" />
            </VideoWithPlayButton>
            {canSelect && (
                <>
                    <ThumbnailSelectBox
                        className={isSelectFocused ? 'focus-visible' : ''}
                        data-testid={`recording-select-button-${artifactSid}`}
                        isSelected={isSelected}
                        role="button"
                        onClick={() => onSelect?.(artifactSid)}
                        onKeyDown={(event: ReactKeyboardEvent) => {
                            if (event.key === 'Enter' || event.key === ' ') {
                                event.preventDefault();
                                onSelect?.(artifactSid);
                            }
                        }}
                        tabIndex={isAllSelectsVisible ? -1 : 0}
                        onFocus={() => setIsSelectFocused(true)}
                        onBlur={() => setIsSelectFocused(false)}
                        isVisible={
                            isAllSelectsVisible ||
                            isSelectVisible ||
                            isWrapperFocused ||
                            isSelectFocused
                        }
                        aria-label={'select artifact'}
                    >
                        {isSelected && <AppIcon name="DoneIcon" color="white" />}
                    </ThumbnailSelectBox>
                    <ThumbnailSelectedOverlay
                        isSelected={isSelected}
                        isAllSelectsVisible={isAllSelectsVisible}
                        onClick={() => onSelect?.(artifactSid)}
                    />
                </>
            )}
        </ThumbnailWrapper>
    );
};

export const StreemshotThumbnail: FC<{
    showTimestamp?: boolean;
    streemshot: WallItem.IStreemshot & { id: string; streemshotId?: string };
    canSelect: boolean;
    isSelected: boolean;
    isSelectVisible: boolean;
    onSelect: (artifactSid: string) => void;
    imageDataRef: React.MutableRefObject<{ [url: string]: StreemshotData }>;
}> = ({
    showTimestamp,
    streemshot,
    canSelect,
    isSelected,
    isSelectVisible: isAllSelectsVisible,
    onSelect,
    imageDataRef,
}) => {
    const history = useHistory();
    const detailSession = useDetailSession();
    const [isSelectVisible, setIsSelectVisible] = useState(false);
    const [isWrapperFocused, setIsWrapperFocused] = useState(false);
    const [isSelectFocused, setIsSelectFocused] = useState(false);
    const [isMouseOver, setIsMouseOver] = useState(false);
    const { isMobile } = useMediaBreakpoint();

    useEffect(() => {
        if (!isMouseOver) {
            setIsSelectVisible(isAllSelectsVisible);
        }
    }, [isAllSelectsVisible, isMouseOver]);

    const streemshotId = useMemo(
        () => new StreemshotId(streemshot?.streemshotId || streemshot.id),
        [streemshot.id, streemshot?.streemshotId],
    );
    const [note] = useObservable(detailSession.note.getStreemshotNote(streemshotId));
    const { dataURL, aspectRatio } = useFetchStreemshotData(
        streemshot?.downloadUrl ?? '',
        imageDataRef,
    );

    if (!dataURL || !aspectRatio) {
        return <Skeleton />;
    }

    return (
        <ThumbnailWrapper
            aspectRatio={aspectRatio}
            onMouseOver={() => setIsMouseOver(true)}
            onMouseEnter={() => !isAllSelectsVisible && setIsSelectVisible(true)}
            onMouseLeave={() => {
                setIsMouseOver(false);
                !isAllSelectsVisible && setIsSelectVisible(false);
            }}
            onFocus={() => setIsWrapperFocused(true)}
            onBlur={() => setIsWrapperFocused(false)}
            aria-label={isAllSelectsVisible ? 'select artifact' : 'view artifact details'}
        >
            <ThumbnailImage
                url={dataURL}
                data-testid={`streemshot-${streemshot.id}`}
                onClick={() => {
                    history.push(
                        joinPaths(history.location.pathname, `/artifact/${streemshot.id}`),
                    );
                }}
                onKeyDown={(event: ReactKeyboardEvent) => {
                    if (event.key === 'Enter' || event.key === ' ') {
                        event.preventDefault();
                        if (isAllSelectsVisible) {
                            onSelect?.(streemshot.id);
                        } else {
                            history.push(
                                joinPaths(history.location.pathname, `/artifact/${streemshot.id}`),
                            );
                        }
                    }
                }}
                role="button"
                aria-label="open image details"
                tabIndex={0}
            />
            {/* @ts-ignore */}
            {showTimestamp && streemshot?.clientCreatedAt && <TimeStamp streemshot={streemshot} />}
            {note?.text && (
                <ThumbnailNotesOverlay>
                    <ThumbnailNotes
                        as="p"
                        color="light"
                        data-testid={`streemshot-notes-${streemshot.id}`}
                    >
                        {note.text}
                    </ThumbnailNotes>
                </ThumbnailNotesOverlay>
            )}
            {canSelect && (
                <>
                    <ThumbnailSelectBox
                        className={isSelectFocused ? 'focus-visible' : ''}
                        data-testid={`streemshot-select-button-${streemshot.id}`}
                        isSelected={isSelected}
                        role="button"
                        onClick={() => onSelect?.(streemshot.id)}
                        onKeyDown={(event: ReactKeyboardEvent) => {
                            if (event.key === 'Enter' || event.key === ' ') {
                                event.preventDefault();
                                onSelect?.(streemshot.id);
                            }
                        }}
                        tabIndex={isAllSelectsVisible ? -1 : 0}
                        onFocus={() => setIsSelectFocused(true)}
                        onBlur={() => setIsSelectFocused(false)}
                        isVisible={
                            isAllSelectsVisible ||
                            isSelectVisible ||
                            isWrapperFocused ||
                            isSelectFocused
                        }
                        aria-label={'select artifact'}
                    >
                        {isSelected && <AppIcon name="DoneIcon" color="white" />}
                    </ThumbnailSelectBox>
                    {!isMobile && (
                        <ThumbnailSelectedOverlay
                            isSelected={isSelected}
                            isAllSelectsVisible={isAllSelectsVisible}
                            onClick={() => onSelect?.(streemshot.id)}
                        />
                    )}
                </>
            )}
        </ThumbnailWrapper>
    );
};

interface TimeStampProps {
    streemshot: StreemshotData &
        WallItemSubtype<WallItem, 'streemshot'> &
        Partial<WallItem['streemshotRevision']>;
}

const TimeStamp: React.FC<TimeStampProps> = ({ streemshot }) => {
    const [hovered, setHovered] = useState(false);
    const timeStampController = useTimeStampController();
    const theme = useTheme();
    if (!timeStampController.hasVideoArtifact) {
        return null;
    }
    return (
        <TimeStampButton
            data-testid={`streemshot-timestamp-button-${streemshot.id}`}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onClick={() => {
                const streemshotCreatedAtTime =
                    streemshot.clientCreatedAt.seconds.low +
                    streemshot.clientCreatedAt.nanos / 1000000000;
                timeStampController.jumpVideoToTimeStamp(streemshotCreatedAtTime);
            }}
        >
            <Tooltip
                width="230px"
                delay={800}
                showTooltip={hovered}
                placement="bottom"
                message="Go to the time in the call recording when this streemshot was taken."
            >
                <Row alignItems="center" justifyContent="center">
                    <TimeStampText hovered={hovered}>
                        <AppText size="large" color="white" style={{ fontSize: '16px' }}>
                            {timeStampController.getStreemShotTimeStamp(streemshot)}
                        </AppText>
                    </TimeStampText>
                    <Box pt={'4px'}>
                        <AppIcon
                            name="TimeStampArrowIcon"
                            size="medium"
                            color={theme.colors.white}
                        />
                    </Box>
                </Row>
            </Tooltip>
        </TimeStampButton>
    );
};

const TimeStampText = styled.p<{ hovered: boolean }>`
    position: relative;
    margin-right: 5px;
    border-bottom: 1px solid
        ${({ theme, hovered }) => (hovered ? theme.colors.white : 'transparent')};
`;
export const TimeStampButton = styled.button`
    position: absolute;
    border: 1px solid transparent;
    right: ${({ theme }) => theme.spacing.m};
    top: ${({ theme }) => theme.spacing.m};
    padding: 2px 8px 6px 8px;
    background: none;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.blackA40};
    &:hover {
        cursor: pointer;
        border: 1px solid ${({ theme }) => theme.colors.white};
    }
`;

const ThumbnailWrapper = styled.div<{
    aspectRatio: number;
}>(({ aspectRatio }) => ({
    gridColumn: `span ${aspectRatio < 1 ? 1 : 2}`,
    position: 'relative',
    overflow: 'hidden',
    flexGrow: 1,
}));

const ThumbnailImage = styled.div<{
    url: string | undefined;
}>(({ url }) => ({
    backgroundImage: `url(${url})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    cursor: 'pointer',
    width: '100%',
    height: '100%',
}));

const ThumbnailNotesOverlay = styled.div(() => {
    const streemshotNotesFadeHeight = '128px';
    const streemshotNotesMaxOpacity = '0.75';
    return {
        background: `linear-gradient(180deg, rgba(0,0,0,0) 0%,
          rgba(0,0,0,${streemshotNotesMaxOpacity}) ${streemshotNotesFadeHeight},
          rgba(0,0,0,${streemshotNotesMaxOpacity}) 100%)`,
        padding: `${streemshotNotesFadeHeight} 16px 16px`,
        color: 'white',
        position: 'absolute',
        bottom: '0',
        left: '0',
        right: '0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        overflow: 'hidden',
        pointerEvents: 'none',
    };
});

const ThumbnailNotes = styled(AppText)`
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 60px;
`;

const ThumbnailSelectBox = styled.div<{ isSelected: boolean; isVisible: boolean }>(
    ({ theme, isSelected, isVisible }) => ({
        position: 'absolute',
        zIndex: 1,
        top: theme.spacing.m,
        left: theme.spacing.m,
        backgroundColor: isSelected ? theme.colors.azure : theme.colors.white,
        height: isVisible ? theme.spacing.l : 0,
        width: isVisible ? theme.spacing.l : 0,
        border:
            isSelected && isVisible
                ? `solid 1px ${theme.colors.white}`
                : isVisible
                ? `solid 1px ${theme.colors.azure}`
                : 'none',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        ':focus:focus:not(.focus-visible)': {
            outline: 'inherit',
            boxShadow: 'inherit',
        },
    }),
);

const ThumbnailSelectedOverlay = styled.div<{
    isSelected: boolean;
    isAllSelectsVisible: boolean;
}>(({ isSelected, isAllSelectsVisible, theme }) => ({
    pointerEvents: isAllSelectsVisible ? 'auto' : 'none',
    cursor: isAllSelectsVisible ? 'pointer' : 'auto',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    border: isSelected ? `${theme.spacing.s} solid ${theme.colors.azureTransparent}` : '',
}));

const VideoWithPlayButton: React.FC<{
    key: string;
    style: { [key: string]: string | number };
    controls: boolean;
    onPlay?: () => void;
    onMount?: (video: HTMLVideoElement) => void;
}> = ({ children, onPlay, onMount, ...props }) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [showOverlay, setShowOverlay] = useState(true);

    useEffect(() => {
        const handlePlay = () => {
            setShowOverlay(false);
            onPlay?.();
        };
        const handlePause = () => {
            setShowOverlay(true);
        };
        const videoEl = videoRef.current;

        if (onMount) {
            onMount(videoEl);
        }

        if (videoEl) {
            videoEl.addEventListener('play', handlePlay);
            videoEl.addEventListener('pause', handlePause);
        }
        return () => {
            if (videoEl) {
                videoEl.removeEventListener('play', handlePlay);
                videoEl.removeEventListener('pause', handlePause);
            }
        };
    }, [videoRef, setShowOverlay, onMount, onPlay]);

    return (
        <>
            <video
                {...props}
                style={{ ...props.style, position: 'relative' }}
                ref={videoRef}
                crossOrigin="user-credentials"
            >
                {children}
            </video>
            <PlayButton
                data-testid="video-play-button-overlay"
                className={showOverlay ? 'fade-in' : 'fade-out'}
                onClick={() => videoRef.current!.play()}
            >
                <AppIcon name="PlayArrowIcon" />
            </PlayButton>
        </>
    );
};

const PlayButton = styled('button')`
    position: absolute;
    top: 50%;
    right: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 80px;
    border: 3px solid ${props => props.theme.colors.white};
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding-left: 15px;
    transform: translate(50%, -50%);
    cursor: pointer;

    &.fade-out {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 300ms, opacity 300ms;
    }
    &.fade-in {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear 0s, opacity 300ms;
    }
`;
