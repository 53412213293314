/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiGroup,
    StreemApiGroupFromJSON,
    StreemApiGroupFromJSONTyped,
    StreemApiGroupToJSON,
    StreemApiGroupReservationDetail,
    StreemApiGroupReservationDetailFromJSON,
    StreemApiGroupReservationDetailFromJSONTyped,
    StreemApiGroupReservationDetailToJSON,
    StreemApiUser,
    StreemApiUserFromJSON,
    StreemApiUserFromJSONTyped,
    StreemApiUserToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiGroupReservation
 */
export interface StreemApiGroupReservation {
    /**
     * 
     * @type {string}
     * @memberof StreemApiGroupReservation
     */
    companySid?: string;
    /**
     * 
     * @type {StreemApiGroup}
     * @memberof StreemApiGroupReservation
     */
    group?: StreemApiGroup;
    /**
     * 
     * @type {string}
     * @memberof StreemApiGroupReservation
     */
    reservationSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiGroupReservation
     */
    externalUserId?: string;
    /**
     * 
     * @type {number}
     * @memberof StreemApiGroupReservation
     */
    priority?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiGroupReservation
     */
    reserveForSeconds?: number;
    /**
     * 
     * @type {string}
     * @memberof StreemApiGroupReservation
     */
    reservationStatus?: StreemApiGroupReservationReservationStatus;
    /**
     * 
     * @type {number}
     * @memberof StreemApiGroupReservation
     */
    queuePosition?: number;
    /**
     * 
     * @type {StreemApiUser}
     * @memberof StreemApiGroupReservation
     */
    reservedUser?: StreemApiUser;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiGroupReservation
     */
    reservedUntil?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiGroupReservation
     */
    connectedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiGroupReservation
     */
    completedAt?: Date;
    /**
     * 
     * @type {Array<StreemApiGroupReservationDetail>}
     * @memberof StreemApiGroupReservation
     */
    details?: Array<StreemApiGroupReservationDetail>;
    /**
     * 
     * @type {string}
     * @memberof StreemApiGroupReservation
     */
    roomSid?: string;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiGroupReservation
     */
    initiatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiGroupReservation
     */
    failedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiGroupReservation
     */
    expiredAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiGroupReservation
     */
    requestedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiGroupReservation
     */
    estimatedWaitUntil?: Date;
    /**
     * 
     * @type {string}
     * @memberof StreemApiGroupReservation
     */
    referenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiGroupReservation
     */
    integrationId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiGroupReservation
     */
    reconnectPreviousReservationSid?: string;
}

export function StreemApiGroupReservationFromJSON(json: any): StreemApiGroupReservation {
    return StreemApiGroupReservationFromJSONTyped(json, false);
}

export function StreemApiGroupReservationFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiGroupReservation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companySid': !exists(json, 'company_sid') ? undefined : json['company_sid'],
        'group': !exists(json, 'group') ? undefined : StreemApiGroupFromJSON(json['group']),
        'reservationSid': !exists(json, 'reservation_sid') ? undefined : json['reservation_sid'],
        'externalUserId': !exists(json, 'external_user_id') ? undefined : json['external_user_id'],
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
        'reserveForSeconds': !exists(json, 'reserve_for_seconds') ? undefined : json['reserve_for_seconds'],
        'reservationStatus': !exists(json, 'reservation_status') ? undefined : json['reservation_status'],
        'queuePosition': !exists(json, 'queue_position') ? undefined : json['queue_position'],
        'reservedUser': !exists(json, 'reserved_user') ? undefined : StreemApiUserFromJSON(json['reserved_user']),
        'reservedUntil': !exists(json, 'reserved_until') ? undefined : (new Date(json['reserved_until'])),
        'connectedAt': !exists(json, 'connected_at') ? undefined : (new Date(json['connected_at'])),
        'completedAt': !exists(json, 'completed_at') ? undefined : (new Date(json['completed_at'])),
        'details': !exists(json, 'details') ? undefined : ((json['details'] as Array<any>).map(StreemApiGroupReservationDetailFromJSON)),
        'roomSid': !exists(json, 'room_sid') ? undefined : json['room_sid'],
        'initiatedAt': !exists(json, 'initiated_at') ? undefined : (new Date(json['initiated_at'])),
        'failedAt': !exists(json, 'failed_at') ? undefined : (new Date(json['failed_at'])),
        'expiredAt': !exists(json, 'expired_at') ? undefined : (new Date(json['expired_at'])),
        'requestedAt': !exists(json, 'requested_at') ? undefined : (new Date(json['requested_at'])),
        'estimatedWaitUntil': !exists(json, 'estimated_wait_until') ? undefined : (new Date(json['estimated_wait_until'])),
        'referenceId': !exists(json, 'reference_id') ? undefined : json['reference_id'],
        'integrationId': !exists(json, 'integration_id') ? undefined : json['integration_id'],
        'reconnectPreviousReservationSid': !exists(json, 'reconnect_previous_reservation_sid') ? undefined : json['reconnect_previous_reservation_sid'],
    };
}

export function StreemApiGroupReservationToJSON(value?: StreemApiGroupReservation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_sid': value.companySid,
        'group': StreemApiGroupToJSON(value.group),
        'reservation_sid': value.reservationSid,
        'external_user_id': value.externalUserId,
        'priority': value.priority,
        'reserve_for_seconds': value.reserveForSeconds,
        'reservation_status': value.reservationStatus,
        'queue_position': value.queuePosition,
        'reserved_user': StreemApiUserToJSON(value.reservedUser),
        'reserved_until': value.reservedUntil === undefined ? undefined : (value.reservedUntil.toISOString()),
        'connected_at': value.connectedAt === undefined ? undefined : (value.connectedAt.toISOString()),
        'completed_at': value.completedAt === undefined ? undefined : (value.completedAt.toISOString()),
        'details': value.details === undefined ? undefined : ((value.details as Array<any>).map(StreemApiGroupReservationDetailToJSON)),
        'room_sid': value.roomSid,
        'initiated_at': value.initiatedAt === undefined ? undefined : (value.initiatedAt.toISOString()),
        'failed_at': value.failedAt === undefined ? undefined : (value.failedAt.toISOString()),
        'expired_at': value.expiredAt === undefined ? undefined : (value.expiredAt.toISOString()),
        'requested_at': value.requestedAt === undefined ? undefined : (value.requestedAt.toISOString()),
        'estimated_wait_until': value.estimatedWaitUntil === undefined ? undefined : (value.estimatedWaitUntil.toISOString()),
        'reference_id': value.referenceId,
        'integration_id': value.integrationId,
        'reconnect_previous_reservation_sid': value.reconnectPreviousReservationSid,
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiGroupReservationReservationStatus {
    QUEUED = 'GROUP_RESERVATION_STATUS_QUEUED',
    CANCELED = 'GROUP_RESERVATION_STATUS_CANCELED',
    MATCHED = 'GROUP_RESERVATION_STATUS_MATCHED',
    CONNECTED = 'GROUP_RESERVATION_STATUS_CONNECTED',
    EXPIRED = 'GROUP_RESERVATION_STATUS_EXPIRED',
    NOTMATCHED = 'GROUP_RESERVATION_STATUS_NOT_MATCHED',
    COMPLETED = 'GROUP_RESERVATION_STATUS_COMPLETED',
    CONFIRMING = 'GROUP_RESERVATION_STATUS_CONFIRMING',
    INITIATED = 'GROUP_RESERVATION_STATUS_INITIATED',
    FAILED = 'GROUP_RESERVATION_STATUS_FAILED',
    INVALID = 'GROUP_RESERVATION_STATUS_INVALID'
}


