/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiGroupReservationDetail,
    StreemApiGroupReservationDetailFromJSON,
    StreemApiGroupReservationDetailFromJSONTyped,
    StreemApiGroupReservationDetailToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiCreateGroupReservationRequest
 */
export interface StreemApiCreateGroupReservationRequest {
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateGroupReservationRequest
     */
    externalUserId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiCreateGroupReservationRequest
     */
    queue?: boolean;
    /**
     * 
     * @type {number}
     * @memberof StreemApiCreateGroupReservationRequest
     */
    priority?: number;
    /**
     * 
     * @type {number}
     * @memberof StreemApiCreateGroupReservationRequest
     */
    reserveForSeconds?: number;
    /**
     * 
     * @type {Array<StreemApiGroupReservationDetail>}
     * @memberof StreemApiCreateGroupReservationRequest
     */
    details?: Array<StreemApiGroupReservationDetail>;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateGroupReservationRequest
     */
    referenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateGroupReservationRequest
     */
    integrationId?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCreateGroupReservationRequest
     */
    reconnectPreviousReservationSid?: string;
}

export function StreemApiCreateGroupReservationRequestFromJSON(json: any): StreemApiCreateGroupReservationRequest {
    return StreemApiCreateGroupReservationRequestFromJSONTyped(json, false);
}

export function StreemApiCreateGroupReservationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCreateGroupReservationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalUserId': !exists(json, 'external_user_id') ? undefined : json['external_user_id'],
        'queue': !exists(json, 'queue') ? undefined : json['queue'],
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
        'reserveForSeconds': !exists(json, 'reserve_for_seconds') ? undefined : json['reserve_for_seconds'],
        'details': !exists(json, 'details') ? undefined : ((json['details'] as Array<any>).map(StreemApiGroupReservationDetailFromJSON)),
        'referenceId': !exists(json, 'reference_id') ? undefined : json['reference_id'],
        'integrationId': !exists(json, 'integration_id') ? undefined : json['integration_id'],
        'reconnectPreviousReservationSid': !exists(json, 'reconnect_previous_reservation_sid') ? undefined : json['reconnect_previous_reservation_sid'],
    };
}

export function StreemApiCreateGroupReservationRequestToJSON(value?: StreemApiCreateGroupReservationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'external_user_id': value.externalUserId,
        'queue': value.queue,
        'priority': value.priority,
        'reserve_for_seconds': value.reserveForSeconds,
        'details': value.details === undefined ? undefined : ((value.details as Array<any>).map(StreemApiGroupReservationDetailToJSON)),
        'reference_id': value.referenceId,
        'integration_id': value.integrationId,
        'reconnect_previous_reservation_sid': value.reconnectPreviousReservationSid,
    };
}


