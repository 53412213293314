import config from '../config';
import { APICallState, APICallStore } from './api_call_store';
import { APIError, APITypes, StreemAPI } from '@streem/api';
import { computed } from 'mobx';
import {
    createSettingsDictionary,
    DisplayableSettingsDictionary,
    Setting,
    SettingMeta,
    SettingsDictionary,
} from '../util/company_settings';
import appLogger from '../util/logging/app_logger';

export const displayableSettings: DisplayableSettingsDictionary = {
    CUSTOMER_TS_AND_CS_URL: {
        label: "Additional T&C's for Customer",
        description: "Enter an additional T&C's URL for #COMPANY if needed.",
        superAdmin: true,
        order: 0,
        fieldType: 'URL',
        testId: 'customer-ts-and-cs-url',
    },
    PRO_TS_AND_CS_URL: {
        label: "Additional T&C's for Pro",
        description: "Enter an additional T&C's URL for #COMPANY if needed.",
        superAdmin: true,
        order: 1,
        fieldType: 'URL',
        testId: 'pro-ts-and-cs-url',
    },
    CUSTOMER_PRIVACY_POLICY_URL: {
        label: 'Privacy Policy',
        description: 'Enter a privacy policy URL for #COMPANY if needed.',
        superAdmin: true,
        order: 2,
        fieldType: 'URL',
        testId: 'customer-privacy-policy-url',
    },
    PRO_SURVEY_URL: {
        label: 'Pro Post-Streem Survey',
        description: 'Enter a URL for a short survey for a pro to take after a Streem call',
        superAdmin: true,
        order: 3,
        fieldType: 'URL',
        testId: 'pro-survey-url',
    },
    GPS_ENABLED: {
        label: 'GPS',
        description: 'Allow for agents to use GPS requests during live calls.',
        superAdmin: false,
        order: 5,
        testId: 'gps-enabled',
    },
    ANONYMOUS_SHARE_ENABLED: {
        label: 'Anonymous Share',
        description:
            'Allow users to share room data including streemshots and recordings with anyone outside the company.',
        superAdmin: false,
        order: 6,
        testId: 'anonymous-share-enabled',
    },
    SHOW_CUSTOMER_REDIRECT_URL: {
        label: 'Show Final Redirect URL',
        description:
            'Display the Final Redirect URL customization and allow Company Admins to enable or disable this feature',
        superAdmin: true,
        order: 8,
        testId: 'show-customer-redirect-url',
    },
    EXPERIMENTAL_FEATURES_ENABLED: {
        label: 'Experimental Features',
        description: 'Enables the experimental features dropdown to use unreleased features',
        superAdmin: true,
        order: 9,
        testId: 'experimental-features-enabled',
    },
    UNRELEASED_FEATURES_ENABLED: {
        label: 'Unreleased Features',
        description: 'Enables unreleased features to be displayed',
        superAdmin: true,
        order: 10,
        testId: 'unreleased-features-enabled',
    },
    FACE_TO_FACE_MODE_ENABLED: {
        label: 'Two-Way Video',
        description:
            "Enabling this starts calls with Expert's front-facing camera turned on for all calls (assuming they have a camera).",
        superAdmin: true,
        order: 11,
        testId: 'two-way-video-enabled',
    },
    SHOW_ANONYMOUS_SHARE_ENABLED: {
        label: 'Show Anonymous Share',
        description:
            'Display the Anonymous Share setting and allow Company Admins to enable or disable this feature',
        superAdmin: true,
        order: 13,
        testId: 'show-anonymous-share-enable',
    },
    SHOW_GPS_ENABLED: {
        label: 'Show GPS',
        description:
            'Display the GPS setting and allow Company Admins to enable or disable this feature',
        superAdmin: true,
        order: 14,
        testId: 'show-gps-enabled',
    },
    SHOW_CUSTOMER_COMPANY_WATERMARK: {
        label: 'Show Company Watermark Feature',
        description:
            'Display the Company Watermark customization and allow Company Admins to enable or disable this feature.',
        superAdmin: true,
        order: 15,
        testId: 'show-customer-company-watermark',
    },
    POST_CALL_ANNOTATIONS_ENABLED: {
        label: 'Enable Post-Call Annotations',
        description:
            'Allows Streemshots to be edited after a call has ended and saved as revisions that can be shared, or reverted as needed.',
        superAdmin: true,
        order: 16,
        testId: 'post-call-annotations-enabled',
    },
    CALL_FAVORITING_ENABLED: {
        label: 'Enable Call Favoriting',
        description: 'Allows users to favorite calls and view them in a separate tab.',
        superAdmin: true,
        order: 17,
        testId: 'call-favoriting-enabled',
    },
    GROUP_MANAGEMENT_ENABLED: {
        label: 'Enable Group Management',
        description: 'Allows Company Admins to organize and manage experts in groups they create.',
        superAdmin: true,
        order: 18,
        testId: 'group-management-enabled',
    },
    EXPERT_AVAILABILITY_ENABLED: {
        label: 'Enable Expert Availability',
        description: 'Allows users to use group reservations and expert availability statuses.',
        superAdmin: true,
        order: 19,
        testId: 'expert-availability-enabled',
    },
    EMBEDDED_REPORTS_ENABLED: {
        label: 'Enable Embedded Reports',
        description: 'Allows company Managers and Admins to access reports',
        superAdmin: true,
        order: 20,
        testId: 'embedded-reports-enabled',
    },
    ROOM_TTL_DAYS: {
        label: 'Data Retention Policy',
        description:
            'This is the number of days after a call that all the information about that call will be deleted',
        superAdmin: true,
        order: 21,
        testId: 'room-ttl-days',
    },
    CHIME_LOW_BANDWIDTH_OPTIMIZATION_ENABLED: {
        label: 'Chime Low Bandwidth Optimization',
        description: 'Enables low bandwidth optimization for Chime calls',
        superAdmin: true,
        order: -1,
        testId: 'low-bandwidth-optimization-enabled',
    },
};

export const displayableCustomizations: DisplayableSettingsDictionary = {
    COMPANY_LOGO_URL: {
        label: 'Company Logo Large',
        description: 'A large, full-color logo on a transparent background.',
        order: 0,
        testId: 'company-logo-url',
    },
    COMPANY_WATERMARK_URL: {
        label: 'Company Watermark',
        description: 'A small white logo on a transparent background.',
        order: 1,
        testId: 'company-watermark-url',
    },
    CUSTOMER_REDIRECT_URL: {
        label: 'Final Redirect URL',
        description: 'Customize where your customer is sent after a Streem call has ended.',
        order: 2,
        fieldType: 'URL',
        testId: 'customer-redirect-url',
    },
    CUSTOMER_TS_AND_CS_URL: {
        label: 'Terms and Conditions URL',
        description: "Direct your customers to your company's terms and conditions.",
        order: 4,
        fieldType: 'URL',
        testId: 'customer-ts-and-cs-url',
    },
    SWAC_HIDE_STREEM_BRANDING: {
        label: 'Remove Streem Branding',
        description: 'Remove "Powered by Streem" logo from experience.',
        superAdmin: true,
        order: 5,
        testId: 'hide-streem-branding',
    },
};

export const displayableDevSettings: DisplayableSettingsDictionary = {
    WEB_AR_ENABLED: {
        label: 'AR Tools',
        description: 'Enables AR Tools in EV2 when communicating with an AR-capable device.',
        order: -1,
        testId: 'web-ar-enabled',
    },
    CUSTOMER_SMS_MESSAGE_TEXT: {
        label: 'SMS Message Text',
        description: 'Customize the sms message text sent to the customer',
        order: -1,
        testId: 'customer-sms-message-text',
    },
};

export const displayableBetaSettings: DisplayableSettingsDictionary = {
    ROOM_OUTCOME_FEATURE_ENABLED: {
        label: 'Call Disposition',
        description:
            'Call Disposition allows users the ability to record the reason and resolution of their calls.',
        order: 1,
        helpLinkHref: 'https://support.streem.com/article/30-using-streem-call-disposition',
        helpLinkDataTestId: 'call-disposition-learnmore-link',
        testId: 'call-disposition-enabled',
    },
    ALLOW_PAUSE_RESUME_RECORDING: {
        label: 'Allow Pause/Resume Recording',
        description: 'Allows users to pause and resume recordings during a call.',
        order: 2,
        testId: 'allow-pause-resume-recording',
    },
};

export const displayableExperimentalSettings: DisplayableSettingsDictionary = {
    TRANSCRIPTION_ENABLED: {
        label: 'Transcription Enabled',
        description: 'Enables post-call transcriptions of the audio recording for a Streem Call.',
        order: 1,
    },
};

/**
 * Load all company settings (including settings that may contain sensitive information)
 * Examples: settings related to the in-call experience
 *
 * Map for display in the Admin views as well as within logic that determines user experience
 */
export class CompanySettingsStore {
    private loadSettingsStore = new APICallStore(
        StreemAPI.companies.listCompanySettings.bind(StreemAPI.companies),
        'listCompanySettings',
    );

    constructor(private readonly companyCode: string) {}

    public async getDataRetentionPolicyDefault(): Promise<string> {
        const response = await this.getSettingDefinition('ROOM_TTL_DAYS');
        return response._default;
    }

    // denotes completion of an API call from the the APICallStore class
    @computed
    public get complete() {
        return this.loadSettingsStore.complete;
    }

    @computed
    public get lastError(): APIError {
        return this.loadSettingsStore.lastError;
    }

    @computed
    public get state(): APICallState {
        return this.loadSettingsStore.state;
    }

    @computed
    public get settings(): Setting[] {
        if (!this.loadSettingsStore.response || !this.loadSettingsStore.response.settings) {
            return undefined;
        }
        return this.filterAndSortSettingsForSettingsPage(
            this.loadSettingsStore.response.settings,
            displayableSettings,
        );
    }

    @computed
    public get developerSettings(): Setting[] {
        if (!this.loadSettingsStore.response || !this.loadSettingsStore.response.settings) {
            return undefined;
        }

        return this.loadSettingsStore.response.settings
            .filter(s => s.name)
            .filter(s => !(s.name! in displayableSettings))
            .filter(s => !(s.name! in displayableCustomizations))
            .filter(s => !(s.name! in displayableBetaSettings))
            .filter(s => !(s.name! in displayableExperimentalSettings))
            .map((s, index) => this.toDeveloperSetting(s, index, displayableDevSettings[s.name!]));
    }

    @computed
    public get customizations(): Setting[] {
        if (!this.loadSettingsStore.response || !this.loadSettingsStore.response.settings) {
            return undefined;
        }

        return this.filterAndSortSettingsForSettingsPage(
            this.loadSettingsStore.response.settings,
            displayableCustomizations,
        );
    }

    @computed
    public get betaSettings(): Setting[] {
        if (!this.loadSettingsStore.response || !this.loadSettingsStore.response.settings) {
            return undefined;
        }
        return this.filterAndSortSettingsForSettingsPage(
            this.loadSettingsStore.response.settings,
            displayableBetaSettings,
        );
    }

    @computed
    public get experimentalSettings(): Setting[] {
        if (!this.loadSettingsStore.response || !this.loadSettingsStore.response.settings) {
            return undefined;
        }
        return this.filterAndSortSettingsForSettingsPage(
            this.loadSettingsStore.response.settings,
            displayableExperimentalSettings,
        );
    }

    @computed
    public get companyLogoUrl(): string | undefined {
        return this.settingsDictionary?.COMPANY_LOGO_URL?.value;
    }

    @computed
    public get companySurveyUrl(): string | undefined {
        return this.settingsDictionary?.PRO_SURVEY_URL?.value;
    }

    @computed
    public get streemSurveyUrl(): string | undefined {
        return this.settingsDictionary?.STREEM_SURVEY_URL?.value ?? config.defaultStreemSurveyUrl;
    }

    @computed
    public get proTsAndCsUrl(): string | undefined {
        return this.settingsDictionary?.PRO_TS_AND_CS_URL?.value;
    }

    @computed
    public get anonymousShareEnabled(): boolean {
        return this.settingsDictionary?.ANONYMOUS_SHARE_ENABLED?.value === 'true';
    }

    @computed
    public get gpsEnabled(): boolean {
        return this.settingsDictionary?.GPS_ENABLED?.value === 'true';
    }

    @computed
    public get webAREnabled(): boolean {
        return this.settingsDictionary?.WEB_AR_ENABLED?.value === 'true';
    }

    @computed
    public get switchWorkspacesEnabled(): boolean {
        return this.settingsDictionary?.SWITCH_WORKSPACES_ENABLED?.value === 'true';
    }

    @computed
    public get hideFormattedAddressEnabled(): boolean {
        return this.settingsDictionary?.HIDE_FORMATTED_ADDRESS_ENABLED?.value === 'true';
    }

    @computed
    public get faceToFaceModeEnabled(): boolean {
        return this.settingsDictionary?.FACE_TO_FACE_MODE_ENABLED?.value === 'true';
    }

    @computed
    public get experimentalFeatures(): boolean {
        return this.settingsDictionary?.EXPERIMENTAL_FEATURES_ENABLED?.value === 'true';
    }

    @computed
    public get unreleasedFeaturesEnabled(): boolean {
        return this.settingsDictionary?.UNRELEASED_FEATURES_ENABLED?.value === 'true';
    }

    @computed
    public get roomOutcomeFeatureEnabled(): boolean {
        return this.settingsDictionary?.ROOM_OUTCOME_FEATURE_ENABLED?.value === 'true';
    }

    @computed
    public get screenshareEnabled(): boolean {
        return this.settingsDictionary?.SCREENSHARE_ENABLED?.value === 'true';
    }

    @computed
    public get videoTrackPreviewEnabled(): boolean {
        return this.settingsDictionary?.VIDEO_TRACK_PREVIEW_ENABLED?.value === 'true';
    }

    @computed
    public get crmEmbedEnabled(): boolean {
        return this.settingsDictionary?.CRM_EMBED_ENABLED?.value === 'true';
    }

    @computed
    public get expertAvailabilityEnabled(): boolean {
        return this.settingsDictionary?.EXPERT_AVAILABILITY_ENABLED?.value === 'true';
    }

    @computed
    public get callMetricsEnabled(): boolean {
        return this.settingsDictionary?.CALL_METRICS_ENABLED?.value === 'true';
    }

    @computed
    public get showCustomerRedirectUrlEnabled(): boolean {
        return this.settingsDictionary?.SHOW_CUSTOMER_REDIRECT_URL?.value === 'true';
    }

    @computed
    public get showCustomerCompanyWatermarkEnabled(): boolean {
        return this.settingsDictionary?.SHOW_CUSTOMER_COMPANY_WATERMARK?.value === 'true';
    }

    @computed
    public get showAnonymousShareEnabled(): boolean {
        return this.settingsDictionary?.SHOW_ANONYMOUS_SHARE_ENABLED?.value === 'true';
    }

    @computed
    public get showGpsEnabled(): boolean {
        return this.settingsDictionary?.SHOW_GPS_ENABLED?.value === 'true';
    }

    @computed
    public get postCallAnnotationsEnabled(): boolean {
        return this.settingsDictionary?.POST_CALL_ANNOTATIONS_ENABLED?.value === 'true';
    }

    @computed
    public get callFavoritingEnabled(): boolean {
        return this.settingsDictionary?.CALL_FAVORITING_ENABLED?.value === 'true';
    }
    @computed
    public get groupManagementEnabled(): boolean {
        return this.settingsDictionary?.GROUP_MANAGEMENT_ENABLED?.value === 'true';
    }

    @computed
    public get allowPauseResumeRecording(): boolean {
        return this.settingsDictionary?.ALLOW_PAUSE_RESUME_RECORDING?.value === 'true';
    }

    @computed
    public get embeddedReportsEnabled(): boolean {
        return this.settingsDictionary?.EMBEDDED_REPORTS_ENABLED?.value === 'true';
    }

    @computed
    public get transcriptionsEnabled(): boolean {
        return this.settingsDictionary?.TRANSCRIPTION_ENABLED?.value === 'true';
    }

    @computed
    public get chimeNetworkAdaptationEnabled(): boolean {
        return this.settingsDictionary?.CHIME_NETWORK_ADAPTATION_ENABLED?.value === 'true';
    }

    @computed
    public get chimeLowBandwidthOptimizationEnabled(): boolean {
        return this.settingsDictionary?.CHIME_LOW_BANDWIDTH_OPTIMIZATION_ENABLED?.value === 'true';
    }

    @computed
    public get callDeactivateEnabled(): boolean {
        return this.settingsDictionary?.CALL_DEACTIVATE_ENABLED?.value === 'true';
    }

    @computed
    public get loading(): boolean {
        return this.loadSettingsStore.loading;
    }

    @computed
    public get loadingMore(): boolean {
        return this.loadSettingsStore.loading;
    }

    public async updateSetting(name: string, value: string): Promise<void> {
        await StreemAPI.companies.saveCompanySetting(this.companyCode, name, {
            value,
        });
        await this.loadSettingsStore.refresh();
    }

    public async getSettingDefinition(name: string) {
        try {
            const response = await StreemAPI.companies.getSettingDefinition(this.companyCode, {
                settingName: name,
            });
            return response.settingDefinition;
        } catch (error) {
            appLogger.error(`Error fetching default value for setting ${name}:`, error);
            return undefined;
        }
    }

    public async refresh() {
        await this.loadSettingsStore.refresh();
    }

    public loadSettings() {
        return this.loadSettingsStore.fetch(this.companyCode);
    }

    @computed
    private get settingsDictionary(): SettingsDictionary {
        if (!this.loadSettingsStore.response || !this.loadSettingsStore.response.settings) {
            return undefined;
        }
        return createSettingsDictionary(this.loadSettingsStore.response.settings);
    }

    private filterAndSortSettingsForSettingsPage(
        settings: APITypes.StreemApiSetting[],
        meta: DisplayableSettingsDictionary,
    ): Setting[] {
        return settings
            .filter(s => s.name && s.name in meta)
            .map(s => Object.assign({}, s, meta[s.name!]) as Setting)
            .sort((a, b) => a.order - b.order);
    }

    private toDeveloperSetting(
        setting: APITypes.StreemApiSetting,
        index: number,
        meta?: SettingMeta,
    ): Setting {
        return {
            ...setting,
            name: setting.name!,
            label: meta ? meta.label : setting.name!,
            order: index,
            description: meta ? meta.description : '',
            testId: meta ? meta.testId : '',
        };
    }
}
