/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreemApiCompany
 */
export interface StreemApiCompany {
    /**
     * 
     * @type {string}
     * @memberof StreemApiCompany
     */
    sid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCompany
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCompany
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StreemApiCompany
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StreemApiCompany
     */
    parentCompanySid?: string;
    /**
     * 
     * @type {number}
     * @memberof StreemApiCompany
     */
    subCompanyLimit?: number;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiCompany
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiCompany
     */
    activeStatusModifiedAt?: Date;
}

export function StreemApiCompanyFromJSON(json: any): StreemApiCompany {
    return StreemApiCompanyFromJSONTyped(json, false);
}

export function StreemApiCompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiCompany {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sid': !exists(json, 'sid') ? undefined : json['sid'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'parentCompanySid': !exists(json, 'parent_company_sid') ? undefined : json['parent_company_sid'],
        'subCompanyLimit': !exists(json, 'sub_company_limit') ? undefined : json['sub_company_limit'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'activeStatusModifiedAt': !exists(json, 'active_status_modified_at') ? undefined : (new Date(json['active_status_modified_at'])),
    };
}

export function StreemApiCompanyToJSON(value?: StreemApiCompany | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sid': value.sid,
        'code': value.code,
        'name': value.name,
        'active': value.active,
        'parent_company_sid': value.parentCompanySid,
        'sub_company_limit': value.subCompanyLimit,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'active_status_modified_at': value.activeStatusModifiedAt === undefined ? undefined : (value.activeStatusModifiedAt.toISOString()),
    };
}


