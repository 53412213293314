import { action, computed, observable } from 'mobx';
import { fromStream, IStreamListener } from 'mobx-utils';
import StreemSDK, { RoomSession, UserSession } from '@streem/sdk-core';
import { WallItemSubtype } from '@streem/sdk-types';
import { streem } from 'streem-sdk-protobuf';
import { GroupReservation, UserAvailabilityStatus } from '@streem/sdk-core/src/data/wall/user_wall';
import { processCallLogDetails } from '../util/remote_user_call_log';
import appLogger from '../util/logging/app_logger';

export class SDKStore {
    public readonly configured = fromStream(StreemSDK.configured, false);
    public readonly userSession = fromStream<UserSession | undefined>(StreemSDK.userSession);
    private roomSessionStream?: IStreamListener<RoomSession | undefined>;
    private userAvailabilityStatusStream?: IStreamListener<UserAvailabilityStatus | undefined>;
    private groupReservationStream?: IStreamListener<GroupReservation | undefined>;
    private smsDeliveryStatusStream?: IStreamListener<
        WallItemSubtype<streem.user.UserWallItem, 'smsDeliveryStatus'> | undefined
    >;

    // Once the user session initiates a room session, this will return that room session
    @computed
    public get roomSession(): IStreamListener<RoomSession | undefined> | undefined {
        if (this.roomSessionStream) {
            appLogger.info(
                `Getting roomSessionStream::roomId=${this.roomSessionStream.current?.roomId}`,
            );
            this.roomSessionStream.dispose();
            this.roomSessionStream = undefined;
        }

        if (this.userSession.current) {
            this.roomSessionStream = fromStream(this.userSession.current.roomSession);
            appLogger.info(
                `Getting from userSession roomSessionStream::roomId=${this.roomSessionStream?.current?.roomId}`,
            );
            return this.roomSessionStream;
        }

        return undefined;
    }

    @action
    public async getRoomCallLogHistory() {
        if (this.roomSession) {
            const calls = await this.roomSession?.current?.hostUser?.getHostUserCallLog();
            return processCallLogDetails(calls);
        }
    }

    @computed
    public get userAvailabilityStatus():
        | IStreamListener<UserAvailabilityStatus | undefined>
        | undefined {
        if (this.userAvailabilityStatusStream) {
            this.userAvailabilityStatusStream.dispose();
            this.userAvailabilityStatusStream = undefined;
        }

        if (this.userSession.current) {
            this.userAvailabilityStatusStream = fromStream(
                this.userSession.current.currentAvailabilityStatus,
            );
            return this.userAvailabilityStatusStream;
        }

        return undefined;
    }

    @computed
    public get groupReservation(): IStreamListener<GroupReservation | undefined> | undefined {
        if (this.groupReservationStream) {
            this.groupReservationStream.dispose();
            this.groupReservationStream = undefined;
        }

        if (this.userSession.current) {
            this.groupReservationStream = fromStream(this.userSession.current.groupReservations);
            return this.groupReservationStream;
        }

        return undefined;
    }

    @observable
    public getSmsInviteStatusBySid(
        invitationSid: string,
    ):
        | IStreamListener<
              WallItemSubtype<streem.user.UserWallItem, 'smsDeliveryStatus'> | undefined
          >
        | undefined {
        if (this.smsDeliveryStatusStream) {
            this.smsDeliveryStatusStream.dispose();
            this.smsDeliveryStatusStream = undefined;
        }

        if (this.userSession.current) {
            this.smsDeliveryStatusStream = fromStream(
                this.userSession.current.getSmsInviteStatusBySid(invitationSid),
            );
            return this.smsDeliveryStatusStream;
        }

        return undefined;
    }
}
