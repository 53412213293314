import { RoomOutcomeFormPayload } from '@streem/sdk-core';
import { streem } from 'streem-sdk-protobuf';

export function convertRoomOutcomePayloadToProtobuf(
    roomOutcomeReport: RoomOutcomeFormPayload,
): streem.api.Artifact.IRoomOutcomeReport {
    if (!roomOutcomeReport.customFields || Object.keys(roomOutcomeReport?.customFields).length === 0) {
        return {
            ...roomOutcomeReport,
            customFields: null,
        };
    }

    const { refund, training } = roomOutcomeReport.customFields;
    const customFields = {
        training: {
            boolValue: training,
        },
        refund: {
            structValue: {
                fields: {
                    // eslint-disable-next-line camelcase
                    should_refund: {
                        boolValue: refund.shouldRefund,
                    },
                    ...(refund.reason && {
                        reason: {
                            structValue: {
                                fields: {
                                    code: {
                                        stringValue: refund.reason.code,
                                    },
                                    label: {
                                        stringValue: refund.reason.label,
                                    },
                                },
                            },
                        },
                    }),
                },
            },
        },
    };

    const protoRoomOutcomeReport = {
        ...roomOutcomeReport,
        customFields,
    };

    return protoRoomOutcomeReport;
}

export function convertProtobufToRoomOutcome(
    roomOutcomeReport: streem.api.Artifact.IRoomOutcomeReport,
): RoomOutcomeFormPayload {
    if (!roomOutcomeReport.customFields || Object.keys(roomOutcomeReport?.customFields).length === 0) {
        return {
            ...roomOutcomeReport,
            customFields: null,
        } as RoomOutcomeFormPayload;
    }

    const refund = roomOutcomeReport.customFields?.refund;
    const training = roomOutcomeReport.customFields?.training;

    const customFields = {
        training: training?.boolValue,
        refund: {
            // handle either 'should_refund or 'shouldRefund' to account for old data
            // eslint-disable-next-line camelcase
            shouldRefund: refund?.structValue?.fields?.should_refund
                ? // eslint-disable-next-line camelcase
                  refund?.structValue?.fields?.should_refund.boolValue
                : refund?.structValue?.fields?.shouldRefund.boolValue,
            ...(refund?.structValue?.fields?.reason && {
                reason: {
                    code: refund?.structValue?.fields?.reason.structValue?.fields?.code.stringValue,
                    label: refund?.structValue?.fields?.reason.structValue?.fields?.label
                        .stringValue,
                },
            }),
        },
    };

    const roomOutcome = {
        ...roomOutcomeReport,
        customFields,
    };

    return roomOutcome as RoomOutcomeFormPayload;
}
