import { APITypes } from '@streem/api';
import Papa from 'papaparse';

export interface CSVInput {
    type: 'callDiagnosisOption' | 'callDispositionOption' | 'refundOption' | 'trainingOption';
    label: string;
    code?: string;
    trade?: string;
    item?: string;
    style?: string;
}

export interface Properties {
    trade: string;
    item: string;
    style: string;
}

export interface TransformedData {
    diagnosis: APITypes.StreemApiCompanyRoomOutcomeConfigSection;
    disposition: APITypes.StreemApiCompanyRoomOutcomeConfigSection;
    customFields: { [key: string]: APITypes.StreemApiCompanyRoomOutcomeConfigSection };
}

export const transformOptions = (options: CSVInput[]) => {
    const transformedData: TransformedData = {
        diagnosis: {
            options: [],
        },
        disposition: {
            options: [],
        },
        customFields: {},
    };

    options.forEach(option => {
        const { type, label, trade, item, style } = option;
        const code = option.code?.trim() || label.toLowerCase().trim();
        const entry = { label, code };

        let properties;
        if (trade || item || style) {
            properties = { trade: trade || '', item: item || '', style: style || '' };
        }

        if (type === 'callDiagnosisOption') {
            transformedData.diagnosis.options.push({
                entry,
                ...(properties && { properties }),
            });
        } else if (type === 'callDispositionOption') {
            transformedData.disposition.options.push({ entry });
        } else {
            const customField = type.replace('Option', '');
            if (!transformedData.customFields[customField]) {
                transformedData.customFields[customField] = { options: [] };
            }
            if (customField !== 'training') {
                transformedData.customFields[customField].options.push({ entry });
            }
        }
    });

    return transformedData;
};

export const truncateFileName = (fileName?: string, maxLength = 15) => {
    if (!fileName) return '';
    if (fileName.length <= maxLength) {
        return fileName;
    }

    const extensionIndex = fileName.lastIndexOf('.');
    const extension = fileName.slice(extensionIndex);
    const truncatedName = fileName.slice(0, maxLength - extension.length);

    return `${truncatedName}..${extension}`;
};

export const validateCsvUpload = (input: Papa.ParseResult<unknown>) => {
    if (input.data.length === 0) {
        return 'File cannot be empty';
    }
    if (input.meta.fields.some(field => field.includes('_'))) {
        return 'Duplicate headers detected';
    }
    for (const item of input.data as CSVInput[]) {
        if (!('type' in item) || !('label' in item)) {
            return 'Invalid headers: missing type or label';
        }
    }

    // All input is valid
    return null;
};

export const customFilterOption = (candidate: { label: string; value: string }, input: string) => {
    if (input) {
        const words = input
            .trim()
            .toLowerCase()
            .split(/,\s*|\s+/);
        return words.every(word => candidate.label.toLowerCase().includes(word));
    }
    return true;
};
