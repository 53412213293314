import { AppIcon, AppText, Box, Button, Column, Row, useTheme } from '@streem/ui-react';
import { Form, Formik } from 'formik';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { DiagnosisDetailSelection } from './call_diagnosis';
import { DispositionDetailSection } from './call_disposition';
import appLogger from '../../util/logging/app_logger';
const log = appLogger.extend('roomOutcomeForm');

import { RoomOutcomeFormPayload, Diagnosis, Disposition, DetailSession } from '@streem/sdk-core';

import { streem } from 'streem-sdk-protobuf';
import { convertRoomOutcomePayloadToProtobuf } from '../../util/convert_room_outcome_protobuf';
import { useRoomOutcomeFormConfig } from '../../hooks/use_room_outcome_form_config';

interface Props {
    trade?: string;
    onSuccess: () => void;
    detailSession: DetailSession;
}

export type DispositionFormStatus = 'diagnosis' | 'disposition' | 'dispositionOnly' /* Only disposition is allowed on this form */;

export interface RawDiagnosisOption {
    jobID: string;
    style: string;
    jobDescription: string;
    trade: string;
    item: string;
}

const buildDiagnosisListOptions = (
    formConfig: streem.api.ICompanyRoomOutcomeConfig,
    tradeFilter?: string,
) => {
    // Get the cumulative list of trades from the provided options
    const tradeList = formConfig.diagnosis.options.reduce((acc, opt) => {
        if (opt.properties && opt.properties.trade && !acc.includes(opt.properties.trade)) {
            acc.push(opt.properties.trade);
            return acc;
        }
        return acc;
    }, []);

    // If a trade filter is provided, check that it matches one of the provided trade in the options
    const tradeFilterMatched = tradeList.includes(tradeFilter);
    if (tradeList.length > 0 && tradeFilter && !tradeFilterMatched) {
        log.error('No trade match found in provided options list for: ', tradeFilter);
    }

    const diagnosisListOptions = formConfig.diagnosis.options
        .filter(option => {
            if (tradeFilter && tradeFilterMatched) {
                return option.properties && option.properties.trade && option.properties.trade === tradeFilter;
            } else {
                return true;
            }
        })
        .map(option => {
            const trade = option.properties && option.properties.trade ? option.properties.trade + ' - ' : '';
            return {
                code: option.entry.code,
                label: `${tradeFilter && tradeFilterMatched ? '' : trade}${option.entry.label}`,
            };
        })
        .sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            } else {
                return 1;
            }
        });

    return diagnosisListOptions;
};

const initialFormValues: RoomOutcomeFormPayload = {
    diagnoses: [],
    disposition: { code: '', label: '' },
    customFields: {},
};

const parseFormConfig = (formConfig: streem.api.ICompanyRoomOutcomeConfig) => {
    initialFormValues.customFields = {}; // Reset custom fields

    if (formConfig?.customFields?.training) {
        initialFormValues.customFields.training = false;
    }

    if (formConfig?.customFields?.refund) {
        initialFormValues.customFields.refund = {
            shouldRefund: false,
            reason: undefined,
        };
    }
};

export const RoomOutcomeForm: FC<Props> = ({ onSuccess, detailSession, trade }) => {
    const formConfig = useRoomOutcomeFormConfig();

    const [status, setStatus] = useState<DispositionFormStatus>();
    const [diagnoses, setDiagnoses] = useState<Diagnosis[]>([]);
    const diagnosisOptions = useMemo(() => {
        if (!formConfig || !Object.keys(formConfig).length) return [];
        parseFormConfig(formConfig);

        if (formConfig.diagnosis?.options?.length > 0) {
            setStatus('diagnosis');
            return buildDiagnosisListOptions(formConfig, trade);
        } else {
            setStatus('dispositionOnly');
            return [];
        }
    }, [formConfig, trade]);

    const roomId = detailSession.roomId.toString();

    useEffect(() => {
        log.info(`RoomOutcomeReport pending. roomId=${roomId} roomOutcomeEvent=report_pending`);
    }, [roomId]);

    return (
        <Formik
            enableReinitialize
            initialValues={initialFormValues}
            validateOnChange={true}
            onSubmit={formValues => {
                const baseRoomOutcomeReport = {
                    status: streem.api.Artifact.Status.STATUS_FULFILLED,
                    diagnoses: formValues.diagnoses.map(d => {
                        // This function ensures the original label from the form config is used and NOT a concatenated version.
                        const match = formConfig.diagnosis.options.find( opt => opt.entry.code === d.code );
                        return { code: match.entry.code, label: match.entry.label }
                    }),
                    disposition: formValues.disposition,
                    customFields: formValues.customFields,
                };
                const protobufRoomOutcomeReport =
                    convertRoomOutcomePayloadToProtobuf(baseRoomOutcomeReport);
                detailSession.personalizations.addRoomOutcomeReport(protobufRoomOutcomeReport);
                onSuccess();
            }}
        >
            {({ setFieldValue, values, ...props }: any) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                            minHeight: '600px',
                            minWidth: '500px',
                            overflow: 'visible',
                            padding: '10px 5px',
                        }}
                    >
                        <Form
                            data-testid="room-outcome-form"
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-evenly',
                                flexGrow: 1,
                            }}
                            onKeyDown={e => {
                                if (e.key === 'Enter' || e.key === 'Return') {
                                    e.preventDefault();
                                }
                            }}
                        >
                        {status && <>
                            <StatusHeading status={status} />
                            <DetailHeader status={status} />
                            <div style={{ display: 'flex', flexGrow: 1, width: '100%' }}>
                                {status === 'diagnosis' && (
                                    <DiagnosisDetailSelection
                                        diagnosisOptions={diagnosisOptions}
                                        diagnoses={diagnoses}
                                        handleSetDiagnoses={(
                                            callback: (diagnosis: Diagnosis) => Diagnosis,
                                            newDiagnosis?: Diagnosis,
                                        ) => {
                                            if (newDiagnosis) {
                                                setDiagnoses(diagnoses => [
                                                    ...diagnoses,
                                                    newDiagnosis,
                                                ]);
                                                return;
                                            } else {
                                                setDiagnoses(diagnoses =>
                                                    diagnoses
                                                        ?.map(d => callback(d))
                                                        .filter(Boolean),
                                                );
                                            }
                                        }}
                                    />
                                )}
                                {(status === 'disposition' || status === 'dispositionOnly') && (
                                    <DispositionDetailSection
                                        formValues={{
                                            disposition: values.disposition,
                                            shouldRefund: values.customFields?.refund?.shouldRefund,
                                            refundReason: values.customFields?.refund?.refundReason,
                                            training: values.customFields?.training,
                                        }}
                                        dispositionOptions={formConfig.disposition}
                                        customFieldOptions={formConfig.customFields}
                                        handleSetDisposition={(disp: Disposition) => {
                                            setFieldValue('disposition', disp);
                                        }}
                                        handleToggleShouldRefund={() => {
                                            const { customFields } = values;
                                            if (!customFields?.refund) {
                                                return;
                                            }
                                            const { refund } = customFields;
                                            setFieldValue('customFields', {
                                                ...customFields,
                                                refund: {
                                                    ...customFields.refund,
                                                    shouldRefund: !refund.shouldRefund,
                                                },
                                            });
                                        }}
                                        handleSetRefundReason={(reason: {
                                            code: string;
                                            label: string;
                                        }) => {
                                            const { customFields } = values;
                                            if (!customFields?.refund) {
                                                return;
                                            }
                                            const { refund } = customFields;
                                            setFieldValue('customFields', {
                                                ...customFields,
                                                refund: {
                                                    ...refund,
                                                    reason: reason,
                                                },
                                            });
                                        }}
                                        handleToggleTrainingCall={() => {
                                            const { customFields } = values;
                                            if (customFields?.training === undefined) {
                                                return;
                                            }
                                            const { training } = customFields;
                                            setFieldValue('customFields', {
                                                ...customFields,
                                                training: !training,
                                            });
                                        }}
                                    />
                                )}
                            </div>
                            <ButtonFooter
                                disableNext={diagnoses.filter(d => d.label !== '').length === 0}
                                disableDone={
                                    values.disposition.code === '' ||
                                    (values.customFields?.refund?.shouldRefund &&
                                        values.customFields?.refund?.reason === undefined)
                                }
                                status={status}
                                handleBack={() => {
                                    setStatus('diagnosis');
                                    setFieldValue('customFields', {
                                        ...values.customFields,
                                        refund: {
                                            shouldRefund: false,
                                            reason: undefined,
                                        },
                                    });
                                }}
                                handleNext={() => {
                                    const nonNullDiagnoses = diagnoses.filter(d => d.label !== '');
                                    setFieldValue('diagnoses', nonNullDiagnoses);
                                    setDiagnoses(nonNullDiagnoses);
                                    setStatus('disposition');
                                }}
                            />
                        </>}
                        </Form>
                    </div>
                );
            }}
        </Formik>
    );
};

const DetailHeader: React.FC<{ status: DispositionFormStatus }> = ({ status }) => {
    return (
        <Column mt={4} mb={3}>
            <Box mb="20px">
                <AppText size="xlarge" headingFontFamily bold>
                    {`${status === 'diagnosis' ? 'Diagnosis' : 'Disposition'} Detail`}
                </AppText>
            </Box>
            <AppText size="medium">{`Select the ${
                status === 'diagnosis' ? 'Diagnosis' : 'Disposition'
            } that describes the call`}</AppText>
        </Column>
    );
};

interface ButtonFooterProps {
    status: DispositionFormStatus;
    handleBack: () => void;
    handleNext: () => void;
    disableNext: boolean;
    disableDone: boolean;
}
const ButtonFooter: React.FC<ButtonFooterProps> = ({
    status,
    handleBack,
    handleNext,
    disableNext,
    disableDone,
}) => {
    return (
        <>
            {status === 'diagnosis' && (
                <Row justifyContent={'space-between'}>
                    <Button
                        width="150px"
                        data-testid="diagnosis-back-btn"
                        type="button"
                        variant="secondary"
                        disabled
                    >
                        Back
                    </Button>
                    <Button
                        width="150px"
                        data-testid="diagnosis-next-btn"
                        type="button"
                        variant="primary"
                        onClick={handleNext}
                        disabled={disableNext}
                    >
                        Next
                    </Button>
                </Row>
            )}
            {status === 'disposition' && (
                <Row justifyContent={'space-between'}>
                    <Button
                        width="150px"
                        data-testid="disposition-back-btn"
                        type="button"
                        variant="secondary"
                        onClick={handleBack}
                    >
                        Back
                    </Button>
                    <Button
                        width="150px"
                        data-testid="disposition-submit-btn"
                        type="submit"
                        variant="primary"
                        disabled={disableDone}
                    >
                        Done
                    </Button>
                </Row>
            )}
            {status === 'dispositionOnly' && (
                <Button
                    width="150px"
                    data-testid="disposition-submit-btn"
                    type="submit"
                    variant="primary"
                    disabled={disableDone}
                    style={{ alignSelf: 'flex-end'}}
                >
                    Done
                </Button>
            )}
        </>
    );
};

const DiagnosisIndicatorIcon: React.FC<{
    isActive: boolean;
}> = ({ isActive }) => {
    const theme = useTheme();
    const { azure, grey30, white } = theme.colors;

    return (
        <div
            style={{
                width: '24px',
                height: '24px',
                padding: '2px',
                borderRadius: '12px',
                background: isActive ? azure : grey30,
                color: theme.colors.white,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {isActive && <AppText color="white">1</AppText>}
            {!isActive && <AppIcon name="DoneIcon" color={white} />}
        </div>
    );
};

const DiagnosisIndicatorGroup: React.FC<{ isActive: boolean }> = ({ isActive }) => {
    const textColor = isActive ? undefined : 'grey40';

    return (
        <>
            <DiagnosisIndicatorIcon isActive={isActive} />
            <Box ml={3}>
                <AppText color={textColor} semibold>
                    Diagnosis
                </AppText>
            </Box>
        </>
    );
};

const StatusHeading: React.FC<{ status: DispositionFormStatus }> = ({ status }) => {
    const theme = useTheme();
    const { grey30, grey10 } = theme.colors;
    return (
        <Box borderBottom={`1px solid ${grey10}`}>
            <Box mb={4}>
                <AppText size="medium" uppercase bold>
                    Post Call Log
                </AppText>
            </Box>
            {status !== 'dispositionOnly' &&
                <Row ml={1} mb="28px">
                    <DiagnosisIndicatorGroup isActive={status === 'diagnosis'} />
                    <Row justifyContent="center" alignItems="center" mx={3}>
                        <div style={{ borderTop: `2px solid ${grey30}`, width: '30px' }} />
                    </Row>
                    <DispositionIndicatorGroup isActive={status === 'disposition'} />
                </Row>
            }
        </Box>
    );
};

const DispositionIndicatorGroup: React.FC<{ isActive: boolean }> = ({ isActive }) => {
    const textColor = isActive ? 'black' : 'grey40';
    return (
        <>
            <DispositionIndicatorIcon isActive={isActive} />
            <Box ml={3}>
                <AppText color={textColor} semibold>
                    Disposition
                </AppText>
            </Box>
        </>
    );
};

const DispositionIndicatorIcon: React.FC<{
    isActive: boolean;
}> = ({ isActive }) => {
    const theme = useTheme();
    const { azure, grey30, transparent } = theme.colors;
    const border = isActive ? 'none' : `2px solid ${grey30}`;

    return (
        <div
            style={{
                width: '24px',
                height: '24px',
                border: border,
                borderRadius: '12px',
                background: isActive ? azure : transparent,
                color: theme.colors.white,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {isActive && <AppText color="white">2</AppText>}
        </div>
    );
};
