import { useMediaBreakpoint, useObservable } from '@streem/sdk-react';
import { Modal, Spinner, styled } from '@streem/ui-react';
import { Observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory, useParams } from 'react-router-dom';
import { streem } from 'streem-sdk-protobuf';
import { StreemshotDetailsView } from '../../components/artifact_details/artifact_details_view';
import { CallDetailsView } from '../../components/call_details/call_details_view';
import { RoomOutcomeForm } from '../../components/room_outcome/room_outcome_form';
import {
    DetailSessionProvider,
    useDetailSession,
    useInitializeDetailSession,
} from '../../hooks/detail_session_hooks';
import { useGlobalStore } from '../../hooks/use_global_context';
import { useRoomOutcomeReportRequired } from '../../hooks/use_room_outcome_report_required';
import { removeSegments } from '../../util/routing';
import { PageColumn } from '../page_column';
/**
 * Store wrapper for the call details page
 */
export const CallDetailsPage: FC<RouteComponentProps> = () => {
    const { roomSid, artifactSid, token } = useParams<{
        roomSid: string;
        artifactSid: string;
        token?: string;
    }>();
    const detailSession = useInitializeDetailSession(roomSid, token);

    if (!detailSession) {
        return (
            <SpinnerWrapper>
                <Spinner />
            </SpinnerWrapper>
        );
    }

    return (
        <DetailSessionProvider value={detailSession}>
            <PageColumn dataTestId="call-details-page">
                <LoadedCallDetails artifactSid={artifactSid} />
            </PageColumn>
        </DetailSessionProvider>
    );
};

const LoadedCallDetails: FC<{ artifactSid: string }> = ({ artifactSid }) => {
    const history = useHistory();
    const { authStore } = useGlobalStore();
    const detailSession = useDetailSession();
    const [room] = useObservable(detailSession.room.room);
    const [participants = []] = useObservable(detailSession.users.users);
    const [roomStatus] = useObservable(detailSession.room.roomStatus);
    const liveRoom = roomStatus === streem.api.Room.Status.STATUS_OPEN;
    const isParticipant = participants.some(participant => participant.id === authStore.userId);
    const writeAccess = !liveRoom && ((authStore.isAgent && isParticipant) || authStore.isAdmin);
    const [showRoomOutcomeModal, setShowRoomOutcomeModal] = useState(false);
    const tradeDetail = room?.details.find(
        d => d.label === 'Trade' || d.label === 'Trade.Description',
    );
    const roomOutcomeReportRequired = useRoomOutcomeReportRequired();
    const { isMobile } = useMediaBreakpoint();

    useEffect(() => {
        if (roomOutcomeReportRequired && !showRoomOutcomeModal) {
            setShowRoomOutcomeModal(true);
        }
        // intentionally ignore showRoomOutcomeModal
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomOutcomeReportRequired]);

    return (
        <Observer>
            {() => (
                <>
                    {showRoomOutcomeModal && roomOutcomeReportRequired && (
                        <Modal
                            hideCloseButton
                            isOpen
                            closeOnOverlayClick={false}
                            onClose={() => {
                                setShowRoomOutcomeModal(false);
                            }}
                            styleOverride={{
                                content: {
                                    overflow: 'visible',
                                },
                            }}
                        >
                            <RoomOutcomeForm
                                trade={tradeDetail?.value}
                                onSuccess={() => setShowRoomOutcomeModal(false)}
                                detailSession={detailSession}
                            />
                        </Modal>
                    )}
                    <CallDetailsView readOnly={!writeAccess} />
                    {!!artifactSid && (
                        <Modal
                            renderOnlyChildren={true}
                            isOpen={true}
                            onClose={() =>
                                history.push(removeSegments(history.location.pathname, 2))
                            }
                            contentStyle={{
                                padding: '0',
                            }}
                            styleOverride={{
                                overlay: {
                                    overflow: 'hidden',
                                },
                            }}
                        >
                            <StreemshotDetailsView isMobile={isMobile} readOnly={!writeAccess} />
                        </Modal>
                    )}
                </>
            )}
        </Observer>
    );
};

const SpinnerWrapper = styled.div({
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
});
