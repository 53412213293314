import { StreemAPI } from '@streem/api';
import StreemAuth from '@streem/auth';
import StreemSDK from '@streem/sdk-core';
import Analytics, { RegionCode } from '@streem/analytics';
import { configureReactLogger, audio } from '@streem/sdk-react';
import { GlobalStyles, LoadingOverlay, ThemeProvider } from '@streem/ui-react';
import { FC, useEffect, useState } from 'react';
import config from './config';
import appLogger from './util/logging/app_logger';

import { useGetCompanyRegistration } from './hooks/use_get_company_registration';
import { addRegionCodeToDatadogRumSession } from './util/datadog';
import RedirectToWorkspacesPage from './pages/authentication/redirect_to_workspaces_page';
import { CookiesDisabledPage } from './pages/authentication/cookies_disabled_page';

export const RegionAwareApp: FC = ({ children }) => {
    const { error, regionalApiUrl, regionCode, wallUrl } = useGetCompanyRegistration();
    const [configuredPackages, setConfiguredPackages] = useState(false);

    useEffect(() => {
        if (!window.navigator.cookieEnabled || !regionalApiUrl || !regionCode || !wallUrl) return;

        appLogger.setContextValue('regionCode', regionCode);
        addRegionCodeToDatadogRumSession(regionCode);
        audio.initialize();
        StreemAuth.configure({
            regionalApiUrl,
            globalApiUrl: config.urls.globalApi,
            appId: config.appId,
            logger: appLogger.extend('auth'),
            centralLoginAppName: config.centralLoginAppName,
        });

        StreemAPI.configure({
            apiUrl: regionalApiUrl,
            appId: config.appId,
            tokenFetcher: StreemAuth.tokenFetcher,
        });

        Analytics.configure({
            appId: config.appId,
            regionCode: regionCode as RegionCode,
        });

        StreemSDK.configure({
            appId: config.appId,
            appVersion: config.buildTag,
            logger: appLogger.extend('sdk-core'),
            googleMapsKey: config.googleMapsKey,
            wallUrl,
        });

        configureReactLogger(appLogger.extend('sdk-react'));

        setConfiguredPackages(true);
    }, [regionalApiUrl, regionCode, wallUrl]);

    if (!window.navigator.cookieEnabled) {
        return (
            <ThemeProvider>
                <GlobalStyles />
                <CookiesDisabledPage />
            </ThemeProvider>
        );
    }

    if (error) {
        return (
            <ThemeProvider>
                <GlobalStyles />
                <RedirectToWorkspacesPage />
            </ThemeProvider>
        );
    }

    if (!configuredPackages) {
        return (
            <ThemeProvider>
                <GlobalStyles />
                <LoadingOverlay />
            </ThemeProvider>
        );
    }

    return <>{children}</>;
};
