import { FC, useEffect, useState } from 'react';
import { google, streem } from 'streem-sdk-protobuf';

import {
    AppText,
    Box,
    Button,
    Column,
    Modal,
    Subheader,
    Row,
    styled,
    Theme,
    IconButton,
} from '@streem/ui-react';

export const UpcomingCall: FC<{
    customerDetails: streem.api.GroupReservation.IDetail[] | null;
    expirationTime: google.protobuf.ITimestamp;
    secondsReserved: number;
    isReconnect: boolean;
}> = ({ customerDetails, expirationTime, secondsReserved, isReconnect }) => {
    const [timeToDisplay, setTimeToDisplay] = useState(Math.round(secondsReserved / 60));
    const [expanded, setExpanded] = useState(true);
    const [customerName, setCustomerName] = useState('');

    useEffect(() => {
        if (expirationTime) {
            const expirationTimeInSeconds = convertProtobufToSeconds(expirationTime);
            const timer = window.setInterval(() => {
                setTimeToDisplay(calculateMinutesRemaining(expirationTimeInSeconds));
            }, 1_000);
            return () => window.clearInterval(timer);
        }
    }, [expirationTime]);

    useEffect(() => {
        if (customerDetails && customerDetails.length > 0) {
            const nameField = customerDetails.find(detail => {
                return (
                    detail.label.toLowerCase().includes('customer') ||
                    detail.label.toLowerCase().includes('name')
                );
            });
            setCustomerName(nameField?.value || '');
        }
    }, [customerDetails]);

    // The banner should clear when the reservation is expired or the user status changes, but just in case
    // we will show 'less than 1 minute' as our lowest possible countdown to not show 0 or negative numbers

    return (
        <Box style={{ width: '100%' }} data-testid="banner-container">
            <Row
                style={{ height: '42px', width: '100%' }}
                alignItems="center"
                justifyContent="space-between"
            >
                <Row alignItems="center">
                    <Subheader data-testid="reservation-banner-header" size="xlarge" bold>
                        Upcoming call {customerName && `with ${customerName}`}
                    </Subheader>
                    <ReservedPill data-testid="reserved-icon" headingFontFamily bold>
                        Reserved
                    </ReservedPill>
                    {isReconnect && (
                        <ReconnectPill data-testid="reconnect-icon" headingFontFamily bold>
                            Reconnection
                        </ReconnectPill>
                    )}
                    <AppText data-testid="expiration-text">
                        This reservation will expire in {timeToDisplay < 1 ? 'less than' : ''}{' '}
                        {timeToDisplay < 1 ? 1 : timeToDisplay} minute
                        {timeToDisplay > 1 ? 's' : ''}.
                    </AppText>
                </Row>
                <IconButton
                    iconName="CaretIcon"
                    label="expand"
                    fill={Theme.colors.black}
                    data-testid="expand-banner-button"
                    style={{
                        transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: 'transform 0.2s ease-in-out',
                    }}
                    onClick={() => {
                        setExpanded(!expanded);
                    }}
                />
            </Row>
            {expanded && (
                <ExpandedBanner>
                    <DetailsContainer data-testid="call-details-section">
                        {customerDetails
                            .filter(item => item.value !== customerName)
                            .map(({ label, value }) => {
                                return (
                                    <Column key={label}>
                                        <AppText
                                            as="h3"
                                            data-testid={`${label}-label`}
                                            headingFontFamily
                                            semibold
                                        >
                                            {label}
                                        </AppText>
                                        <TruncatedText data-testid={`${label}-value`}>
                                            {value}
                                        </TruncatedText>
                                    </Column>
                                );
                            })}
                    </DetailsContainer>
                </ExpandedBanner>
            )}
        </Box>
    );
};

const DetailsContainer = styled(Box)(({ theme }) => ({
    display: 'grid',
    rowGap: '20px',
    gridTemplateColumns: 'repeat(5, 1fr)',
    columnGap: '32px',
    marginTop: '16px',
    overflowY: 'auto',
    border: `solid 1px ${theme.colors.grey30}`,
    borderRadius: '10px',
    padding: '24px',
}));

const TruncatedText = styled(AppText)({
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
});

const ExpandedBanner = styled(Box)({
    width: '100%',
    paddingTop: '18px',
    paddingLeft: '24px',
    paddingRight: '24px',
});

interface SetReadyModalProps {
    message: string;
    closeModal: () => void;
    isOpen: boolean;
    setActive: () => void;
}
export const SetReadyModal: FC<SetReadyModalProps> = ({
    message,
    setActive,
    isOpen,
    closeModal,
}) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={closeModal}
            styleOverride={{
                content: {
                    maxWidth: '650px',
                    padding: Theme.spacing.l,
                },
            }}
        >
            <Column justifyContent="space-between">
                <Subheader style={{ marginBottom: '24px' }}>Set yourself to ready?</Subheader>
                <AppText>{message}</AppText>
                <Row alignSelf="flex-end" marginTop="36px">
                    <Button
                        ml={5}
                        variant="secondary"
                        onClick={closeModal}
                        data-testid="availability-away-modal-no-thanks-button"
                    >
                        No thanks
                    </Button>
                    <Button
                        onClick={async () => {
                            await setActive();
                            closeModal();
                        }}
                        ml={4}
                        data-testid="availability-away-modal-set-ready-button"
                    >
                        Set to ready
                    </Button>
                </Row>
            </Column>
        </Modal>
    );
};

export const PageLoadModal: FC<{
    isOpen: boolean;
    closeModal: () => void;
    setNotReady: () => void;
}> = ({ isOpen, closeModal, setNotReady }) => {
    return (
        <Modal
            isOpen={isOpen}
            hideCloseButton
            closeOnEscape={false}
            onClose={() => {
                closeModal();
            }}
            styleOverride={{
                content: {
                    width: '580px',
                    padding: Theme.spacing.l,
                },
            }}
        >
            <Subheader style={{ marginBottom: '24px' }}>Still ready to take calls?</Subheader>
            <AppText>Please confirm your availability.</AppText>
            <Row justifyContent={'flex-end'} width="100%" marginTop="36px">
                <Button
                    onClick={async () => {
                        await setNotReady();
                        closeModal();
                    }}
                    variant="secondary"
                    style={{ marginRight: '16px' }}
                    data-testid="availability-modal-not-ready-button"
                >
                    I'm not ready
                </Button>
                <Button onClick={() => closeModal()} data-testid="availability-modal-ready-button">
                    I'm ready
                </Button>
            </Row>
        </Modal>
    );
};

const ReservedPill = styled(AppText)(({ theme }) => ({
    backgroundColor: theme.colors.yellow10,
    padding: '2px 8px',
    borderRadius: '6px',
    marginLeft: '12px',
    marginRight: '12px',
}));

const ReconnectPill = styled(AppText)(({ theme }) => ({
    backgroundColor: theme.colors.blue05,
    padding: '2px 8px',
    borderRadius: '6px',
    marginRight: '12px',
}));

const calculateMinutesRemaining = (expirationTime: number) => {
    const currentTimeInSeconds = Date.now() / 1000;
    return Math.round((expirationTime - currentTimeInSeconds) / 60);
};

export const convertProtobufToSeconds = (time: google.protobuf.ITimestamp) => {
    return time?.seconds?.low || 0 + (time?.nanos || 0) / 1000000000;
};
