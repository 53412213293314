import { useEffect, useReducer } from 'react';

export interface StreemshotData {
    dataURL: string;
    aspectRatio: number;
    clientCreatedAt?: {
        seconds: {
            low: number;
            high: number;
            unsigned: boolean;
        };
        nanos: number;
    };
}

enum ActionType {
    FETCHING = 'FETCHING',
    FETCHED = 'FETCHED',
    FETCH_ERROR = 'ERROR',
}

type Action =
    | {
          type: ActionType.FETCHING;
      }
    | {
          type: ActionType.FETCHED;
          payload: StreemshotData;
      }
    | {
          type: ActionType.FETCH_ERROR;
          payload: {
              error: string | Event;
          };
      };

interface State extends StreemshotData {
    status: 'idle' | 'fetching' | 'fetched' | 'error';
    error: string | null | Event;
}

const streemshotReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ActionType.FETCHING:
            return {
                ...state,
                status: 'fetching',
            };
        case ActionType.FETCHED:
            return {
                ...state,
                status: 'fetched',
                ...action.payload,
            };
        case ActionType.FETCH_ERROR:
            return { ...state, status: 'error', ...action.payload };
        default:
            return state;
    }
};

/**
 * Fetches streemshot image data associated with streemshot url
 * Saves image data as blob to an objectURL reference in the browser
 * Calculates image aspect ratio
 * Returns a StreemshotImageData object with objectURL reference and image aspect ratio properties
 * Adds a matching record to a mutable ref object, with the url as the data lookup key
 * On re-renders of subscribed components, if the streemshot url already exists in the ref object, the cached data is returned in place of a newly fetched payload
 * Once images are loaded, any component can use the ref object to look up cached streemshot image data by url
 * This prevents redundant fetching and enables fast downloading to user's local disk, as image data is already in the browser once it is visible on the page
 * @param url
 * @param imageDataRef
 */
export const useFetchStreemshotData = (
    url: string,
    imageDataRef: React.MutableRefObject<{ [url: string]: StreemshotData }>,
) => {
    const initialState: State = {
        status: 'idle',
        error: null,
        dataURL: '',
        aspectRatio: 1,
    };
    const [state, dispatch] = useReducer(streemshotReducer, initialState);
    useEffect(() => {
        if (!url || !imageDataRef) return;

        const controller = new AbortController();
        const fetchData = async () => {
            dispatch({ type: ActionType.FETCHING });
            if (imageDataRef.current[url]) {
                const data: StreemshotData = imageDataRef.current[url];
                dispatch({ type: ActionType.FETCHED, payload: data });
            } else {
                try {
                    const response = await fetch(url, { signal: controller.signal });
                    const blob = await response.blob();
                    const dataURL = URL.createObjectURL(blob);

                    const aspectRatio = await new Promise<number>((res, rej) => {
                        const img = new Image();
                        img.onload = () => res(img.width / img.height);
                        img.onerror = e => rej(e);
                        img.src = dataURL;
                    });

                    const imageData: StreemshotData = {
                        dataURL,
                        aspectRatio,
                    };

                    imageDataRef.current[url] = imageData;
                    dispatch({
                        type: ActionType.FETCHED,
                        payload: imageData,
                    });
                } catch (error: unknown) {
                    if (error instanceof Error) {
                        dispatch({
                            type: ActionType.FETCH_ERROR,
                            payload: { error: error.message },
                        });
                    }
                }
            }
        };

        fetchData();
        return () => controller.abort();
    }, [url, imageDataRef]);

    return state;
};
