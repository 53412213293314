import { useCallback, useEffect, useState } from 'react';
import { Option, Select, Label, styled } from '@streem/ui-react';
import { recordElementClicked } from '@streem/analytics';
import appLogger from '../../util/logging/app_logger';
import {
    getMediaDevices,
    getPreferredAudioOutputId,
    removePreferredAudioOutputId,
    setPreferredAudioOutputId,
} from '@streem/toolbox';

const log = appLogger.extend('AudioOutputSelect');

type AudioOutputOption = Option<string>;
const convertMediaDeviceInfoToAudioOutputOption = (device: MediaDeviceInfo): AudioOutputOption => ({
    value: device.deviceId,
    label: device.label,
});

interface Props {
    open: boolean;
    onPermissionDenied: () => void;
    onPermissionAllowed: () => void;
}

const inputId = 'audio-output-select';
const audioOutputDropdownOpenedId = 'ms-audio-output-select-opened';
const audioOutputDropdownId = 'ms-audio-output-item-selected';

let mediaStream: MediaStream | null = null;

const AudioOutputSelect = ({ open, onPermissionDenied, onPermissionAllowed }: Props) => {
    const [audioOutputDevices, setAudioOutputDevices] = useState<AudioOutputOption[]>([]);
    const [selectedAudioOutputDevice, setSelectedAudioOutputDevice] = useState<
        AudioOutputOption | undefined
    >(undefined);
    const [initialized, setInitialized] = useState(false);
    const [placeholder, setPlaceholder] = useState<string>('Speaker selection unavailable');

    const handleSelect = useCallback(
        (option: Option<string>) => {
            log.info('setting preferred audio output device', option);
            setPreferredAudioOutputId(option.value);
            setSelectedAudioOutputDevice(option);
            recordElementClicked(audioOutputDropdownId);
        },
        [setSelectedAudioOutputDevice],
    );

    useEffect(() => {
        const getAudioOutputDevices = async () => {
            // ensure that the user has given permission to the website to use audio.  Without this,
            //     the list of audio devices will be empty.
            if (open) {
                try {
                    mediaStream = await navigator.mediaDevices.getUserMedia({
                        audio: true,
                        video: false,
                    });
                } catch (e) {
                    log.warn('call to getUserMedia failed for audio', e);
                    onPermissionDenied();
                    return;
                }
            }
            onPermissionAllowed();
            // this should only be executed if the user has given permission to the website to use audio.  Also,
            // this doesn't work in Safari.
            try {
                const devices = await getMediaDevices('audiooutput');
                const options = devices.map(convertMediaDeviceInfoToAudioOutputOption);
                setAudioOutputDevices(options);
                if (options.length > 0) {
                    setPlaceholder('Select speaker');
                }
            } catch (e) {
                log.error('error getting user audio devices', e);
            }
        };

        getAudioOutputDevices();

        return () => {
            if (mediaStream) {
                mediaStream.getAudioTracks().forEach(track => track.stop());
            }
        };
    }, [open, onPermissionDenied, onPermissionAllowed]);

    useEffect(() => {
        if (audioOutputDevices.length > 0) {
            const preferredAudioOutputId = getPreferredAudioOutputId();
            if (preferredAudioOutputId) {
                const preferredAudioOutput = audioOutputDevices.find(
                    device => device.value === preferredAudioOutputId,
                );
                if (preferredAudioOutput) {
                    setSelectedAudioOutputDevice(preferredAudioOutput);
                } else {
                    // not sure if this is right, but it seems better to remove an unknown device
                    removePreferredAudioOutputId();
                }
            }
            setInitialized(true);
        }
    }, [audioOutputDevices]);

    return (
        <Wrapper>
            <DropdownHeader size="medium" semibold>
                Audio
            </DropdownHeader>
            <Label semibold size="medium" htmlFor={inputId}>
                Select speaker
            </Label>
            <Select
                onMenuOpen={() => recordElementClicked(audioOutputDropdownOpenedId)}
                options={audioOutputDevices}
                value={selectedAudioOutputDevice}
                menuPlacement="bottom"
                onSelect={handleSelect}
                isDisabled={!initialized}
                id="audio-output-select"
                border={true}
                placeholder={placeholder}
                styles={{
                    menuList: () => ({
                        // prevent the menu list from overflowing the bottom of the wrapper
                        overflowY: 'scroll',
                        maxHeight: '9rem',
                    }),
                    container: provided => ({
                        width: '290px',
                        ...provided,
                    }),
                }}
                inputId={inputId}
            />
        </Wrapper>
    );
};

const Wrapper = styled.span({
    display: 'inline-block',
});

const DropdownHeader = styled(Label)({
    display: 'block',
    margin: '5px 0px',
    fontSize: '1.1rem',
});

export default AudioOutputSelect;
