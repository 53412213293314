import { FC, useEffect, useState } from 'react';
import { useTheme, Subheader } from '@streem/ui-react';
import { APITypes } from '@streem/api';
import { columnBuilder, CallLogColumnProps } from '../../components/calls/call_log_columns';
import { FlexCentered } from '../../forms/shared_form.styles';
import { useGlobalStore } from '../../hooks/use_global_context';
import { useObserver } from 'mobx-react';
import { useGetUserCallLogStore } from '../../hooks/list_store_hooks';
import { Redirect, useHistory } from 'react-router-dom';
import { joinPaths } from '../../util/routing';
import { ListViewWithPagination } from '../../components/lists/list_view_with_pagination';
import { useAppHistory } from '../../hooks/use_app_history';
import { getCompanyRoomOutcomeConfig } from '../../hooks/use_room_outcome_form_config';
import { CancellablePromise } from '../../types/project.types';
import { useGetEmbedEnabledSettings } from '../../hooks/use_get_embed_enabled_settings';
import { useGetCompanyStore } from '../../hooks/detail_store_hooks';

function buildMyCallLogColumns(props: CallLogColumnProps) {
    const cols = [
        columnBuilder.customerColumn(props),
        columnBuilder.artifactColumn(props),
        columnBuilder.durationColumn(),
        columnBuilder.dateColumn(),
    ];

    return cols;
}

interface MyCallLogPageProps {
    searchString: string;
}

interface CallLogRow extends APITypes.StreemApiCallLogEntry {
    key: string;
}

export const MyCallLogPage: FC<MyCallLogPageProps> = ({ searchString }) => {
    const theme = useTheme();
    const history = useHistory();
    const { authStore, companySettingsStore } = useGlobalStore();
    const userCallLogStore = useGetUserCallLogStore();
    const appHistory = useAppHistory();
    const { isEmbedView } = useGetEmbedEnabledSettings();
    const companyStore = useGetCompanyStore();

    const [showCallOutcomeWarning, setShowCallOutcomeWarning] = useState(false);
    useEffect(() => {
        async function displayCallOutcomeWarning() {
            try {
                await getCompanyRoomOutcomeConfig(companyStore?.result?.sid);
                setShowCallOutcomeWarning(true);
            } catch (error) {
                setShowCallOutcomeWarning(false);
            }
        }

        if (companySettingsStore.roomOutcomeFeatureEnabled && companyStore && companyStore.result) {
            displayCallOutcomeWarning();
        }
    }, [companySettingsStore, companyStore]);

    useEffect(() => {
        const previousLocationPathname = appHistory[appHistory.length - 1];
        const navigatingFromCallDetailPage =
            previousLocationPathname && previousLocationPathname.includes('rm_');
        const previousListFilter = userCallLogStore.baseRequest?.filter;
        const PAGE_SIZE = 100;

        let promise: CancellablePromise<void>;

        if (navigatingFromCallDetailPage && userCallLogStore.results.length !== 0) {
            promise = userCallLogStore.refresh();
        } else {
            promise = userCallLogStore.fetchFirstPage(
                {
                    userSid: authStore.userId,
                    pageSize: PAGE_SIZE,
                    filter: searchString,
                },
                {
                    // Conditionally display cached data if we're navigating from the call detail page
                    // Show loading spinner while fetching new results if the cached data used a filter
                    clearCache: !!previousListFilter,
                },
            );
        }
        return () => {
            promise.cancel();
        };
        // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    }, [userCallLogStore, searchString, authStore]);

    const rowClickUrl = (row: CallLogRow) => {
        const url = joinPaths(history.location.pathname, row.roomSid!);
        history.push(url);
    };

    const isRowClickable = (row: CallLogRow) => row.roomSid && (row.callStatus === 'STATUS_SUCCESSFUL');

    return useObserver(() => {
        const callLogRecords: CallLogRow[] = userCallLogStore.results.map(v => ({
            key: v.roomSid!,
            ...v,
        }));

        if (userCallLogStore.lastError && userCallLogStore.lastError.status === 500) {
            return <Redirect to="/error" />;
        }

        if (!userCallLogStore.loading && callLogRecords.length === 0 && searchString.length) {
            return (
                <FlexCentered>
                    <Subheader>No Streem calls were found for {searchString}.</Subheader>
                </FlexCentered>
            );
        }

        if (!userCallLogStore.loading && callLogRecords.length === 0) {
            return (
                <FlexCentered>
                    <Subheader>You have not made any calls yet.</Subheader>
                </FlexCentered>
            );
        }

        const hideFormattedAddress = companySettingsStore.hideFormattedAddressEnabled;
        const hideGPSLocations = !companySettingsStore.gpsEnabled;

        const columns =
            isEmbedView || !companySettingsStore.callFavoritingEnabled
                ? [
                      ...buildMyCallLogColumns({
                          theme,
                          hideFormattedAddress,
                          hideGPSLocations,
                          ...(!isEmbedView && { showCallOutcomeWarning }),
                      }),
                  ]
                : [
                      columnBuilder.favoriteCallColumn(() => userCallLogStore.refresh()),
                      ...buildMyCallLogColumns({
                          theme,
                          hideFormattedAddress,
                          hideGPSLocations,
                          showCallOutcomeWarning,
                      }),
                  ];
        return (
            <ListViewWithPagination
                initialScrollPosition={userCallLogStore.scrollPosition}
                store={userCallLogStore}
                data-testid="my-call-log"
                loading={userCallLogStore.loading}
                linkTo={rowClickUrl}
                canRowClick={isRowClickable}
                data={callLogRecords}
                buildRowTestId={row => 'my-call-log-row-' + row.roomSid}
                columns={columns}
                gridTemplateColumns={`${
                    isEmbedView || !companySettingsStore.callFavoritingEnabled
                        ? ''
                        : 'minmax(150px, 200px)'
                } minmax(300px, 1fr) 250px 170px 170px`}
            />
        );
    });
};
