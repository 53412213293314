/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StreemApiAcceptExternalAudioRequest,
    StreemApiAcceptExternalAudioRequestFromJSON,
    StreemApiAcceptExternalAudioRequestToJSON,
    StreemApiAcceptExternalAudioResponse,
    StreemApiAcceptExternalAudioResponseFromJSON,
    StreemApiAcceptExternalAudioResponseToJSON,
    StreemApiAcceptRoomInvitationRequest,
    StreemApiAcceptRoomInvitationRequestFromJSON,
    StreemApiAcceptRoomInvitationRequestToJSON,
    StreemApiAcceptRoomInvitationResponse,
    StreemApiAcceptRoomInvitationResponseFromJSON,
    StreemApiAcceptRoomInvitationResponseToJSON,
    StreemApiArchiveAndDeleteRoomResponse,
    StreemApiArchiveAndDeleteRoomResponseFromJSON,
    StreemApiArchiveAndDeleteRoomResponseToJSON,
    StreemApiCancelRoomInvitationRequest,
    StreemApiCancelRoomInvitationRequestFromJSON,
    StreemApiCancelRoomInvitationRequestToJSON,
    StreemApiCancelRoomInvitationResponse,
    StreemApiCancelRoomInvitationResponseFromJSON,
    StreemApiCancelRoomInvitationResponseToJSON,
    StreemApiCreateReadonlyRoomTokenResponse,
    StreemApiCreateReadonlyRoomTokenResponseFromJSON,
    StreemApiCreateReadonlyRoomTokenResponseToJSON,
    StreemApiCreateRecordingResponse,
    StreemApiCreateRecordingResponseFromJSON,
    StreemApiCreateRecordingResponseToJSON,
    StreemApiCreateRoomInvitationRequest,
    StreemApiCreateRoomInvitationRequestFromJSON,
    StreemApiCreateRoomInvitationRequestToJSON,
    StreemApiCreateRoomInvitationResponse,
    StreemApiCreateRoomInvitationResponseFromJSON,
    StreemApiCreateRoomInvitationResponseToJSON,
    StreemApiCreateRoomOutcomeReportRequest,
    StreemApiCreateRoomOutcomeReportRequestFromJSON,
    StreemApiCreateRoomOutcomeReportRequestToJSON,
    StreemApiCreateRoomOutcomeReportResponse,
    StreemApiCreateRoomOutcomeReportResponseFromJSON,
    StreemApiCreateRoomOutcomeReportResponseToJSON,
    StreemApiCreateRoomRequest,
    StreemApiCreateRoomRequestFromJSON,
    StreemApiCreateRoomRequestToJSON,
    StreemApiCreateRoomResponse,
    StreemApiCreateRoomResponseFromJSON,
    StreemApiCreateRoomResponseToJSON,
    StreemApiCreateRoomShareTokenRequest,
    StreemApiCreateRoomShareTokenRequestFromJSON,
    StreemApiCreateRoomShareTokenRequestToJSON,
    StreemApiCreateRoomShareTokenResponse,
    StreemApiCreateRoomShareTokenResponseFromJSON,
    StreemApiCreateRoomShareTokenResponseToJSON,
    StreemApiCreateRoomTokenResponse,
    StreemApiCreateRoomTokenResponseFromJSON,
    StreemApiCreateRoomTokenResponseToJSON,
    StreemApiCreateRoomVideoCredentialsRequest,
    StreemApiCreateRoomVideoCredentialsRequestFromJSON,
    StreemApiCreateRoomVideoCredentialsRequestToJSON,
    StreemApiCreateRoomVideoCredentialsResponse,
    StreemApiCreateRoomVideoCredentialsResponseFromJSON,
    StreemApiCreateRoomVideoCredentialsResponseToJSON,
    StreemApiCreateRoomWallCredentialsResponse,
    StreemApiCreateRoomWallCredentialsResponseFromJSON,
    StreemApiCreateRoomWallCredentialsResponseToJSON,
    StreemApiCreateStreemshotRequest,
    StreemApiCreateStreemshotRequestFromJSON,
    StreemApiCreateStreemshotRequestToJSON,
    StreemApiCreateStreemshotResponse,
    StreemApiCreateStreemshotResponseFromJSON,
    StreemApiCreateStreemshotResponseToJSON,
    StreemApiCreateStreemshotRevisionRequest,
    StreemApiCreateStreemshotRevisionRequestFromJSON,
    StreemApiCreateStreemshotRevisionRequestToJSON,
    StreemApiCreateStreemshotRevisionResponse,
    StreemApiCreateStreemshotRevisionResponseFromJSON,
    StreemApiCreateStreemshotRevisionResponseToJSON,
    StreemApiFulfillMeshResponse,
    StreemApiFulfillMeshResponseFromJSON,
    StreemApiFulfillMeshResponseToJSON,
    StreemApiFulfillRecordingRequest,
    StreemApiFulfillRecordingRequestFromJSON,
    StreemApiFulfillRecordingRequestToJSON,
    StreemApiFulfillRecordingResponse,
    StreemApiFulfillRecordingResponseFromJSON,
    StreemApiFulfillRecordingResponseToJSON,
    StreemApiFulfillStreemshotRequest,
    StreemApiFulfillStreemshotRequestFromJSON,
    StreemApiFulfillStreemshotRequestToJSON,
    StreemApiFulfillStreemshotResponse,
    StreemApiFulfillStreemshotResponseFromJSON,
    StreemApiFulfillStreemshotResponseToJSON,
    StreemApiGetBookmarkResponse,
    StreemApiGetBookmarkResponseFromJSON,
    StreemApiGetBookmarkResponseToJSON,
    StreemApiGetGpsPositionResponse,
    StreemApiGetGpsPositionResponseFromJSON,
    StreemApiGetGpsPositionResponseToJSON,
    StreemApiGetLayoutEstimationResponse,
    StreemApiGetLayoutEstimationResponseFromJSON,
    StreemApiGetLayoutEstimationResponseToJSON,
    StreemApiGetMeshResponse,
    StreemApiGetMeshResponseFromJSON,
    StreemApiGetMeshResponseToJSON,
    StreemApiGetRoomCallLogEntryResponse,
    StreemApiGetRoomCallLogEntryResponseFromJSON,
    StreemApiGetRoomCallLogEntryResponseToJSON,
    StreemApiGetRoomNoteResponse,
    StreemApiGetRoomNoteResponseFromJSON,
    StreemApiGetRoomNoteResponseToJSON,
    StreemApiGetRoomOutcomeReportResponse,
    StreemApiGetRoomOutcomeReportResponseFromJSON,
    StreemApiGetRoomOutcomeReportResponseToJSON,
    StreemApiGetRoomResponse,
    StreemApiGetRoomResponseFromJSON,
    StreemApiGetRoomResponseToJSON,
    StreemApiGetRoomShareTokenResponse,
    StreemApiGetRoomShareTokenResponseFromJSON,
    StreemApiGetRoomShareTokenResponseToJSON,
    StreemApiGetRoomWallItemsResponse,
    StreemApiGetRoomWallItemsResponseFromJSON,
    StreemApiGetRoomWallItemsResponseToJSON,
    StreemApiGetSceneshotResponse,
    StreemApiGetSceneshotResponseFromJSON,
    StreemApiGetSceneshotResponseToJSON,
    StreemApiGetStreemshotResponse,
    StreemApiGetStreemshotResponseFromJSON,
    StreemApiGetStreemshotResponseToJSON,
    StreemApiGetTexturedMeshResponse,
    StreemApiGetTexturedMeshResponseFromJSON,
    StreemApiGetTexturedMeshResponseToJSON,
    StreemApiLeaveRoomResponse,
    StreemApiLeaveRoomResponseFromJSON,
    StreemApiLeaveRoomResponseToJSON,
    StreemApiListGpsPositionsResponse,
    StreemApiListGpsPositionsResponseFromJSON,
    StreemApiListGpsPositionsResponseToJSON,
    StreemApiListMeshesResponse,
    StreemApiListMeshesResponseFromJSON,
    StreemApiListMeshesResponseToJSON,
    StreemApiListRecordingsResponse,
    StreemApiListRecordingsResponseFromJSON,
    StreemApiListRecordingsResponseToJSON,
    StreemApiListRoomArtifactsResponse,
    StreemApiListRoomArtifactsResponseFromJSON,
    StreemApiListRoomArtifactsResponseToJSON,
    StreemApiListRoomOutcomeReportsResponse,
    StreemApiListRoomOutcomeReportsResponseFromJSON,
    StreemApiListRoomOutcomeReportsResponseToJSON,
    StreemApiListRoomParticipantsResponse,
    StreemApiListRoomParticipantsResponseFromJSON,
    StreemApiListRoomParticipantsResponseToJSON,
    StreemApiListRoomShareTokensResponse,
    StreemApiListRoomShareTokensResponseFromJSON,
    StreemApiListRoomShareTokensResponseToJSON,
    StreemApiListRoomsResponse,
    StreemApiListRoomsResponseFromJSON,
    StreemApiListRoomsResponseToJSON,
    StreemApiListSceneshotsResponse,
    StreemApiListSceneshotsResponseFromJSON,
    StreemApiListSceneshotsResponseToJSON,
    StreemApiListStreemshotsResponse,
    StreemApiListStreemshotsResponseFromJSON,
    StreemApiListStreemshotsResponseToJSON,
    StreemApiRejectRoomInvitationRequest,
    StreemApiRejectRoomInvitationRequestFromJSON,
    StreemApiRejectRoomInvitationRequestToJSON,
    StreemApiRejectRoomInvitationResponse,
    StreemApiRejectRoomInvitationResponseFromJSON,
    StreemApiRejectRoomInvitationResponseToJSON,
    StreemApiRoomInvitation,
    StreemApiRoomInvitationFromJSON,
    StreemApiRoomInvitationToJSON,
    StreemApiUpdateRoomNoteRequest,
    StreemApiUpdateRoomNoteRequestFromJSON,
    StreemApiUpdateRoomNoteRequestToJSON,
    StreemApiUpdateRoomNoteResponse,
    StreemApiUpdateRoomNoteResponseFromJSON,
    StreemApiUpdateRoomNoteResponseToJSON,
    StreemApiUpdateRoomRequest,
    StreemApiUpdateRoomRequestFromJSON,
    StreemApiUpdateRoomRequestToJSON,
    StreemApiUpdateRoomResponse,
    StreemApiUpdateRoomResponseFromJSON,
    StreemApiUpdateRoomResponseToJSON,
    StreemApiUpdateStreemshotNoteRequest,
    StreemApiUpdateStreemshotNoteRequestFromJSON,
    StreemApiUpdateStreemshotNoteRequestToJSON,
    StreemApiUpdateStreemshotNoteResponse,
    StreemApiUpdateStreemshotNoteResponseFromJSON,
    StreemApiUpdateStreemshotNoteResponseToJSON,
} from '../models';

export interface AcceptExternalAudioRequest {
    roomSid: string;
    streemApiAcceptExternalAudioRequest?: StreemApiAcceptExternalAudioRequest;
}

export interface AcceptRoomInvitationRequest {
    roomSid: string;
    invitationSid: string;
    streemApiAcceptRoomInvitationRequest?: StreemApiAcceptRoomInvitationRequest;
}

export interface ArchiveAndDeleteRoomRequest {
    roomSid: string;
}

export interface ArchiveAndDeleteRoomAndDeleteNonExpertParticipantRequest {
    roomSid: string;
}

export interface CancelRoomInvitationRequest {
    roomSid: string;
    invitationSid: string;
    streemApiCancelRoomInvitationRequest?: StreemApiCancelRoomInvitationRequest;
}

export interface CreateReadonlyRoomTokenRequest {
    roomSid: string;
}

export interface CreateRoomRequest {
    streemApiCreateRoomRequest?: StreemApiCreateRoomRequest;
}

export interface CreateRoomInvitationRequest {
    roomSid: string;
    streemApiCreateRoomInvitationRequest?: StreemApiCreateRoomInvitationRequest;
}

export interface CreateRoomOutcomeReportRequest {
    roomSid: string;
    streemApiCreateRoomOutcomeReportRequest?: StreemApiCreateRoomOutcomeReportRequest;
}

export interface CreateRoomRecordingRequest {
    roomSid: string;
}

export interface CreateRoomShareTokenRequest {
    roomSid: string;
    streemApiCreateRoomShareTokenRequest?: StreemApiCreateRoomShareTokenRequest;
}

export interface CreateRoomTokenRequest {
    roomSid: string;
    userSid: string;
    preflight?: boolean;
}

export interface CreateRoomVideoCredentialsRequest {
    roomSid: string;
    userSid: string;
    streemApiCreateRoomVideoCredentialsRequest?: StreemApiCreateRoomVideoCredentialsRequest;
}

export interface CreateRoomWallCredentialsRequest {
    roomSid: string;
    userSid: string;
}

export interface CreateStreemshotRequest {
    roomSid: string;
    streemshotSid: string;
    streemApiCreateStreemshotRequest?: StreemApiCreateStreemshotRequest;
}

export interface CreateStreemshotRevisionRequest {
    roomSid: string;
    streemshotSid: string;
    streemApiCreateStreemshotRevisionRequest?: StreemApiCreateStreemshotRevisionRequest;
}

export interface DeleteBookmarkRequest {
    roomSid: string;
    bookmarkSid: string;
}

export interface DeleteExternalAudioRequest {
    roomSid: string;
}

export interface DeleteGpsPositionRequest {
    roomSid: string;
    gpsPositionSid: string;
}

export interface DeleteLayoutEstimationRequest {
    roomSid: string;
    layoutEstimationSid: string;
}

export interface DeleteMeshRequest {
    roomSid: string;
    meshSid: string;
}

export interface DeleteRecordingRequest {
    roomSid: string;
    recordingSid: string;
}

export interface DeleteRoomRequest {
    roomSid: string;
}

export interface DeleteRoomNoteRequest {
    roomSid: string;
}

export interface DeleteRoomOutcomeReportRequest {
    roomSid: string;
}

export interface DeleteSceneshotRequest {
    roomSid: string;
    sceneshotSid: string;
}

export interface DeleteStreemshotRequest {
    roomSid: string;
    streemshotSid: string;
}

export interface DeleteStreemshotNoteRequest {
    roomSid: string;
    streemshotSid: string;
}

export interface DeleteTexturedMeshRequest {
    roomSid: string;
    texturedMeshSid: string;
}

export interface FulfillMeshRequest {
    roomSid: string;
    meshSid: string;
}

export interface FulfillRecordingRequest {
    roomSid: string;
    recordingSid: string;
    streemApiFulfillRecordingRequest?: StreemApiFulfillRecordingRequest;
}

export interface FulfillStreemshotRequest {
    roomSid: string;
    streemshotSid: string;
    streemApiFulfillStreemshotRequest?: StreemApiFulfillStreemshotRequest;
}

export interface GetBookmarkRequest {
    roomSid: string;
    bookmarkSid: string;
}

export interface GetGpsPositionRequest {
    roomSid: string;
    gpsPositionSid: string;
}

export interface GetLayoutEstimationRequest {
    roomSid: string;
    layoutEstimationSid: string;
}

export interface GetMeshRequest {
    roomSid: string;
    meshSid: string;
}

export interface GetRoomRequest {
    roomSid: string;
}

export interface GetRoomCallLogEntryRequest {
    roomSid: string;
}

export interface GetRoomInvitationRequest {
    roomSid: string;
    invitationSid: string;
}

export interface GetRoomNoteRequest {
    roomSid: string;
}

export interface GetRoomOutcomeReportRequest {
    roomSid: string;
    roomOutcomeReportSid: string;
}

export interface GetRoomShareTokenRequest {
    roomSid: string;
    tokenSid: string;
}

export interface GetRoomWallItemsRequest {
    roomSid: string;
}

export interface GetSceneshotRequest {
    roomSid: string;
    sceneshotSid: string;
}

export interface GetStreemshotRequest {
    roomSid: string;
    streemshotSid: string;
}

export interface GetTexturedMeshRequest {
    roomSid: string;
    texturedMeshSid: string;
}

export interface LeaveRoomRequest {
    roomSid: string;
    userSid: string;
}

export interface ListGpsPositionsRequest {
    roomSid: string;
    pageSize?: number;
    pageToken?: string;
}

export interface ListMeshesRequest {
    roomSid: string;
    pageSize?: number;
    pageToken?: string;
}

export interface ListRecordingsRequest {
    roomSid: string;
    pageSize?: number;
    pageToken?: string;
}

export interface ListRoomArtifactsRequest {
    roomSid: string;
    pageSize?: number;
    pageToken?: string;
}

export interface ListRoomOutcomeReportsRequest {
    roomSid: string;
    pageSize?: number;
    pageToken?: string;
}

export interface ListRoomParticipantsRequest {
    roomSid: string;
}

export interface ListRoomShareTokensRequest {
    roomSid: string;
    includeExpired?: boolean;
    expires?: ListRoomShareTokensExpires;
    view?: ListRoomShareTokensView;
    pageSize?: number;
    pageToken?: string;
}

export interface ListRoomsRequest {
    createdByUserSid?: string;
    companySid?: string;
    referenceId?: string;
    integrationId?: string;
    pageSize?: number;
    pageToken?: string;
}

export interface ListSceneshotsRequest {
    roomSid: string;
    pageSize?: number;
    pageToken?: string;
}

export interface ListStreemshotsRequest {
    roomSid: string;
    pageSize?: number;
    pageToken?: string;
}

export interface PauseRoomRecordingRequest {
    roomSid: string;
}

export interface RejectRoomInvitationRequest {
    roomSid: string;
    invitationSid: string;
    streemApiRejectRoomInvitationRequest?: StreemApiRejectRoomInvitationRequest;
}

export interface ResumeRoomRecordingRequest {
    roomSid: string;
}

export interface RevokeRoomShareTokenRequest {
    roomSid: string;
    tokenSid: string;
}

export interface UpdateRoomRequest {
    roomSid: string;
    streemApiUpdateRoomRequest?: StreemApiUpdateRoomRequest;
}

export interface UpdateRoomNoteRequest {
    roomSid: string;
    streemApiUpdateRoomNoteRequest?: StreemApiUpdateRoomNoteRequest;
}

export interface UpdateStreemshotNoteRequest {
    roomSid: string;
    streemshotSid: string;
    streemApiUpdateStreemshotNoteRequest?: StreemApiUpdateStreemshotNoteRequest;
}

/**
 * 
 */
export class RoomsApi extends runtime.BaseAPI {

    /**
     * Upload external audio to overwrite Streem-recorded audio in recordings
     * Upload external audio
     */
    async acceptExternalAudioRaw(requestParameters: AcceptExternalAudioRequest): Promise<runtime.ApiResponse<StreemApiAcceptExternalAudioResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling acceptExternalAudio.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/artifacts/external-audio`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiAcceptExternalAudioRequestToJSON(requestParameters.streemApiAcceptExternalAudioRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiAcceptExternalAudioResponseFromJSON(jsonValue));
    }

    /**
     * Upload external audio to overwrite Streem-recorded audio in recordings
     * Upload external audio
     */
    async acceptExternalAudio(roomSid: string, streemApiAcceptExternalAudioRequest?: StreemApiAcceptExternalAudioRequest): Promise<StreemApiAcceptExternalAudioResponse> {
        const response = await this.acceptExternalAudioRaw({ roomSid: roomSid, streemApiAcceptExternalAudioRequest: streemApiAcceptExternalAudioRequest });
        return await response.value();
    }

    /**
     * Mark invitation as answered by invitee
     * Accept Invitation
     */
    async acceptRoomInvitationRaw(requestParameters: AcceptRoomInvitationRequest): Promise<runtime.ApiResponse<StreemApiAcceptRoomInvitationResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling acceptRoomInvitation.');
        }

        if (requestParameters.invitationSid === null || requestParameters.invitationSid === undefined) {
            throw new runtime.RequiredError('invitationSid','Required parameter requestParameters.invitationSid was null or undefined when calling acceptRoomInvitation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/invitations/{invitation_sid}/accept`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"invitation_sid"}}`, encodeURIComponent(String(requestParameters.invitationSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiAcceptRoomInvitationRequestToJSON(requestParameters.streemApiAcceptRoomInvitationRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiAcceptRoomInvitationResponseFromJSON(jsonValue));
    }

    /**
     * Mark invitation as answered by invitee
     * Accept Invitation
     */
    async acceptRoomInvitation(roomSid: string, invitationSid: string, streemApiAcceptRoomInvitationRequest?: StreemApiAcceptRoomInvitationRequest): Promise<StreemApiAcceptRoomInvitationResponse> {
        const response = await this.acceptRoomInvitationRaw({ roomSid: roomSid, invitationSid: invitationSid, streemApiAcceptRoomInvitationRequest: streemApiAcceptRoomInvitationRequest });
        return await response.value();
    }

    /**
     * Archives all room\'s Assets to S3 bucket and deletes them from storage, and hides from Call Logs
     * Archive Room assets and Delete
     */
    async archiveAndDeleteRoomRaw(requestParameters: ArchiveAndDeleteRoomRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling archiveAndDeleteRoom.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/archive-and-delete`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives all room\'s Assets to S3 bucket and deletes them from storage, and hides from Call Logs
     * Archive Room assets and Delete
     */
    async archiveAndDeleteRoom(roomSid: string): Promise<void> {
        await this.archiveAndDeleteRoomRaw({ roomSid: roomSid });
    }

    /**
     * Archives all room\'s Assets to S3 bucket and deletes them from storage, and hides from Call Logs
     * Archive Room assets and Delete along with the caller (non-expert)
     */
    async archiveAndDeleteRoomAndDeleteNonExpertParticipantRaw(requestParameters: ArchiveAndDeleteRoomAndDeleteNonExpertParticipantRequest): Promise<runtime.ApiResponse<StreemApiArchiveAndDeleteRoomResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling archiveAndDeleteRoomAndDeleteNonExpertParticipant.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/~archive-and-delete`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiArchiveAndDeleteRoomResponseFromJSON(jsonValue));
    }

    /**
     * Archives all room\'s Assets to S3 bucket and deletes them from storage, and hides from Call Logs
     * Archive Room assets and Delete along with the caller (non-expert)
     */
    async archiveAndDeleteRoomAndDeleteNonExpertParticipant(roomSid: string): Promise<StreemApiArchiveAndDeleteRoomResponse> {
        const response = await this.archiveAndDeleteRoomAndDeleteNonExpertParticipantRaw({ roomSid: roomSid });
        return await response.value();
    }

    /**
     * Mark invitation as cancelled (by inviter)
     * Cancel Invitation
     */
    async cancelRoomInvitationRaw(requestParameters: CancelRoomInvitationRequest): Promise<runtime.ApiResponse<StreemApiCancelRoomInvitationResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling cancelRoomInvitation.');
        }

        if (requestParameters.invitationSid === null || requestParameters.invitationSid === undefined) {
            throw new runtime.RequiredError('invitationSid','Required parameter requestParameters.invitationSid was null or undefined when calling cancelRoomInvitation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/invitations/{invitation_sid}/cancel`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"invitation_sid"}}`, encodeURIComponent(String(requestParameters.invitationSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiCancelRoomInvitationRequestToJSON(requestParameters.streemApiCancelRoomInvitationRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCancelRoomInvitationResponseFromJSON(jsonValue));
    }

    /**
     * Mark invitation as cancelled (by inviter)
     * Cancel Invitation
     */
    async cancelRoomInvitation(roomSid: string, invitationSid: string, streemApiCancelRoomInvitationRequest?: StreemApiCancelRoomInvitationRequest): Promise<StreemApiCancelRoomInvitationResponse> {
        const response = await this.cancelRoomInvitationRaw({ roomSid: roomSid, invitationSid: invitationSid, streemApiCancelRoomInvitationRequest: streemApiCancelRoomInvitationRequest });
        return await response.value();
    }

    /**
     * Create a new token to use to connect to a Room with readonly privileges
     * Create Readonly Token
     */
    async createReadonlyRoomTokenRaw(requestParameters: CreateReadonlyRoomTokenRequest): Promise<runtime.ApiResponse<StreemApiCreateReadonlyRoomTokenResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling createReadonlyRoomToken.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/readonly-tokens`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCreateReadonlyRoomTokenResponseFromJSON(jsonValue));
    }

    /**
     * Create a new token to use to connect to a Room with readonly privileges
     * Create Readonly Token
     */
    async createReadonlyRoomToken(roomSid: string): Promise<StreemApiCreateReadonlyRoomTokenResponse> {
        const response = await this.createReadonlyRoomTokenRaw({ roomSid: roomSid });
        return await response.value();
    }

    /**
     * Create a new Streem Room
     * Create Room
     */
    async createRoomRaw(requestParameters: CreateRoomRequest): Promise<runtime.ApiResponse<StreemApiCreateRoomResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiCreateRoomRequestToJSON(requestParameters.streemApiCreateRoomRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCreateRoomResponseFromJSON(jsonValue));
    }

    /**
     * Create a new Streem Room
     * Create Room
     */
    async createRoom(streemApiCreateRoomRequest?: StreemApiCreateRoomRequest): Promise<StreemApiCreateRoomResponse> {
        const response = await this.createRoomRaw({ streemApiCreateRoomRequest: streemApiCreateRoomRequest });
        return await response.value();
    }

    /**
     * Create a new Room Invitation, which allows the invited User to have access to the Room
     * Create Room Invitation
     */
    async createRoomInvitationRaw(requestParameters: CreateRoomInvitationRequest): Promise<runtime.ApiResponse<StreemApiCreateRoomInvitationResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling createRoomInvitation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/invitations`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiCreateRoomInvitationRequestToJSON(requestParameters.streemApiCreateRoomInvitationRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCreateRoomInvitationResponseFromJSON(jsonValue));
    }

    /**
     * Create a new Room Invitation, which allows the invited User to have access to the Room
     * Create Room Invitation
     */
    async createRoomInvitation(roomSid: string, streemApiCreateRoomInvitationRequest?: StreemApiCreateRoomInvitationRequest): Promise<StreemApiCreateRoomInvitationResponse> {
        const response = await this.createRoomInvitationRaw({ roomSid: roomSid, streemApiCreateRoomInvitationRequest: streemApiCreateRoomInvitationRequest });
        return await response.value();
    }

    /**
     * Create Room Outcome Report artifact for this Room
     * Create Room Outcome Report
     */
    async createRoomOutcomeReportRaw(requestParameters: CreateRoomOutcomeReportRequest): Promise<runtime.ApiResponse<StreemApiCreateRoomOutcomeReportResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling createRoomOutcomeReport.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/outcome-reports`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiCreateRoomOutcomeReportRequestToJSON(requestParameters.streemApiCreateRoomOutcomeReportRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCreateRoomOutcomeReportResponseFromJSON(jsonValue));
    }

    /**
     * Create Room Outcome Report artifact for this Room
     * Create Room Outcome Report
     */
    async createRoomOutcomeReport(roomSid: string, streemApiCreateRoomOutcomeReportRequest?: StreemApiCreateRoomOutcomeReportRequest): Promise<StreemApiCreateRoomOutcomeReportResponse> {
        const response = await this.createRoomOutcomeReportRaw({ roomSid: roomSid, streemApiCreateRoomOutcomeReportRequest: streemApiCreateRoomOutcomeReportRequest });
        return await response.value();
    }

    /**
     * Create a new ON_DEVICE Recording for a room
     * Create Recording
     */
    async createRoomRecordingRaw(requestParameters: CreateRoomRecordingRequest): Promise<runtime.ApiResponse<StreemApiCreateRecordingResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling createRoomRecording.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/recordings`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCreateRecordingResponseFromJSON(jsonValue));
    }

    /**
     * Create a new ON_DEVICE Recording for a room
     * Create Recording
     */
    async createRoomRecording(roomSid: string): Promise<StreemApiCreateRecordingResponse> {
        const response = await this.createRoomRecordingRaw({ roomSid: roomSid });
        return await response.value();
    }

    /**
     * Create a new Share Token, which can be provided as `Authorization: ShareToken [token]` to gain readonly access to the Room
     * Create Share Token
     */
    async createRoomShareTokenRaw(requestParameters: CreateRoomShareTokenRequest): Promise<runtime.ApiResponse<StreemApiCreateRoomShareTokenResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling createRoomShareToken.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/share-tokens`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiCreateRoomShareTokenRequestToJSON(requestParameters.streemApiCreateRoomShareTokenRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCreateRoomShareTokenResponseFromJSON(jsonValue));
    }

    /**
     * Create a new Share Token, which can be provided as `Authorization: ShareToken [token]` to gain readonly access to the Room
     * Create Share Token
     */
    async createRoomShareToken(roomSid: string, streemApiCreateRoomShareTokenRequest?: StreemApiCreateRoomShareTokenRequest): Promise<StreemApiCreateRoomShareTokenResponse> {
        const response = await this.createRoomShareTokenRaw({ roomSid: roomSid, streemApiCreateRoomShareTokenRequest: streemApiCreateRoomShareTokenRequest });
        return await response.value();
    }

    /**
     * Create a new wall/video token to use to connect to a Room as a User
     * Create wall/video Token
     */
    async createRoomTokenRaw(requestParameters: CreateRoomTokenRequest): Promise<runtime.ApiResponse<StreemApiCreateRoomTokenResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling createRoomToken.');
        }

        if (requestParameters.userSid === null || requestParameters.userSid === undefined) {
            throw new runtime.RequiredError('userSid','Required parameter requestParameters.userSid was null or undefined when calling createRoomToken.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.preflight !== undefined) {
            queryParameters['preflight'] = requestParameters.preflight;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/participants/{user_sid}/tokens`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"user_sid"}}`, encodeURIComponent(String(requestParameters.userSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCreateRoomTokenResponseFromJSON(jsonValue));
    }

    /**
     * Create a new wall/video token to use to connect to a Room as a User
     * Create wall/video Token
     */
    async createRoomToken(roomSid: string, userSid: string, preflight?: boolean): Promise<StreemApiCreateRoomTokenResponse> {
        const response = await this.createRoomTokenRaw({ roomSid: roomSid, userSid: userSid, preflight: preflight });
        return await response.value();
    }

    /**
     * Create new credentials to use to connect to a Room as a User
     * Create Video credentials
     */
    async createRoomVideoCredentialsRaw(requestParameters: CreateRoomVideoCredentialsRequest): Promise<runtime.ApiResponse<StreemApiCreateRoomVideoCredentialsResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling createRoomVideoCredentials.');
        }

        if (requestParameters.userSid === null || requestParameters.userSid === undefined) {
            throw new runtime.RequiredError('userSid','Required parameter requestParameters.userSid was null or undefined when calling createRoomVideoCredentials.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/participants/{user_sid}/credentials/video`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"user_sid"}}`, encodeURIComponent(String(requestParameters.userSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiCreateRoomVideoCredentialsRequestToJSON(requestParameters.streemApiCreateRoomVideoCredentialsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCreateRoomVideoCredentialsResponseFromJSON(jsonValue));
    }

    /**
     * Create new credentials to use to connect to a Room as a User
     * Create Video credentials
     */
    async createRoomVideoCredentials(roomSid: string, userSid: string, streemApiCreateRoomVideoCredentialsRequest?: StreemApiCreateRoomVideoCredentialsRequest): Promise<StreemApiCreateRoomVideoCredentialsResponse> {
        const response = await this.createRoomVideoCredentialsRaw({ roomSid: roomSid, userSid: userSid, streemApiCreateRoomVideoCredentialsRequest: streemApiCreateRoomVideoCredentialsRequest });
        return await response.value();
    }

    /**
     * Create new wall credentials to use to connect/update the room wall
     * Create wall credentials
     */
    async createRoomWallCredentialsRaw(requestParameters: CreateRoomWallCredentialsRequest): Promise<runtime.ApiResponse<StreemApiCreateRoomWallCredentialsResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling createRoomWallCredentials.');
        }

        if (requestParameters.userSid === null || requestParameters.userSid === undefined) {
            throw new runtime.RequiredError('userSid','Required parameter requestParameters.userSid was null or undefined when calling createRoomWallCredentials.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/participants/{user_sid}/credentials/wall`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"user_sid"}}`, encodeURIComponent(String(requestParameters.userSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCreateRoomWallCredentialsResponseFromJSON(jsonValue));
    }

    /**
     * Create new wall credentials to use to connect/update the room wall
     * Create wall credentials
     */
    async createRoomWallCredentials(roomSid: string, userSid: string): Promise<StreemApiCreateRoomWallCredentialsResponse> {
        const response = await this.createRoomWallCredentialsRaw({ roomSid: roomSid, userSid: userSid });
        return await response.value();
    }

    /**
     * Create a new streemshot request
     * Create Streemshot Request
     */
    async createStreemshotRaw(requestParameters: CreateStreemshotRequest): Promise<runtime.ApiResponse<StreemApiCreateStreemshotResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling createStreemshot.');
        }

        if (requestParameters.streemshotSid === null || requestParameters.streemshotSid === undefined) {
            throw new runtime.RequiredError('streemshotSid','Required parameter requestParameters.streemshotSid was null or undefined when calling createStreemshot.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/streemshots/{streemshot_sid}/request`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"streemshot_sid"}}`, encodeURIComponent(String(requestParameters.streemshotSid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiCreateStreemshotRequestToJSON(requestParameters.streemApiCreateStreemshotRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCreateStreemshotResponseFromJSON(jsonValue));
    }

    /**
     * Create a new streemshot request
     * Create Streemshot Request
     */
    async createStreemshot(roomSid: string, streemshotSid: string, streemApiCreateStreemshotRequest?: StreemApiCreateStreemshotRequest): Promise<StreemApiCreateStreemshotResponse> {
        const response = await this.createStreemshotRaw({ roomSid: roomSid, streemshotSid: streemshotSid, streemApiCreateStreemshotRequest: streemApiCreateStreemshotRequest });
        return await response.value();
    }

    /**
     * Create a streemshot revision, which may include new AR data
     * Create Streemshot Revision
     */
    async createStreemshotRevisionRaw(requestParameters: CreateStreemshotRevisionRequest): Promise<runtime.ApiResponse<StreemApiCreateStreemshotRevisionResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling createStreemshotRevision.');
        }

        if (requestParameters.streemshotSid === null || requestParameters.streemshotSid === undefined) {
            throw new runtime.RequiredError('streemshotSid','Required parameter requestParameters.streemshotSid was null or undefined when calling createStreemshotRevision.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/streemshots/{streemshot_sid}/revisions`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"streemshot_sid"}}`, encodeURIComponent(String(requestParameters.streemshotSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiCreateStreemshotRevisionRequestToJSON(requestParameters.streemApiCreateStreemshotRevisionRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCreateStreemshotRevisionResponseFromJSON(jsonValue));
    }

    /**
     * Create a streemshot revision, which may include new AR data
     * Create Streemshot Revision
     */
    async createStreemshotRevision(roomSid: string, streemshotSid: string, streemApiCreateStreemshotRevisionRequest?: StreemApiCreateStreemshotRevisionRequest): Promise<StreemApiCreateStreemshotRevisionResponse> {
        const response = await this.createStreemshotRevisionRaw({ roomSid: roomSid, streemshotSid: streemshotSid, streemApiCreateStreemshotRevisionRequest: streemApiCreateStreemshotRevisionRequest });
        return await response.value();
    }

    /**
     * Delete a video recording bookmark
     * Delete Bookmark
     */
    async deleteBookmarkRaw(requestParameters: DeleteBookmarkRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling deleteBookmark.');
        }

        if (requestParameters.bookmarkSid === null || requestParameters.bookmarkSid === undefined) {
            throw new runtime.RequiredError('bookmarkSid','Required parameter requestParameters.bookmarkSid was null or undefined when calling deleteBookmark.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/bookmarks/{bookmark_sid}`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"bookmark_sid"}}`, encodeURIComponent(String(requestParameters.bookmarkSid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a video recording bookmark
     * Delete Bookmark
     */
    async deleteBookmark(roomSid: string, bookmarkSid: string): Promise<void> {
        await this.deleteBookmarkRaw({ roomSid: roomSid, bookmarkSid: bookmarkSid });
    }

    /**
     * Delete external audio recording and revert original Streem-recorded audio
     * Delete external audio
     */
    async deleteExternalAudioRaw(requestParameters: DeleteExternalAudioRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling deleteExternalAudio.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/artifacts/external-audio`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete external audio recording and revert original Streem-recorded audio
     * Delete external audio
     */
    async deleteExternalAudio(roomSid: string): Promise<void> {
        await this.deleteExternalAudioRaw({ roomSid: roomSid });
    }

    /**
     * Delete the specified GPS position
     * Delete GPS
     */
    async deleteGpsPositionRaw(requestParameters: DeleteGpsPositionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling deleteGpsPosition.');
        }

        if (requestParameters.gpsPositionSid === null || requestParameters.gpsPositionSid === undefined) {
            throw new runtime.RequiredError('gpsPositionSid','Required parameter requestParameters.gpsPositionSid was null or undefined when calling deleteGpsPosition.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/gps-positions/{gps_position_sid}`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"gps_position_sid"}}`, encodeURIComponent(String(requestParameters.gpsPositionSid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the specified GPS position
     * Delete GPS
     */
    async deleteGpsPosition(roomSid: string, gpsPositionSid: string): Promise<void> {
        await this.deleteGpsPositionRaw({ roomSid: roomSid, gpsPositionSid: gpsPositionSid });
    }

    /**
     * Delete a layout estimation
     * Delete Layout Estimation
     */
    async deleteLayoutEstimationRaw(requestParameters: DeleteLayoutEstimationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling deleteLayoutEstimation.');
        }

        if (requestParameters.layoutEstimationSid === null || requestParameters.layoutEstimationSid === undefined) {
            throw new runtime.RequiredError('layoutEstimationSid','Required parameter requestParameters.layoutEstimationSid was null or undefined when calling deleteLayoutEstimation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/layout-estimations/{layout_estimation_sid}`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"layout_estimation_sid"}}`, encodeURIComponent(String(requestParameters.layoutEstimationSid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a layout estimation
     * Delete Layout Estimation
     */
    async deleteLayoutEstimation(roomSid: string, layoutEstimationSid: string): Promise<void> {
        await this.deleteLayoutEstimationRaw({ roomSid: roomSid, layoutEstimationSid: layoutEstimationSid });
    }

    /**
     * Delete a mesh
     * Delete Mesh
     */
    async deleteMeshRaw(requestParameters: DeleteMeshRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling deleteMesh.');
        }

        if (requestParameters.meshSid === null || requestParameters.meshSid === undefined) {
            throw new runtime.RequiredError('meshSid','Required parameter requestParameters.meshSid was null or undefined when calling deleteMesh.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/meshes/{mesh_sid}`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"mesh_sid"}}`, encodeURIComponent(String(requestParameters.meshSid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a mesh
     * Delete Mesh
     */
    async deleteMesh(roomSid: string, meshSid: string): Promise<void> {
        await this.deleteMeshRaw({ roomSid: roomSid, meshSid: meshSid });
    }

    /**
     * Delete a Recording artifact, which will eventually permanently remove it from storage
     * Delete Recording
     */
    async deleteRecordingRaw(requestParameters: DeleteRecordingRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling deleteRecording.');
        }

        if (requestParameters.recordingSid === null || requestParameters.recordingSid === undefined) {
            throw new runtime.RequiredError('recordingSid','Required parameter requestParameters.recordingSid was null or undefined when calling deleteRecording.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/recordings/{recording_sid}`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"recording_sid"}}`, encodeURIComponent(String(requestParameters.recordingSid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a Recording artifact, which will eventually permanently remove it from storage
     * Delete Recording
     */
    async deleteRecording(roomSid: string, recordingSid: string): Promise<void> {
        await this.deleteRecordingRaw({ roomSid: roomSid, recordingSid: recordingSid });
    }

    /**
     * Delete the Room, which deletes all Assets from storage, and hides from Call Logs
     * Delete Room
     */
    async deleteRoomRaw(requestParameters: DeleteRoomRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling deleteRoom.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the Room, which deletes all Assets from storage, and hides from Call Logs
     * Delete Room
     */
    async deleteRoom(roomSid: string): Promise<void> {
        await this.deleteRoomRaw({ roomSid: roomSid });
    }

    /**
     * Delete the Note from the specified Room
     * Delete Room Note
     */
    async deleteRoomNoteRaw(requestParameters: DeleteRoomNoteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling deleteRoomNote.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/note`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the Note from the specified Room
     * Delete Room Note
     */
    async deleteRoomNote(roomSid: string): Promise<void> {
        await this.deleteRoomNoteRaw({ roomSid: roomSid });
    }

    /**
     * Delete Room Outcome Report artifact for this Room
     * Delete Room Outcome Report
     */
    async deleteRoomOutcomeReportRaw(requestParameters: DeleteRoomOutcomeReportRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling deleteRoomOutcomeReport.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/outcome-reports`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Room Outcome Report artifact for this Room
     * Delete Room Outcome Report
     */
    async deleteRoomOutcomeReport(roomSid: string): Promise<void> {
        await this.deleteRoomOutcomeReportRaw({ roomSid: roomSid });
    }

    /**
     * Delete a sceneshot
     * Delete Sceneshot
     */
    async deleteSceneshotRaw(requestParameters: DeleteSceneshotRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling deleteSceneshot.');
        }

        if (requestParameters.sceneshotSid === null || requestParameters.sceneshotSid === undefined) {
            throw new runtime.RequiredError('sceneshotSid','Required parameter requestParameters.sceneshotSid was null or undefined when calling deleteSceneshot.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/sceneshots/{sceneshot_sid}`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"sceneshot_sid"}}`, encodeURIComponent(String(requestParameters.sceneshotSid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a sceneshot
     * Delete Sceneshot
     */
    async deleteSceneshot(roomSid: string, sceneshotSid: string): Promise<void> {
        await this.deleteSceneshotRaw({ roomSid: roomSid, sceneshotSid: sceneshotSid });
    }

    /**
     * Delete a Streemshot, which will eventually permanently remove it from storage
     * Delete Streemshot
     */
    async deleteStreemshotRaw(requestParameters: DeleteStreemshotRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling deleteStreemshot.');
        }

        if (requestParameters.streemshotSid === null || requestParameters.streemshotSid === undefined) {
            throw new runtime.RequiredError('streemshotSid','Required parameter requestParameters.streemshotSid was null or undefined when calling deleteStreemshot.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/streemshots/{streemshot_sid}`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"streemshot_sid"}}`, encodeURIComponent(String(requestParameters.streemshotSid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a Streemshot, which will eventually permanently remove it from storage
     * Delete Streemshot
     */
    async deleteStreemshot(roomSid: string, streemshotSid: string): Promise<void> {
        await this.deleteStreemshotRaw({ roomSid: roomSid, streemshotSid: streemshotSid });
    }

    /**
     * Delete the text Note from a Streemshot
     * Delete Streemshot Note
     */
    async deleteStreemshotNoteRaw(requestParameters: DeleteStreemshotNoteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling deleteStreemshotNote.');
        }

        if (requestParameters.streemshotSid === null || requestParameters.streemshotSid === undefined) {
            throw new runtime.RequiredError('streemshotSid','Required parameter requestParameters.streemshotSid was null or undefined when calling deleteStreemshotNote.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/streemshots/{streemshot_sid}/note`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"streemshot_sid"}}`, encodeURIComponent(String(requestParameters.streemshotSid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the text Note from a Streemshot
     * Delete Streemshot Note
     */
    async deleteStreemshotNote(roomSid: string, streemshotSid: string): Promise<void> {
        await this.deleteStreemshotNoteRaw({ roomSid: roomSid, streemshotSid: streemshotSid });
    }

    /**
     * Delete a textured mesh
     * Delete Textured Mesh
     */
    async deleteTexturedMeshRaw(requestParameters: DeleteTexturedMeshRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling deleteTexturedMesh.');
        }

        if (requestParameters.texturedMeshSid === null || requestParameters.texturedMeshSid === undefined) {
            throw new runtime.RequiredError('texturedMeshSid','Required parameter requestParameters.texturedMeshSid was null or undefined when calling deleteTexturedMesh.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/textured-meshes/{textured_mesh_sid}`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"textured_mesh_sid"}}`, encodeURIComponent(String(requestParameters.texturedMeshSid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Delete a textured mesh
     * Delete Textured Mesh
     */
    async deleteTexturedMesh(roomSid: string, texturedMeshSid: string): Promise<object> {
        const response = await this.deleteTexturedMeshRaw({ roomSid: roomSid, texturedMeshSid: texturedMeshSid });
        return await response.value();
    }

    /**
     * Fulfill a mesh request
     * Fulfill Mesh Request
     */
    async fulfillMeshRaw(requestParameters: FulfillMeshRequest): Promise<runtime.ApiResponse<StreemApiFulfillMeshResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling fulfillMesh.');
        }

        if (requestParameters.meshSid === null || requestParameters.meshSid === undefined) {
            throw new runtime.RequiredError('meshSid','Required parameter requestParameters.meshSid was null or undefined when calling fulfillMesh.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/meshes/{mesh_sid}/fulfill`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"mesh_sid"}}`, encodeURIComponent(String(requestParameters.meshSid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiFulfillMeshResponseFromJSON(jsonValue));
    }

    /**
     * Fulfill a mesh request
     * Fulfill Mesh Request
     */
    async fulfillMesh(roomSid: string, meshSid: string): Promise<StreemApiFulfillMeshResponse> {
        const response = await this.fulfillMeshRaw({ roomSid: roomSid, meshSid: meshSid });
        return await response.value();
    }

    /**
     * Fulfill a Recording artifact after upload is complete
     * Fulfill Recording
     */
    async fulfillRecordingRaw(requestParameters: FulfillRecordingRequest): Promise<runtime.ApiResponse<StreemApiFulfillRecordingResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling fulfillRecording.');
        }

        if (requestParameters.recordingSid === null || requestParameters.recordingSid === undefined) {
            throw new runtime.RequiredError('recordingSid','Required parameter requestParameters.recordingSid was null or undefined when calling fulfillRecording.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/recordings/{recording_sid}/fulfill`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"recording_sid"}}`, encodeURIComponent(String(requestParameters.recordingSid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiFulfillRecordingRequestToJSON(requestParameters.streemApiFulfillRecordingRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiFulfillRecordingResponseFromJSON(jsonValue));
    }

    /**
     * Fulfill a Recording artifact after upload is complete
     * Fulfill Recording
     */
    async fulfillRecording(roomSid: string, recordingSid: string, streemApiFulfillRecordingRequest?: StreemApiFulfillRecordingRequest): Promise<StreemApiFulfillRecordingResponse> {
        const response = await this.fulfillRecordingRaw({ roomSid: roomSid, recordingSid: recordingSid, streemApiFulfillRecordingRequest: streemApiFulfillRecordingRequest });
        return await response.value();
    }

    /**
     * Fulfill a streemshot request
     * Fulfill Streemshot Request
     */
    async fulfillStreemshotRaw(requestParameters: FulfillStreemshotRequest): Promise<runtime.ApiResponse<StreemApiFulfillStreemshotResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling fulfillStreemshot.');
        }

        if (requestParameters.streemshotSid === null || requestParameters.streemshotSid === undefined) {
            throw new runtime.RequiredError('streemshotSid','Required parameter requestParameters.streemshotSid was null or undefined when calling fulfillStreemshot.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/streemshots/{streemshot_sid}/fulfill`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"streemshot_sid"}}`, encodeURIComponent(String(requestParameters.streemshotSid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiFulfillStreemshotRequestToJSON(requestParameters.streemApiFulfillStreemshotRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiFulfillStreemshotResponseFromJSON(jsonValue));
    }

    /**
     * Fulfill a streemshot request
     * Fulfill Streemshot Request
     */
    async fulfillStreemshot(roomSid: string, streemshotSid: string, streemApiFulfillStreemshotRequest?: StreemApiFulfillStreemshotRequest): Promise<StreemApiFulfillStreemshotResponse> {
        const response = await this.fulfillStreemshotRaw({ roomSid: roomSid, streemshotSid: streemshotSid, streemApiFulfillStreemshotRequest: streemApiFulfillStreemshotRequest });
        return await response.value();
    }

    /**
     * Get info about the specified video recording bookmark
     * Get Bookmark
     */
    async getBookmarkRaw(requestParameters: GetBookmarkRequest): Promise<runtime.ApiResponse<StreemApiGetBookmarkResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling getBookmark.');
        }

        if (requestParameters.bookmarkSid === null || requestParameters.bookmarkSid === undefined) {
            throw new runtime.RequiredError('bookmarkSid','Required parameter requestParameters.bookmarkSid was null or undefined when calling getBookmark.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/bookmarks/{bookmark_sid}`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"bookmark_sid"}}`, encodeURIComponent(String(requestParameters.bookmarkSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetBookmarkResponseFromJSON(jsonValue));
    }

    /**
     * Get info about the specified video recording bookmark
     * Get Bookmark
     */
    async getBookmark(roomSid: string, bookmarkSid: string): Promise<StreemApiGetBookmarkResponse> {
        const response = await this.getBookmarkRaw({ roomSid: roomSid, bookmarkSid: bookmarkSid });
        return await response.value();
    }

    /**
     * Get info about the specified GPS position
     * Get GPS
     */
    async getGpsPositionRaw(requestParameters: GetGpsPositionRequest): Promise<runtime.ApiResponse<StreemApiGetGpsPositionResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling getGpsPosition.');
        }

        if (requestParameters.gpsPositionSid === null || requestParameters.gpsPositionSid === undefined) {
            throw new runtime.RequiredError('gpsPositionSid','Required parameter requestParameters.gpsPositionSid was null or undefined when calling getGpsPosition.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/gps-positions/{gps_position_sid}`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"gps_position_sid"}}`, encodeURIComponent(String(requestParameters.gpsPositionSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetGpsPositionResponseFromJSON(jsonValue));
    }

    /**
     * Get info about the specified GPS position
     * Get GPS
     */
    async getGpsPosition(roomSid: string, gpsPositionSid: string): Promise<StreemApiGetGpsPositionResponse> {
        const response = await this.getGpsPositionRaw({ roomSid: roomSid, gpsPositionSid: gpsPositionSid });
        return await response.value();
    }

    /**
     * Get info about the specified layout estimation
     * Get Layout Estimation
     */
    async getLayoutEstimationRaw(requestParameters: GetLayoutEstimationRequest): Promise<runtime.ApiResponse<StreemApiGetLayoutEstimationResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling getLayoutEstimation.');
        }

        if (requestParameters.layoutEstimationSid === null || requestParameters.layoutEstimationSid === undefined) {
            throw new runtime.RequiredError('layoutEstimationSid','Required parameter requestParameters.layoutEstimationSid was null or undefined when calling getLayoutEstimation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/layout-estimations/{layout_estimation_sid}`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"layout_estimation_sid"}}`, encodeURIComponent(String(requestParameters.layoutEstimationSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetLayoutEstimationResponseFromJSON(jsonValue));
    }

    /**
     * Get info about the specified layout estimation
     * Get Layout Estimation
     */
    async getLayoutEstimation(roomSid: string, layoutEstimationSid: string): Promise<StreemApiGetLayoutEstimationResponse> {
        const response = await this.getLayoutEstimationRaw({ roomSid: roomSid, layoutEstimationSid: layoutEstimationSid });
        return await response.value();
    }

    /**
     * Get info about the specified mesh
     * Get Mesh
     */
    async getMeshRaw(requestParameters: GetMeshRequest): Promise<runtime.ApiResponse<StreemApiGetMeshResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling getMesh.');
        }

        if (requestParameters.meshSid === null || requestParameters.meshSid === undefined) {
            throw new runtime.RequiredError('meshSid','Required parameter requestParameters.meshSid was null or undefined when calling getMesh.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/meshes/{mesh_sid}`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"mesh_sid"}}`, encodeURIComponent(String(requestParameters.meshSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetMeshResponseFromJSON(jsonValue));
    }

    /**
     * Get info about the specified mesh
     * Get Mesh
     */
    async getMesh(roomSid: string, meshSid: string): Promise<StreemApiGetMeshResponse> {
        const response = await this.getMeshRaw({ roomSid: roomSid, meshSid: meshSid });
        return await response.value();
    }

    /**
     * Get information about the specified Room
     * Get Room
     */
    async getRoomRaw(requestParameters: GetRoomRequest): Promise<runtime.ApiResponse<StreemApiGetRoomResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling getRoom.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetRoomResponseFromJSON(jsonValue));
    }

    /**
     * Get information about the specified Room
     * Get Room
     */
    async getRoom(roomSid: string): Promise<StreemApiGetRoomResponse> {
        const response = await this.getRoomRaw({ roomSid: roomSid });
        return await response.value();
    }

    /**
     * Get the Call Log Entry for this Room and User
     * Get Call Log Entry
     */
    async getRoomCallLogEntryRaw(requestParameters: GetRoomCallLogEntryRequest): Promise<runtime.ApiResponse<StreemApiGetRoomCallLogEntryResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling getRoomCallLogEntry.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/call-log-entry`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetRoomCallLogEntryResponseFromJSON(jsonValue));
    }

    /**
     * Get the Call Log Entry for this Room and User
     * Get Call Log Entry
     */
    async getRoomCallLogEntry(roomSid: string): Promise<StreemApiGetRoomCallLogEntryResponse> {
        const response = await this.getRoomCallLogEntryRaw({ roomSid: roomSid });
        return await response.value();
    }

    /**
     * Get information for the specified invitation within this Room
     * Get Room Invitation
     */
    async getRoomInvitationRaw(requestParameters: GetRoomInvitationRequest): Promise<runtime.ApiResponse<StreemApiRoomInvitation>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling getRoomInvitation.');
        }

        if (requestParameters.invitationSid === null || requestParameters.invitationSid === undefined) {
            throw new runtime.RequiredError('invitationSid','Required parameter requestParameters.invitationSid was null or undefined when calling getRoomInvitation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/invitations/{invitation_sid}`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"invitation_sid"}}`, encodeURIComponent(String(requestParameters.invitationSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiRoomInvitationFromJSON(jsonValue));
    }

    /**
     * Get information for the specified invitation within this Room
     * Get Room Invitation
     */
    async getRoomInvitation(roomSid: string, invitationSid: string): Promise<StreemApiRoomInvitation> {
        const response = await this.getRoomInvitationRaw({ roomSid: roomSid, invitationSid: invitationSid });
        return await response.value();
    }

    /**
     * Gets the text Note for the specified Room
     * Get Room Note
     */
    async getRoomNoteRaw(requestParameters: GetRoomNoteRequest): Promise<runtime.ApiResponse<StreemApiGetRoomNoteResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling getRoomNote.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/note`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetRoomNoteResponseFromJSON(jsonValue));
    }

    /**
     * Gets the text Note for the specified Room
     * Get Room Note
     */
    async getRoomNote(roomSid: string): Promise<StreemApiGetRoomNoteResponse> {
        const response = await this.getRoomNoteRaw({ roomSid: roomSid });
        return await response.value();
    }

    /**
     * Get Room Outcome Report artifact for this Room
     * Get Room Outcome Report
     */
    async getRoomOutcomeReportRaw(requestParameters: GetRoomOutcomeReportRequest): Promise<runtime.ApiResponse<StreemApiGetRoomOutcomeReportResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling getRoomOutcomeReport.');
        }

        if (requestParameters.roomOutcomeReportSid === null || requestParameters.roomOutcomeReportSid === undefined) {
            throw new runtime.RequiredError('roomOutcomeReportSid','Required parameter requestParameters.roomOutcomeReportSid was null or undefined when calling getRoomOutcomeReport.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/outcome-reports/{room_outcome_report_sid}`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"room_outcome_report_sid"}}`, encodeURIComponent(String(requestParameters.roomOutcomeReportSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetRoomOutcomeReportResponseFromJSON(jsonValue));
    }

    /**
     * Get Room Outcome Report artifact for this Room
     * Get Room Outcome Report
     */
    async getRoomOutcomeReport(roomSid: string, roomOutcomeReportSid: string): Promise<StreemApiGetRoomOutcomeReportResponse> {
        const response = await this.getRoomOutcomeReportRaw({ roomSid: roomSid, roomOutcomeReportSid: roomOutcomeReportSid });
        return await response.value();
    }

    /**
     * Get a Share Token by `token_sid`
     * Get Share Token
     */
    async getRoomShareTokenRaw(requestParameters: GetRoomShareTokenRequest): Promise<runtime.ApiResponse<StreemApiGetRoomShareTokenResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling getRoomShareToken.');
        }

        if (requestParameters.tokenSid === null || requestParameters.tokenSid === undefined) {
            throw new runtime.RequiredError('tokenSid','Required parameter requestParameters.tokenSid was null or undefined when calling getRoomShareToken.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/share-tokens/{token_sid}`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"token_sid"}}`, encodeURIComponent(String(requestParameters.tokenSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetRoomShareTokenResponseFromJSON(jsonValue));
    }

    /**
     * Get a Share Token by `token_sid`
     * Get Share Token
     */
    async getRoomShareToken(roomSid: string, tokenSid: string): Promise<StreemApiGetRoomShareTokenResponse> {
        const response = await this.getRoomShareTokenRaw({ roomSid: roomSid, tokenSid: tokenSid });
        return await response.value();
    }

    /**
     * Gets all room wall items and decrypts if necessary. This is an admin endpoint for manual testing. Do not use in code.
     * Get Wall Items
     */
    async getRoomWallItemsRaw(requestParameters: GetRoomWallItemsRequest): Promise<runtime.ApiResponse<StreemApiGetRoomWallItemsResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling getRoomWallItems.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/wall-items`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetRoomWallItemsResponseFromJSON(jsonValue));
    }

    /**
     * Gets all room wall items and decrypts if necessary. This is an admin endpoint for manual testing. Do not use in code.
     * Get Wall Items
     */
    async getRoomWallItems(roomSid: string): Promise<StreemApiGetRoomWallItemsResponse> {
        const response = await this.getRoomWallItemsRaw({ roomSid: roomSid });
        return await response.value();
    }

    /**
     * Get info about the specified sceneshot
     * Get Sceneshot
     */
    async getSceneshotRaw(requestParameters: GetSceneshotRequest): Promise<runtime.ApiResponse<StreemApiGetSceneshotResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling getSceneshot.');
        }

        if (requestParameters.sceneshotSid === null || requestParameters.sceneshotSid === undefined) {
            throw new runtime.RequiredError('sceneshotSid','Required parameter requestParameters.sceneshotSid was null or undefined when calling getSceneshot.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/sceneshots/{sceneshot_sid}`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"sceneshot_sid"}}`, encodeURIComponent(String(requestParameters.sceneshotSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetSceneshotResponseFromJSON(jsonValue));
    }

    /**
     * Get info about the specified sceneshot
     * Get Sceneshot
     */
    async getSceneshot(roomSid: string, sceneshotSid: string): Promise<StreemApiGetSceneshotResponse> {
        const response = await this.getSceneshotRaw({ roomSid: roomSid, sceneshotSid: sceneshotSid });
        return await response.value();
    }

    /**
     * Get info about the specified Streemshot
     * Get Streemshot
     */
    async getStreemshotRaw(requestParameters: GetStreemshotRequest): Promise<runtime.ApiResponse<StreemApiGetStreemshotResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling getStreemshot.');
        }

        if (requestParameters.streemshotSid === null || requestParameters.streemshotSid === undefined) {
            throw new runtime.RequiredError('streemshotSid','Required parameter requestParameters.streemshotSid was null or undefined when calling getStreemshot.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/streemshots/{streemshot_sid}`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"streemshot_sid"}}`, encodeURIComponent(String(requestParameters.streemshotSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetStreemshotResponseFromJSON(jsonValue));
    }

    /**
     * Get info about the specified Streemshot
     * Get Streemshot
     */
    async getStreemshot(roomSid: string, streemshotSid: string): Promise<StreemApiGetStreemshotResponse> {
        const response = await this.getStreemshotRaw({ roomSid: roomSid, streemshotSid: streemshotSid });
        return await response.value();
    }

    /**
     * Get info about the specified textured mesh
     * Get Textured Mesh
     */
    async getTexturedMeshRaw(requestParameters: GetTexturedMeshRequest): Promise<runtime.ApiResponse<StreemApiGetTexturedMeshResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling getTexturedMesh.');
        }

        if (requestParameters.texturedMeshSid === null || requestParameters.texturedMeshSid === undefined) {
            throw new runtime.RequiredError('texturedMeshSid','Required parameter requestParameters.texturedMeshSid was null or undefined when calling getTexturedMesh.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/textured-meshes/{textured_mesh_sid}`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"textured_mesh_sid"}}`, encodeURIComponent(String(requestParameters.texturedMeshSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetTexturedMeshResponseFromJSON(jsonValue));
    }

    /**
     * Get info about the specified textured mesh
     * Get Textured Mesh
     */
    async getTexturedMesh(roomSid: string, texturedMeshSid: string): Promise<StreemApiGetTexturedMeshResponse> {
        const response = await this.getTexturedMeshRaw({ roomSid: roomSid, texturedMeshSid: texturedMeshSid });
        return await response.value();
    }

    /**
     * Leaves the Room, which may close the Room if this is the last Participant
     * Leave Room
     */
    async leaveRoomRaw(requestParameters: LeaveRoomRequest): Promise<runtime.ApiResponse<StreemApiLeaveRoomResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling leaveRoom.');
        }

        if (requestParameters.userSid === null || requestParameters.userSid === undefined) {
            throw new runtime.RequiredError('userSid','Required parameter requestParameters.userSid was null or undefined when calling leaveRoom.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/participants/{user_sid}/leave`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"user_sid"}}`, encodeURIComponent(String(requestParameters.userSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiLeaveRoomResponseFromJSON(jsonValue));
    }

    /**
     * Leaves the Room, which may close the Room if this is the last Participant
     * Leave Room
     */
    async leaveRoom(roomSid: string, userSid: string): Promise<StreemApiLeaveRoomResponse> {
        const response = await this.leaveRoomRaw({ roomSid: roomSid, userSid: userSid });
        return await response.value();
    }

    /**
     * List all GPS positions for the Room
     * List GPS
     */
    async listGpsPositionsRaw(requestParameters: ListGpsPositionsRequest): Promise<runtime.ApiResponse<StreemApiListGpsPositionsResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling listGpsPositions.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['page_token'] = requestParameters.pageToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/gps-positions`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListGpsPositionsResponseFromJSON(jsonValue));
    }

    /**
     * List all GPS positions for the Room
     * List GPS
     */
    async listGpsPositions(roomSid: string, pageSize?: number, pageToken?: string): Promise<StreemApiListGpsPositionsResponse> {
        const response = await this.listGpsPositionsRaw({ roomSid: roomSid, pageSize: pageSize, pageToken: pageToken });
        return await response.value();
    }

    /**
     * List all Meshes for this Room
     * List Meshes
     */
    async listMeshesRaw(requestParameters: ListMeshesRequest): Promise<runtime.ApiResponse<StreemApiListMeshesResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling listMeshes.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['page_token'] = requestParameters.pageToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/meshes`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListMeshesResponseFromJSON(jsonValue));
    }

    /**
     * List all Meshes for this Room
     * List Meshes
     */
    async listMeshes(roomSid: string, pageSize?: number, pageToken?: string): Promise<StreemApiListMeshesResponse> {
        const response = await this.listMeshesRaw({ roomSid: roomSid, pageSize: pageSize, pageToken: pageToken });
        return await response.value();
    }

    /**
     * List all Recording artifacts for this Room
     * List Recordings
     */
    async listRecordingsRaw(requestParameters: ListRecordingsRequest): Promise<runtime.ApiResponse<StreemApiListRecordingsResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling listRecordings.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['page_token'] = requestParameters.pageToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/recordings`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListRecordingsResponseFromJSON(jsonValue));
    }

    /**
     * List all Recording artifacts for this Room
     * List Recordings
     */
    async listRecordings(roomSid: string, pageSize?: number, pageToken?: string): Promise<StreemApiListRecordingsResponse> {
        const response = await this.listRecordingsRaw({ roomSid: roomSid, pageSize: pageSize, pageToken: pageToken });
        return await response.value();
    }

    /**
     * List all Artifacts for this Room, such as Recordings, Streemshots, etc.
     * List Artifacts
     */
    async listRoomArtifactsRaw(requestParameters: ListRoomArtifactsRequest): Promise<runtime.ApiResponse<StreemApiListRoomArtifactsResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling listRoomArtifacts.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['page_token'] = requestParameters.pageToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/artifacts`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListRoomArtifactsResponseFromJSON(jsonValue));
    }

    /**
     * List all Artifacts for this Room, such as Recordings, Streemshots, etc.
     * List Artifacts
     */
    async listRoomArtifacts(roomSid: string, pageSize?: number, pageToken?: string): Promise<StreemApiListRoomArtifactsResponse> {
        const response = await this.listRoomArtifactsRaw({ roomSid: roomSid, pageSize: pageSize, pageToken: pageToken });
        return await response.value();
    }

    /**
     * List all RoomOutcomeReports for this Room
     * List RoomOutcomeReports
     */
    async listRoomOutcomeReportsRaw(requestParameters: ListRoomOutcomeReportsRequest): Promise<runtime.ApiResponse<StreemApiListRoomOutcomeReportsResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling listRoomOutcomeReports.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['page_token'] = requestParameters.pageToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/outcome-reports`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListRoomOutcomeReportsResponseFromJSON(jsonValue));
    }

    /**
     * List all RoomOutcomeReports for this Room
     * List RoomOutcomeReports
     */
    async listRoomOutcomeReports(roomSid: string, pageSize?: number, pageToken?: string): Promise<StreemApiListRoomOutcomeReportsResponse> {
        const response = await this.listRoomOutcomeReportsRaw({ roomSid: roomSid, pageSize: pageSize, pageToken: pageToken });
        return await response.value();
    }

    /**
     * Get the list of Participants for a Room
     * List Participants
     */
    async listRoomParticipantsRaw(requestParameters: ListRoomParticipantsRequest): Promise<runtime.ApiResponse<StreemApiListRoomParticipantsResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling listRoomParticipants.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/participants`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListRoomParticipantsResponseFromJSON(jsonValue));
    }

    /**
     * Get the list of Participants for a Room
     * List Participants
     */
    async listRoomParticipants(roomSid: string): Promise<StreemApiListRoomParticipantsResponse> {
        const response = await this.listRoomParticipantsRaw({ roomSid: roomSid });
        return await response.value();
    }

    /**
     * List Share Tokens for this Room, which give readonly access to the Assets of the Room
     * List Share Tokens
     */
    async listRoomShareTokensRaw(requestParameters: ListRoomShareTokensRequest): Promise<runtime.ApiResponse<StreemApiListRoomShareTokensResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling listRoomShareTokens.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.includeExpired !== undefined) {
            queryParameters['include_expired'] = requestParameters.includeExpired;
        }

        if (requestParameters.expires !== undefined) {
            queryParameters['expires'] = requestParameters.expires;
        }

        if (requestParameters.view !== undefined) {
            queryParameters['view'] = requestParameters.view;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['page_token'] = requestParameters.pageToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/share-tokens`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListRoomShareTokensResponseFromJSON(jsonValue));
    }

    /**
     * List Share Tokens for this Room, which give readonly access to the Assets of the Room
     * List Share Tokens
     */
    async listRoomShareTokens(roomSid: string, includeExpired?: boolean, expires?: ListRoomShareTokensExpires, view?: ListRoomShareTokensView, pageSize?: number, pageToken?: string): Promise<StreemApiListRoomShareTokensResponse> {
        const response = await this.listRoomShareTokensRaw({ roomSid: roomSid, includeExpired: includeExpired, expires: expires, view: view, pageSize: pageSize, pageToken: pageToken });
        return await response.value();
    }

    /**
     * List Rooms by various filters.  You must provide the `company_sid` param at a minimum.
     * List Rooms
     */
    async listRoomsRaw(requestParameters: ListRoomsRequest): Promise<runtime.ApiResponse<StreemApiListRoomsResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.createdByUserSid !== undefined) {
            queryParameters['created_by_user_sid'] = requestParameters.createdByUserSid;
        }

        if (requestParameters.companySid !== undefined) {
            queryParameters['company_sid'] = requestParameters.companySid;
        }

        if (requestParameters.referenceId !== undefined) {
            queryParameters['reference_id'] = requestParameters.referenceId;
        }

        if (requestParameters.integrationId !== undefined) {
            queryParameters['integration_id'] = requestParameters.integrationId;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['page_token'] = requestParameters.pageToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListRoomsResponseFromJSON(jsonValue));
    }

    /**
     * List Rooms by various filters.  You must provide the `company_sid` param at a minimum.
     * List Rooms
     */
    async listRooms(createdByUserSid?: string, companySid?: string, referenceId?: string, integrationId?: string, pageSize?: number, pageToken?: string): Promise<StreemApiListRoomsResponse> {
        const response = await this.listRoomsRaw({ createdByUserSid: createdByUserSid, companySid: companySid, referenceId: referenceId, integrationId: integrationId, pageSize: pageSize, pageToken: pageToken });
        return await response.value();
    }

    /**
     * List all Sceneshots for this Room
     * List Sceneshots
     */
    async listSceneshotsRaw(requestParameters: ListSceneshotsRequest): Promise<runtime.ApiResponse<StreemApiListSceneshotsResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling listSceneshots.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['page_token'] = requestParameters.pageToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/sceneshots`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListSceneshotsResponseFromJSON(jsonValue));
    }

    /**
     * List all Sceneshots for this Room
     * List Sceneshots
     */
    async listSceneshots(roomSid: string, pageSize?: number, pageToken?: string): Promise<StreemApiListSceneshotsResponse> {
        const response = await this.listSceneshotsRaw({ roomSid: roomSid, pageSize: pageSize, pageToken: pageToken });
        return await response.value();
    }

    /**
     * List all Streemshot Artifacts for this Room
     * List Streemshots
     */
    async listStreemshotsRaw(requestParameters: ListStreemshotsRequest): Promise<runtime.ApiResponse<StreemApiListStreemshotsResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling listStreemshots.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['page_token'] = requestParameters.pageToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/streemshots`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListStreemshotsResponseFromJSON(jsonValue));
    }

    /**
     * List all Streemshot Artifacts for this Room
     * List Streemshots
     */
    async listStreemshots(roomSid: string, pageSize?: number, pageToken?: string): Promise<StreemApiListStreemshotsResponse> {
        const response = await this.listStreemshotsRaw({ roomSid: roomSid, pageSize: pageSize, pageToken: pageToken });
        return await response.value();
    }

    /**
     * Pause an ongiong twilio recording, which will stop recording until resume request is received
     * Pause Recording
     */
    async pauseRoomRecordingRaw(requestParameters: PauseRoomRecordingRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling pauseRoomRecording.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/~pause-recording`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Pause an ongiong twilio recording, which will stop recording until resume request is received
     * Pause Recording
     */
    async pauseRoomRecording(roomSid: string): Promise<object> {
        const response = await this.pauseRoomRecordingRaw({ roomSid: roomSid });
        return await response.value();
    }

    /**
     * Mark invitation as rejected (by invitee)
     * Reject Invitation
     */
    async rejectRoomInvitationRaw(requestParameters: RejectRoomInvitationRequest): Promise<runtime.ApiResponse<StreemApiRejectRoomInvitationResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling rejectRoomInvitation.');
        }

        if (requestParameters.invitationSid === null || requestParameters.invitationSid === undefined) {
            throw new runtime.RequiredError('invitationSid','Required parameter requestParameters.invitationSid was null or undefined when calling rejectRoomInvitation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/invitations/{invitation_sid}/reject`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"invitation_sid"}}`, encodeURIComponent(String(requestParameters.invitationSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiRejectRoomInvitationRequestToJSON(requestParameters.streemApiRejectRoomInvitationRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiRejectRoomInvitationResponseFromJSON(jsonValue));
    }

    /**
     * Mark invitation as rejected (by invitee)
     * Reject Invitation
     */
    async rejectRoomInvitation(roomSid: string, invitationSid: string, streemApiRejectRoomInvitationRequest?: StreemApiRejectRoomInvitationRequest): Promise<StreemApiRejectRoomInvitationResponse> {
        const response = await this.rejectRoomInvitationRaw({ roomSid: roomSid, invitationSid: invitationSid, streemApiRejectRoomInvitationRequest: streemApiRejectRoomInvitationRequest });
        return await response.value();
    }

    /**
     * Resume a recording, which will continue recording until pause request is received
     * Resume Recording
     */
    async resumeRoomRecordingRaw(requestParameters: ResumeRoomRecordingRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling resumeRoomRecording.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/~resume-recording`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Resume a recording, which will continue recording until pause request is received
     * Resume Recording
     */
    async resumeRoomRecording(roomSid: string): Promise<object> {
        const response = await this.resumeRoomRecordingRaw({ roomSid: roomSid });
        return await response.value();
    }

    /**
     * Revoke a Share Token, which sets its `expires_at` value to now
     * Revoke Share Token
     */
    async revokeRoomShareTokenRaw(requestParameters: RevokeRoomShareTokenRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling revokeRoomShareToken.');
        }

        if (requestParameters.tokenSid === null || requestParameters.tokenSid === undefined) {
            throw new runtime.RequiredError('tokenSid','Required parameter requestParameters.tokenSid was null or undefined when calling revokeRoomShareToken.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/share-tokens/{token_sid}`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"token_sid"}}`, encodeURIComponent(String(requestParameters.tokenSid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Revoke a Share Token, which sets its `expires_at` value to now
     * Revoke Share Token
     */
    async revokeRoomShareToken(roomSid: string, tokenSid: string): Promise<void> {
        await this.revokeRoomShareTokenRaw({ roomSid: roomSid, tokenSid: tokenSid });
    }

    /**
     * Update the room
     * Update room
     */
    async updateRoomRaw(requestParameters: UpdateRoomRequest): Promise<runtime.ApiResponse<StreemApiUpdateRoomResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling updateRoom.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiUpdateRoomRequestToJSON(requestParameters.streemApiUpdateRoomRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiUpdateRoomResponseFromJSON(jsonValue));
    }

    /**
     * Update the room
     * Update room
     */
    async updateRoom(roomSid: string, streemApiUpdateRoomRequest?: StreemApiUpdateRoomRequest): Promise<StreemApiUpdateRoomResponse> {
        const response = await this.updateRoomRaw({ roomSid: roomSid, streemApiUpdateRoomRequest: streemApiUpdateRoomRequest });
        return await response.value();
    }

    /**
     * Updates the text Note for the specified Room
     * Update Room Note
     */
    async updateRoomNoteRaw(requestParameters: UpdateRoomNoteRequest): Promise<runtime.ApiResponse<StreemApiUpdateRoomNoteResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling updateRoomNote.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/note`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiUpdateRoomNoteRequestToJSON(requestParameters.streemApiUpdateRoomNoteRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiUpdateRoomNoteResponseFromJSON(jsonValue));
    }

    /**
     * Updates the text Note for the specified Room
     * Update Room Note
     */
    async updateRoomNote(roomSid: string, streemApiUpdateRoomNoteRequest?: StreemApiUpdateRoomNoteRequest): Promise<StreemApiUpdateRoomNoteResponse> {
        const response = await this.updateRoomNoteRaw({ roomSid: roomSid, streemApiUpdateRoomNoteRequest: streemApiUpdateRoomNoteRequest });
        return await response.value();
    }

    /**
     * Update the text Note on an existing Streemshot
     * Update Streemshot Note
     */
    async updateStreemshotNoteRaw(requestParameters: UpdateStreemshotNoteRequest): Promise<runtime.ApiResponse<StreemApiUpdateStreemshotNoteResponse>> {
        if (requestParameters.roomSid === null || requestParameters.roomSid === undefined) {
            throw new runtime.RequiredError('roomSid','Required parameter requestParameters.roomSid was null or undefined when calling updateStreemshotNote.');
        }

        if (requestParameters.streemshotSid === null || requestParameters.streemshotSid === undefined) {
            throw new runtime.RequiredError('streemshotSid','Required parameter requestParameters.streemshotSid was null or undefined when calling updateStreemshotNote.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/rooms/{room_sid}/streemshots/{streemshot_sid}/note`.replace(`{${"room_sid"}}`, encodeURIComponent(String(requestParameters.roomSid))).replace(`{${"streemshot_sid"}}`, encodeURIComponent(String(requestParameters.streemshotSid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiUpdateStreemshotNoteRequestToJSON(requestParameters.streemApiUpdateStreemshotNoteRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiUpdateStreemshotNoteResponseFromJSON(jsonValue));
    }

    /**
     * Update the text Note on an existing Streemshot
     * Update Streemshot Note
     */
    async updateStreemshotNote(roomSid: string, streemshotSid: string, streemApiUpdateStreemshotNoteRequest?: StreemApiUpdateStreemshotNoteRequest): Promise<StreemApiUpdateStreemshotNoteResponse> {
        const response = await this.updateStreemshotNoteRaw({ roomSid: roomSid, streemshotSid: streemshotSid, streemApiUpdateStreemshotNoteRequest: streemApiUpdateStreemshotNoteRequest });
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum ListRoomShareTokensExpires {
    ANY = 'EXPIRES_ANY',
    NO = 'EXPIRES_NO',
    YES = 'EXPIRES_YES'
}
/**
    * @export
    * @enum {string}
    */
export enum ListRoomShareTokensView {
    BASIC = 'VIEW_BASIC',
    FULL = 'VIEW_FULL',
    INVALID = 'VIEW_INVALID'
}
