import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
    AvatarWithBadge,
    Button,
    Flex,
    ProfileBlub,
    Sensor,
    StreemGlyphLegal,
    StreemWordmark,
    styled,
} from '@streem/ui-react';
import { recordElementClicked } from '@streem/analytics';
import { APITypes, StreemAPI } from '@streem/api';
import { StreemApiUpdateUserAvailabilityRequestAvailabilityStatus } from '@streem/api/src/openapi';
import { streem } from 'streem-sdk-protobuf';
import { observer } from 'mobx-react';
import { useGlobalStore } from '../../hooks/use_global_context';
import { useGetEmbedEnabledSettings } from '../../hooks/use_get_embed_enabled_settings';
import { AvailabilityIndicator } from '../expert_availability/availability_indicator';
import { UserFormStrategy } from '../../types/project.types';
import UserFormModal from '../../forms/user_form';
import { DeviceSettingsModal } from '../media_settings/device_settings_modal';
import { useGetCompanyStore } from '../../hooks/detail_store_hooks';
import { useActiveCompanyCode } from '../../hooks/use_active_company_code';
import { centralLoginUrl, removeProtocol } from '../../util/routing';
import { statusOptions, StatusOptionValueType } from '../expert_availability/status_option_value';
import AvailabilityStatus = streem.api.AvailabilityStatus;
import GroupReservationStatus = streem.api.GroupReservationStatus;
import { useAnonymousShareChecker } from '../../hooks/use_anonymous_share_checker';
import appLogger from '../../util/logging/app_logger';
import { SystemNotifications } from '../allow_notifications/system_notifications';

const log = appLogger;
const mediaSettingsOpenedId = 'media-settings-opened';
const profileBlubOpenedId = 'profile-blub-opened';

export const TopNavBar: FC = observer(() => {
    const { userStore, authStore, companySettingsStore, sdkStore } = useGlobalStore();
    const companyCode = useActiveCompanyCode();
    const companyStore = useGetCompanyStore();
    const { isEmbedView } = useGetEmbedEnabledSettings();
    const history = useHistory();
    const avatarButtonRef = useRef<HTMLDivElement>(null);
    const user: APITypes.StreemApiUser = userStore?.user;
    const availabilityStatusWallItem = sdkStore?.userAvailabilityStatus?.current;
    const groupReservationWallItem = sdkStore?.groupReservation?.current;
    const [showProfileBlub, setShowProfileBlub] = useState(false);
    const [showEditProfileModal, setShowEditProfileModal] = useState(false);
    const [showMediaSettingsModal, setShowMediaSettingsModal] = useState(false);
    const [showSystemNotifications, setShowSystemNotifications] = useState(false);
    const defaultStatus: Readonly<StatusOptionValueType> = statusOptions.find(statusOption =>
        statusOption.value.keyArray.includes(AvailabilityStatus.AVAILABILITY_STATUS_NOT_AVAILABLE),
    );
    const [status, setStatus] = useState(defaultStatus);

    const resetUserAvailabilityToOffline = async () => {
        if (
            companySettingsStore.expertAvailabilityEnabled &&
            [
                AvailabilityStatus.AVAILABILITY_STATUS_AVAILABLE,
                AvailabilityStatus.AVAILABILITY_STATUS_NOT_AVAILABLE,
            ].includes(availabilityStatusWallItem?.availabilityStatus) &&
            authStore
        ) {
            try {
                await StreemAPI.users.updateUserAvailability(authStore.userId, {
                    availabilityStatus:
                        StreemApiUpdateUserAvailabilityRequestAvailabilityStatus.OFFLINE,
                });
                log.info(`Updated Availability Status to OFFLINE on logout`);
            } catch (e) {
                log.error('There was an error changing the user availability status on logout', e);
            }
        }
    };

    const onLogOutHandler = async () => {
        await authStore.logout();
    };

    const isAnonymousSharePage = useAnonymousShareChecker(authStore);

    /**
     * Fetch companyStore for companySettings
     */
    useEffect(() => {
        const promise = companyStore.fetch(companyCode);
        return () => promise.cancel();
    }, [companyCode, companyStore, authStore]);

    const availabilityStatus = availabilityStatusWallItem?.availabilityStatus;
    const groupReservationStatus = groupReservationWallItem?.reservationStatus;
    const isConfirming =
        availabilityStatus === AvailabilityStatus.AVAILABILITY_STATUS_CONFIRMING &&
        groupReservationStatus === GroupReservationStatus.GROUP_RESERVATION_STATUS_CONFIRMING;
    const groupName = groupReservationWallItem?.group?.name;
    const reservationSid = groupReservationWallItem?.reservationSid;

    useEffect(() => {
        if (availabilityStatus != null) {
            const matchedStatus = statusOptions.find(statusOption =>
                statusOption.value.keyArray.includes(availabilityStatus),
            );
            // When the status is INVALID just set it to NOT_READY
            setStatus(matchedStatus || defaultStatus);
        }
    }, [availabilityStatus, defaultStatus]);

    useEffect(() => {
        if (isConfirming) {
            const confirmMatchedGroupReservation = async (
                companyCode: string,
                groupName: string,
                reservationSid: string,
            ) => {
                try {
                    await StreemAPI.companies.confirmMatchedGroupReservation(
                        companyCode,
                        groupName,
                        reservationSid,
                    );
                } catch (e) {
                    setStatus(defaultStatus);
                    log.error('There was an error confirming the group reservation', e);
                }
            };

            confirmMatchedGroupReservation(companyCode, groupName, reservationSid);
        }
    }, [isConfirming, companyCode, groupName, reservationSid, defaultStatus]);

    if (isEmbedView || isAnonymousSharePage) {
        return null;
    }
    const closeBlub = () => setShowProfileBlub(false);

    const onOpenDeviceSettings = () => {
        recordElementClicked(mediaSettingsOpenedId);
        setShowMediaSettingsModal(true);
    };
    return (
        <TopNavWrapper data-testid="top-nav">
            <LogoButton data-testid="nav-streem-logo" onClick={() => history.push('/')}>
                <Flex alignItems="center">
                    <StreemGlyphLegal color="white" style={{ minWidth: '37px' }} />
                    <StreemWordmarkWrapper>
                        <StreemWordmark color="white" />
                    </StreemWordmarkWrapper>
                </Flex>
            </LogoButton>
            <Flex>
                {companySettingsStore.expertAvailabilityEnabled && <AvailabilityIndicator />}
                {user ? (
                    <div ref={avatarButtonRef}>
                        <Button
                            variant="inline"
                            data-testid="nav-user-avatar"
                            onClick={() => {
                                // only record the click if showProfileBlub is false, that means the user is opening the blub.
                                if (!showProfileBlub) {
                                    recordElementClicked(profileBlubOpenedId);
                                }
                                setShowProfileBlub(!showProfileBlub);
                            }}
                        >
                            <AvatarWithBadge
                                src={user?.avatarUrl}
                                displayName={user?.name}
                                size="small"
                                statusColor={
                                    companySettingsStore.expertAvailabilityEnabled
                                        ? status?.value.color
                                        : undefined
                                }
                            />
                        </Button>
                    </div>
                ) : (
                    <LoginButton />
                )}
            </Flex>
            {showProfileBlub && (
                <ProfileWrapper>
                    <Sensor
                        onClickOutside={event => {
                            if (
                                event.target instanceof Element &&
                                avatarButtonRef.current.contains(event.target)
                            )
                                return;

                            closeBlub();
                        }}
                        onEscape={closeBlub}
                    >
                        <ProfileBlub
                            email={user?.email}
                            name={user?.name}
                            statusColor={
                                companySettingsStore.expertAvailabilityEnabled
                                    ? status?.value.color
                                    : undefined
                            }
                            statusText={
                                companySettingsStore.expertAvailabilityEnabled
                                    ? status?.label
                                    : undefined
                            }
                            avatarUrl={user?.avatarUrl}
                            onLogOut={onLogOutHandler}
                            onEditProfile={() => setShowEditProfileModal(true)}
                            canSwitchWorkspace={
                                companySettingsStore.switchWorkspacesEnabled &&
                                authStore.associatedCompanyCount > 1
                            }
                            centralLoginUrl={centralLoginUrl()}
                            workspace={
                                companySettingsStore.switchWorkspacesEnabled
                                    ? {
                                          name: companyStore.result?.name || 'Loading…',
                                          imageUrl: companySettingsStore.companyLogoUrl,
                                          url: removeProtocol(window.location.origin),
                                      }
                                    : undefined
                            }
                            onSwitchWorkspaces={resetUserAvailabilityToOffline}
                            onOpenDeviceSettings={onOpenDeviceSettings}
                            closeBlub={closeBlub}
                            onOpenSystemNotifications={() => setShowSystemNotifications(true)}
                        />
                    </Sensor>
                </ProfileWrapper>
            )}
            <UserFormModal
                isOpen={showEditProfileModal}
                strategy={UserFormStrategy.EDIT_SELF}
                onCancel={() => setShowEditProfileModal(false)}
                onSuccess={() => setShowEditProfileModal(false)}
                user={user}
            />

            <DeviceSettingsModal
                isOpen={showMediaSettingsModal}
                onClose={() => setShowMediaSettingsModal(false)}
            />

            <SystemNotifications
                isOpen={showSystemNotifications}
                onClose={() => setShowSystemNotifications(false)}
            />
        </TopNavWrapper>
    );
});

const TopNavWrapper = styled(Flex)(({ theme }) => ({
    backgroundColor: theme.colors.blue90,
    alignItems: 'center',
    width: '100vw',
    height: '72px',
    padding: '16px 24px',
    position: 'sticky',
    justifyContent: 'space-between',
    zIndex: 5,
}));

const LogoButton = styled.button(({ theme }) => ({
    padding: '0',
    backgroundColor: theme.colors.blue90,
    border: 'none',
    cursor: 'pointer',
}));

const StreemWordmarkWrapper = styled.div({
    width: '100%',
});

const ProfileWrapper = styled.div(({ theme }) => ({
    position: 'absolute',
    top: theme.space[6],
    right: theme.space[5],
}));

const LoginButton: FC = () => {
    const detailsMatch = useRouteMatch<{ roomSid: string }>('/share/:token/details/:roomSid');
    const artifactMatch = useRouteMatch<{ roomSid: string; artifactSid: string }>(
        '/share/:token/details/:roomSid/artifact/:artifactSid',
    );

    const handleClick = useCallback(() => {
        const redirectUrlParts = [''];

        if (detailsMatch) {
            redirectUrlParts.push('teamcalls', detailsMatch.params.roomSid);

            if (artifactMatch) {
                redirectUrlParts.push('artifact', artifactMatch.params.artifactSid);
            }
        }

        (window as any).location.href = redirectUrlParts.join('/');
    }, [detailsMatch, artifactMatch]);

    return (
        <Button data-testid={'nav-streem-login-shared-page'} onClick={handleClick}>
            Log In
        </Button>
    );
};
