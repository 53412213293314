import { Flex, styled } from '@streem/ui-react';

interface Props {
    hasIcon?: boolean;
}

export const Container = styled(Flex)<Props>(({ theme, hasIcon }) => ({
    minHeight: '120px',
    display: 'grid',
    gridTemplateColumns: hasIcon ? '3fr 300px auto' : '3fr 300px',
    columnGap: theme.space[4],
    marginLeft: theme.space[4],
    marginRight: theme.space[3],
    justifyContent: 'end',
    alignItems: 'center',
    flexShrink: 0,
}));
