import { useEffect, useState } from 'react';
import ImageEditor from 'tui-image-editor';
import {
    AppText,
    Button,
    IconNames,
    IconButton,
    Row,
    styled,
    Theme,
    Tooltip,
    TooltipButton,
    Column,
} from '@streem/ui-react';

enum ToolNames {
    CROP = 'Crop',
    DRAW = 'Draw',
    TEXT = 'Text',
    ROTATE = 'Rotate',
}

export const EditToolbar = ({
    imageEditor,
    isTrayOpen,
}: {
    imageEditor: React.MutableRefObject<ImageEditor>;
    isTrayOpen?: boolean;
}) => {
    const [activeTool, setActiveTool] = useState<ToolNames>(undefined);
    const [cropZoneSelected, setCropZoneSelected] = useState(false);

    useEffect(() => {
        imageEditor.current.stopDrawingMode();
        if (activeTool === ToolNames.CROP) {
            imageEditor.current.startDrawingMode('CROPPER');
        }

        if (activeTool === ToolNames.DRAW) {
            imageEditor.current.startDrawingMode('FREE_DRAWING', {
                color: Theme.colors.azure,
            });
        }
        if (activeTool === ToolNames.TEXT) {
            imageEditor.current.startDrawingMode('TEXT', {
                color: Theme.colors.azure,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTool]);

    useEffect(() => {
        if (imageEditor.current) {
            imageEditor.current.on('objectActivated', object => {
                if (object.type === 'cropzone' && object.width >= 1 && !cropZoneSelected)
                    setCropZoneSelected(true);
            });
        }
    }, [imageEditor, cropZoneSelected]);

    const editTools: {
        name: ToolNames;
        icon: IconNames;
        onClick?: () => void;
        tooltip: string;
    }[] = [
        {
            name: ToolNames.ROTATE,
            icon: 'RotateIcon',
            onClick: () => {
                setActiveTool(undefined);
                setCropZoneSelected(false);
                imageEditor.current.stopDrawingMode();
                imageEditor.current.rotate(-90);
            },
            tooltip: 'Rotate left',
        },
        {
            name: ToolNames.DRAW,
            icon: 'DrawSurfaceIcon',
            tooltip: 'Draw',
        },
        {
            name: ToolNames.TEXT,
            icon: 'FancyTextIcon',
            tooltip: 'Text',
        },
        {
            name: ToolNames.CROP,
            icon: 'CropIcon',
            tooltip: 'Crop',
        },
    ];

    const activeIndex = editTools.findIndex(tool => tool.name === activeTool);

    return (
        <div
            data-testid="streemshot-editor-tools"
            style={{
                width: '37.5rem',
            }}
        >
            <Row alignItems="center" justifyContent={isTrayOpen ? 'flex-end' : 'center'} mt="2px">
                <ButtonRowWrapper>
                    {editTools.map(tool => (
                        <ArtifactToolbarButton
                            key={tool.name}
                            name={tool.name}
                            icon={tool.icon}
                            onClick={
                                tool?.onClick
                                    ? tool.onClick
                                    : () => {
                                          setActiveTool(tool.name);
                                          setCropZoneSelected(false);
                                      }
                            }
                            tooltip={tool.tooltip}
                            activeTool={activeTool}
                            displayName
                            style={{
                                height: '30px',
                                width: '30px',
                                backgroundColor:
                                    activeIndex > 0 && activeTool === tool.name
                                        ? Theme.colors.azure
                                        : 'transparent',
                            }}
                        />
                    ))}
                    {activeTool === ToolNames.CROP && (
                        <Row
                            style={{
                                borderLeft: 'solid 1px black',
                                marginLeft: '8px',
                                paddingLeft: '8px',
                            }}
                        >
                            <Button
                                variant="inline"
                                disabled={!cropZoneSelected}
                                onClick={() => {
                                    imageEditor.current.crop(imageEditor.current.getCropzoneRect());
                                    imageEditor.current.stopDrawingMode();
                                    setActiveTool(undefined);
                                    setCropZoneSelected(false);
                                }}
                            >
                                Apply
                            </Button>
                        </Row>
                    )}
                </ButtonRowWrapper>
            </Row>
        </div>
    );
};

const ButtonRowWrapper = styled.div(({ theme }) => ({
    display: 'inline-flex',
    padding: '0 6px',
    borderRadius: '26px',
    minWidth: '305px',
    '> :not(:first-of-type)': {
        marginLeft: theme.spacing.m,
    },
}));

interface SaveEditsToolbarProps {
    saveRevision: () => void;
    undoStackCount: number;
    imageEditor: React.MutableRefObject<ImageEditor>;
}

export const SaveEditsToolbar = ({
    saveRevision,
    undoStackCount,
    imageEditor,
}: SaveEditsToolbarProps) => {
    const [imageEditorReady, setImageEditorReady] = useState(false);
    useEffect(() => {
        if (imageEditor?.current) {
            setImageEditorReady(true);
        }
    }, [imageEditor]);

    if (!imageEditorReady) return null;

    return (
        <SaveEditsToolbarWrapper>
            <Row
                alignItems={'center'}
                pr={Theme.spacing.m}
                pl={Theme.spacing.m}
                borderRadius={'26px 0 0 26px'}
                height="100%"
                backgroundColor={'rgba(255, 255, 255, 0.5)'}
            >
                <ArtifactToolbarButton
                    onClick={() => {
                        imageEditor.current.discardSelection();
                        imageEditor.current.undo();
                    }}
                    icon="UndoIcon"
                    tooltip="Undo"
                    name="undo"
                    disabled={undoStackCount === 0}
                />
                <ArtifactToolbarButton
                    icon="UndoAllIcon"
                    name="trash"
                    tooltip="Revert all changes"
                    disabled={undoStackCount === 0}
                    onClick={() => {
                        // @ts-ignore
                        imageEditor.current.undo(undoStackCount);
                    }}
                    style={{ marginLeft: '16px', marginRight: '16px' }}
                />
                <TooltipButton
                    disabledTooltip={undoStackCount === 0}
                    onClick={saveRevision}
                    variant="inline"
                    tooltipMessage={
                        <AppText size="small" color="dark" style={{ padding: '8px' }}>
                            Save changes
                        </AppText>
                    }
                    tooltipPlacement="top"
                    buttonText="Done"
                />
            </Row>
        </SaveEditsToolbarWrapper>
    );
};

const SaveEditsToolbarWrapper = styled(Row)({
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginLeft: 'auto',
});

const ArtifactToolbarButton = ({
    name,
    icon,
    onClick,
    tooltip,
    activeTool,
    disabled,
    style,
    displayName,
}: {
    name: string;
    icon: IconNames;
    onClick?: () => void;
    tooltip: string;
    activeTool?: string;
    disabled?: boolean;
    style?: {};
    displayName?: boolean;
}) => {
    const [showToolTip, setShowToolTip] = useState(false);

    useEffect(() => {
        if (disabled) setShowToolTip(false);
    }, [disabled]);

    return (
        <Tooltip
            message={
                <AppText size="small" color="dark" style={{ padding: '8px' }}>
                    {tooltip}
                </AppText>
            }
            showTooltip={showToolTip && !disabled}
            placement="top"
        >
            <Column style={{ textAlign: 'center' }} alignItems={'center'}>
                <IconButton
                    key={name}
                    background="transparent"
                    fill={name === activeTool ? Theme.colors.white : Theme.colors.azure}
                    onClick={onClick}
                    iconName={icon}
                    onMouseEnter={() => setShowToolTip(true)}
                    onMouseLeave={() => setShowToolTip(false)}
                    label={name}
                    disabled={disabled}
                    style={style}
                />
                {displayName && (
                    <AppText size="medium" color="azure" semibold>
                        {name}
                    </AppText>
                )}
            </Column>
        </Tooltip>
    );
};
