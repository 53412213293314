import { FC, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useObserver } from 'mobx-react';
import {
    AppText,
    Button,
    ButtonRow,
    Header,
    ListView,
    Modal,
    Row,
    styled,
    Subheader,
    Theme,
    ToastTypes,
} from '@streem/ui-react';
import { APITypes, StreemAPI } from '@streem/api';
import { useListGroupsStore } from '../../../hooks/list_store_hooks';
import { useGlobalStore } from '../../../hooks/use_global_context';
import { useActiveCompanyCode } from '../../../hooks/use_active_company_code';
import { joinPaths } from '../../../util/routing';
import { columnBuilder } from './group_list_columns';
import { ColumnHeader } from '../../lists/list_view_with_pagination';
import appLogger from '../../../util/logging/app_logger';
import { FlexCentered } from '../../../forms/shared_form.styles';

const log = appLogger.extend('Groups Page');

interface GroupRow extends APITypes.StreemApiGroup {
    key: string;
}

const GroupsList: FC = () => {
    const history = useHistory();
    const groupsStore = useListGroupsStore();
    const [activeRecord, setActiveRecord] = useState<GroupRow>();
    const companyCode = useActiveCompanyCode();
    const { uiStore } = useGlobalStore();

    const [isModalOpen, setIsModalOpen] = useState(false);

    return useObserver(() => {
        const groups = groupsStore.results.reduce((acc, group) => {
            if (acc.findIndex(a => a.sid === group.sid) === -1) {
                acc.push(group);
                return acc;
            } else {
                return acc;
            }
        }, []);

        const filteredGroups = groups.filter(group => {
            const filterList = ['COMPANY_ADMIN', 'COMPANY_OWNER', 'DEVELOPER', 'AGENT'];
            return !filterList.includes(group.name.toUpperCase());
        });

        const deleteGroup = async () => {
            try {
                setIsModalOpen(false);
                await StreemAPI.companies.deleteGroup(companyCode, activeRecord.name);
                uiStore.addToast({
                    content: `Successfully deleted group: ${activeRecord.name}`,
                    flavor: ToastTypes.SUCCESS,
                    id: `group-deleted-${activeRecord.name}`,
                });
                groupsStore.refresh();
            } catch (e) {
                log.error('Failed to delete group: ', e);
            }
        };

        const handleDelete = (row: GroupRow, e: any) => {
            e.stopPropagation();
            e.preventDefault();
            setIsModalOpen(true);
            setActiveRecord(row);
        };

        const columns = [
            columnBuilder.nameColumn(true),
            columnBuilder.descriptionColumn(),
            columnBuilder.deleteColumn({ handleDelete }),
        ];

        const rowClickUrl = (row: GroupRow) => {
            const url = joinPaths(history.location.pathname, row.name);
            history.push(url);
        };

        return groupsStore.lastError && groupsStore.lastError.status === 500 ? (
            <Redirect to="/error" />
        ) : (
            <>
                <ListViewGrid columns="1fr 1fr 100px" style={{ boxShadow: Theme.shadow.bottom }}>
                    {columns.map(column => (
                        <ColumnHeader
                            key={column.key}
                            column={column}
                            sortOrder={'ASC'}
                            contentJustify={column.contentJustify}
                        />
                    ))}
                </ListViewGrid>
                <ScrollableContainer>
                    <ListView
                        columns={columns}
                        data={filteredGroups
                            .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
                            .map(u => ({ key: u.sid!, ...u } as GroupRow))}
                        loading={groupsStore.loading}
                        loadingMore={groupsStore.loadingMore}
                        data-testid="group-list"
                        linkTo={rowClickUrl}
                        canRowClick={row => !!row}
                        buildRowTestId={(row: any) => `group-row-${row.name}`}
                        gridTemplateColumns="1fr 1fr 100px"
                    />
                </ScrollableContainer>
                {!groupsStore.loading && filteredGroups.length === 0 && (
                    <NoGroupsContainer>
                        <Subheader>No groups have been created yet.</Subheader>
                    </NoGroupsContainer>
                )}
                <DeleteConfirmationModal
                    isOpen={isModalOpen}
                    closeModal={() => setIsModalOpen(false)}
                    handleDelete={deleteGroup}
                    selectedGroup={activeRecord?.name}
                />
            </>
        );
    });
};

export default GroupsList;

const ListViewGrid = styled.div<{ columns?: string }>(({ columns }) => ({
    display: 'grid',
    gridTemplateColumns: columns ?? '1fr auto auto',
    margin: '0 10px',
    border: `1px solid ${Theme.colors.grey10}`,
}));

interface DeleteConfirmationModalProps {
    isOpen: boolean;
    closeModal: () => void;
    handleDelete: () => void;
    selectedGroup: string;
}

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
    isOpen,
    closeModal,
    handleDelete,
    selectedGroup,
}) => {
    return (
        <Modal
            data-testid="delete-group-confirmation-modal"
            onClose={closeModal}
            isOpen={isOpen}
            styleOverride={{
                content: {
                    maxWidth: '600px',
                    padding: Theme.spacing.l,
                },
            }}
        >
            <Row mb={4}>
                <ModalHeader data-testid="group-deletion-warning-header" size="large">
                    {`Are you sure you want to delete ${selectedGroup} group?`}
                </ModalHeader>
            </Row>
            <Row mb={5}>
                <AppText>
                    All members will be removed from the group. This cannot be undone.
                </AppText>
            </Row>
            <ModalButtonRow>
                <Button variant="secondary" onClick={closeModal}>
                    Cancel
                </Button>
                <Button
                    variant="primary"
                    data-testid="delete-group-confirmation"
                    onClick={handleDelete}
                >
                    Delete
                </Button>
            </ModalButtonRow>
        </Modal>
    );
};

const ModalButtonRow = styled(ButtonRow)({
    justifyContent: 'flex-end',
    width: '100%',
});

const ModalHeader = styled(Header)({
    width: '85%',
});

const ScrollableContainer = styled.div({
    padding: '0 10px',
    overflowX: 'auto',
});

const NoGroupsContainer = styled(FlexCentered)({
    marginTop: '80px',
});
