import { Theme } from '../types/theme.types';
import { animationKeyframes } from './keyframes';

export const colors = {
    black: '#000000',
    blackA10: 'rgba(44,44,44,0.1)',
    blackA20: 'rgba(44,44,44,0.2)',
    blackA30: 'rgba(44,44,44,0.3)',
    blackA40: 'rgba(44,44,44,0.4)',
    blackA50: 'rgba(44,44,44,0.5)',
    grey90: '#1F1F21',
    grey80: '#353739',
    grey70: '#4A4D51',
    grey60: '#5F646A',
    grey50: '#727780',
    grey40: '#8A8F97',
    grey30: '#A9AEB4',
    grey20: '#C0C4CA',
    grey10: '#E3E6EA',
    grey05: '#F2F4F6',
    greyA50: '#1F1F2150',
    white: '#ffffff',
    whiteA20: 'rgba(255,255,255,0.2)',
    whiteA50: 'rgba(255,255,255,0.5)',
    whiteA70: 'rgba(255,255,255,0.8)',

    darkGradientTop: '#0A397A',
    darkGradientBottom: '#093D84',
    darkBlue: '#134570',
    darkGradientMiddle: '#0752AA',
    navyBlue: '#0070CC',
    borderBlue: '#008CFF',
    azure: '#008CFF',
    lightGradientMiddle: '#00BFFF',
    aqua: '#00C0FF',
    azureTransparent: 'rgba(0,140,255,0.65)',
    lightGradientTop: '#23E5E5',
    lightBlue: '#E9F1F6',
    hoverBlue: '#E9F1F6',
    pumpedHoverBlue: '#D6EAF6',
    mediumBlue: '#2D6FF3',
    turquoise: '#25E5E5',
    greenShamrock: '#11BF9F',
    errorPink: '#FAF0F3',
    orange: '#F56918',
    orange05: '#FDF2EC',
    orange60: '#B04119',
    yellow: '#FFDA33',
    darkBluePearlSemiTransparent: 'rgb(7, 30, 49,.45)',
    darkBlueSemiOpaque: 'rgb(18,69,112,.5)',
    transparent: 'rgba(0,0,0,0)',
    pumpkin: '#FBB748',
    phosphorGreen: '#00FF19',

    blue05: '#E7F6FE',
    blue40: '#0A95FF',
    blue50: '#0077FF',
    blue60: '#0061E0',
    blue70: '#0052CC',
    blue80: '#0C3B81',
    blue90: '#082D61',
    red10: '#FCE3E6',
    red40: '#ED4747',
    red50: '#D62A29',
    red50Transparent: '#D62A2980', // 50% transparency: https://stackoverflow.com/a/17239853
    red60: '#BD2121',
    green10: '#CAEBD9',
    green50: '#027D59',
    green60: '#0E6B4F',
    yellow10: '#FAECB0',
};

const gradients = {
    darkGradient: `linear-gradient(180deg, ${colors.darkGradientBottom} 0%, ${colors.darkGradientMiddle} 56.25%, ${colors.darkGradientTop} 100%);`,
    lightGradient: `linear-gradient(90deg,${colors.azure} 0%,${colors.lightGradientMiddle} 57.47%,${colors.lightGradientTop} 100%)`,
};

export const fontSizes = ['.75rem', '1rem', '1.25rem', '1.8rem', '2.5rem'] as const;

const text = {
    headingFontFamily: "'Poppins', sans-serif",
    bodyFontFamily: "'ASAP', sans-serif",
    color: {
        light: colors.white,
        medium: colors.grey60,
        dark: colors.grey90,
        inherit: 'inherit',
        ...colors,
    },
    size: {
        small: '.75rem',
        medium: '1rem',
        mediumLarge: '1.25rem',
        large: '1.5rem',
        xlarge: '1.75rem',
        xxlarge: '2.25rem',
    },
    lineHeight: {
        small: '1.5',
        medium: '1.5',
        mediumLarge: '1.5',
        large: '1.625',
        xlarge: '1.6',
        xxlarge: '1.5',
    },
};

const shadow = {
    inner: 'inset 0px 2px 2px rgba(44, 44, 44, 0.24), inset 0px 0px 2px rgba(0, 0, 0, 0.12)',
    small: '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)',
    large: '0px 0px 6px rgba(0, 0, 0, 0.12), 0px 6px 6px rgba(0, 0, 0, 0.24)',
    bottom: '0 8px 6px -6px rgba(0, 0, 0, 0.12) ',
    bottomLarge: '0px 0px 8px 0px #0000001F, 0px 8px 8px 0px #0000003D',
    xlarge: '0px 0px 24px rgba(0, 0, 0, 0.2), 0px 20px 24px rgba(0, 0, 0, 0.2)',
};

export const focus = {
    outline: {
        outline: '#76c1ff auto 3px',
    },
    shadow: {
        boxShadow: '0px 0px 1px 3px #76c1ff',
    },
};

const buttons = {
    primary: {
        color: colors.white,
        backgroundColor: colors.azure,
        boxShadow: shadow.small,
        /** extra weight on the following states needed to override our js-focus lib */
        '&&&:focus': {
            boxShadow: shadow.small,
        },
        '&&&:hover': {
            boxShadow: shadow.large,
        },
        '&&&:active': {
            backgroundColor: colors.navyBlue,
            boxShadow: 'none',
        },
        '&&&:disabled': {
            cursor: 'not-allowed',
            backgroundColor: colors.grey30,
            ':hover': {
                boxShadow: shadow.small,
            },
        },
    },
    secondary: {
        color: colors.grey90,
        backgroundColor: colors.white,
        border: `2px solid ${colors.grey90}`,
        ':hover': {
            backgroundColor: colors.lightBlue,
        },
        ':active': {
            borderColor: colors.navyBlue,
            color: colors.navyBlue,
            backgroundColor: colors.white,
        },
        ':disabled': {
            color: colors.grey30,
            backgroundColor: colors.grey05,
            borderColor: colors.grey30,
        },
    },
    danger: {
        color: colors.white,
        backgroundColor: colors.red50,
        boxShadow: shadow.small,
        ':hover': {
            boxShadow: shadow.large,
        },
        ':active': {
            borderColor: colors.navyBlue,
            color: colors.navyBlue,
            backgroundColor: colors.white,
        },
        ':disabled': {
            color: colors.grey30,
            backgroundColor: colors.grey05,
            borderColor: colors.grey30,
        },
    },
    tertiary: {
        display: 'inline-block',
        width: 'unset',
        boxShadow: 'none',
        background: 'none',
        padding: '8px 16px',
        border: '2px solid transparent',
        height: 'unset',
        margin: '0',
        color: colors.borderBlue,
        fontWeight: '600',
        cursor: 'pointer',
        textDecoration: 'none',
        ':hover, :active, :focus': {
            border: `2px solid ${colors.lightBlue}`,
            background: colors.lightBlue,
            boxShadow: 'none',
        },
        ':focus-within': focus.shadow,
    },
    transparent: {
        color: colors.white,
        backgroundColor: colors.transparent,
        border: `1px solid ${colors.whiteA50}`,
    },
    inline: {
        display: 'inline',
        width: 'unset',
        height: 'unset',
        padding: 'revert',
        color: colors.azure,
        backgroundColor: colors.transparent,
        borderRadius: '0',
        ':disabled': {
            color: colors.grey30,
        },
    },
};

const iconSizes = {
    small: 15,
    medium: 20,
    large: 30,
    xlarge: 150,
};

const iconButtonSizes = {
    small: '20px',
    medium: '40px',
    CVLarge: '56px',
    large: '60px',
};

const switchSizes = {
    small: {
        height: '16px',
        width: '24px',
        borderRadius: '8px',
        fontSize: '12px',
    },
    large: {
        height: '24px',
        width: '40px',
        borderRadius: '12px',
        fontSize: '20px',
    },
};

const avatarSizes = {
    xsmall: 25,
    small: 40,
    medium: 60,
    large: 80,
    larger: 88,
    xlarge: 200,
};

const space = [0, 2, 4, 8, 16, 32, 64];

const spacing = {
    none: '0',
    xxs: '2px',
    xs: '4px',
    s: '8px',
    m: '16px',
    l: '32px',
    xl: '64px',
};

const radii = [0, 5, 10, 15, 20];

const borders = { black: { border: '0.5px solid black', borderRadius: '2.5' } };

const layers = {
    base: 0,
    ui: 10,
    modal: 20,
    notification: 30,
    callFrame: 40,
    inCall: {
        modal: 50,
        notification: 60,
    },
};

const mediaBreakpoints = {
    xsmall: '(max-width: 480px)',
    small: '(max-width: 1239px)',
};

export type ThemeColors = typeof colors[keyof typeof colors];

export default {
    colors,
    gradients,
    text,
    buttons,
    shadow,
    space,
    spacing,
    radii,
    avatarSizes,
    switchSizes,
    iconSizes,
    iconButtonSizes,
    fontSizes,
    keyframes: animationKeyframes,
    borders,
    focus,
    layers,
    mediaBreakpoints,
} as Theme;
