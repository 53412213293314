import { FC, useLayoutEffect, useMemo, useRef } from 'react';
import {
    AppIcon,
    AppText,
    Box,
    IconButton,
    Label,
    Row,
    Select,
    styled,
    useTheme,
    ReactSelectComponents,
} from '@streem/ui-react';
import { Diagnosis } from '@streem/sdk-core';
import { customFilterOption } from '../../util/call_disposition_helpers';

interface DiagnosisDetailSelectionProps {
    diagnosisOptions: Diagnosis[];
    diagnoses: Diagnosis[];
    handleSetDiagnoses: (
        callback: (diagnosis: Diagnosis) => Diagnosis,
        newDiagnosis?: Diagnosis,
    ) => void;
}
export const DiagnosisDetailSelection: FC<DiagnosisDetailSelectionProps> = ({
    diagnosisOptions,
    diagnoses,
    handleSetDiagnoses,
}) => {
    const editDiagnosis = (prevDiagnosis: Diagnosis, newDiagnosis: Diagnosis) => {
        handleSetDiagnoses((d: Diagnosis) => {
            if (d.code === prevDiagnosis.code) {
                return newDiagnosis;
            } else {
                return d;
            }
        });
    };

    const deleteDiagnosis = (diagnosis: Diagnosis) => {
        handleSetDiagnoses((d: Diagnosis) => {
            if (d.code === diagnosis.code) {
                return null;
            } else {
                return d;
            }
        });
    };

    const addDiagnosis = (diagnosis: Diagnosis) => {
        handleSetDiagnoses(diagnosis => diagnosis, diagnosis);
    };

    const findDiagnosisIndex = (diagnosis: Diagnosis) => {
        const index = diagnosisOptions.findIndex(d => diagnosis.code === d.code);
        return index;
    };
    const firstRender = useRef(true);

    const getInitialValue = (diagnosis: Diagnosis) => {
        const diagnosisIndexMatch = findDiagnosisIndex(diagnosis);
        if (diagnosisIndexMatch !== -1) {
            const diagnosisMatch = diagnosisOptions[diagnosisIndexMatch];
            return {
                label: diagnosisMatch.label,
                value: diagnosisMatch.code,
            };
        } else {
            return {
                label: '',
                value: '',
            };
        }
    };
    const diagnosesEmpty = diagnoses.length === 0;
    const inputOptions = useMemo(() => {
        return diagnosisOptions.map(d => ({
            value: d.code,
            label: d.label,
        }));
    }, [diagnosisOptions]);

    useLayoutEffect(() => {
        if (diagnosesEmpty || diagnoses.length === 1 || firstRender.current) {
            const firstSearchInput = document.querySelector(
                '[aria-label="call diagnosis options 1"]',
            ) as HTMLInputElement;
            firstSearchInput.focus();
        } else if (diagnoses.length === 2) {
            const secondSearchInput = document.querySelector(
                '[aria-label="call diagnosis options 2"]',
            ) as HTMLInputElement;
            secondSearchInput.focus();
        } else if (diagnoses.length === 3) {
            const thirdSearchInput = document.querySelector(
                '[aria-label="call diagnosis options 3"]',
            ) as HTMLInputElement;
            thirdSearchInput.focus();
        }
        if (firstRender.current) {
            firstRender.current = false;
        }
    }, [diagnoses, diagnosesEmpty]);
    return (
        <Box width="100%">
            {diagnosesEmpty ? (
                <LabelSearchInput
                    label="Diagnosis"
                    placeholder="Search here"
                    ariaLabel="call diagnosis options 1"
                    deletable={false}
                    labelNumber={1}
                    handleChange={val => addDiagnosis({ code: val.value, label: val.label })}
                    options={inputOptions}
                />
            ) : (
                diagnoses.map((diagnosis, idx) => {
                    return (
                        <LabelSearchInput
                            ariaLabel={`call diagnosis options ${idx + 1}`}
                            key={diagnosis.code}
                            placeholder="Search here"
                            label="Diagnosis"
                            deletable={diagnoses.length > 1}
                            labelNumber={idx + 1}
                            handleDelete={() => {
                                deleteDiagnosis(diagnosis);
                            }}
                            handleChange={val =>
                                editDiagnosis(diagnosis, { code: val.value, label: val.label })
                            }
                            options={inputOptions.filter(opt => {
                                const currentlySelectedDispositionCodes: string[] = [];
                                diagnoses.forEach(diag => {
                                    if (diag.code !== diagnosis.code) {
                                        currentlySelectedDispositionCodes.push(diag.code);
                                    }
                                });
                                return !currentlySelectedDispositionCodes.includes(opt.value);
                            })}
                            required={idx === 0}
                            initialValue={getInitialValue(diagnosis)}
                        />
                    );
                })
            )}
            {diagnosisOptions.length - diagnoses.length > 0 && diagnoses.length < 3 && (
                <Row ml={2} mt={4}>
                    <AddButton
                        onClick={() => {
                            const uniqueKey = Date.now().toString();
                            addDiagnosis({ code: uniqueKey, label: '' });
                        }}
                    />
                    <Box ml={2}>
                        <AppText semibold headingFontFamily>
                            Add Diagnosis
                        </AppText>
                    </Box>
                </Row>
            )}
        </Box>
    );
};

const BaseButton = styled.button`
    width: 24px;
    height: 24px;
    border: none;
    padding: 0;
    margin: 0;
    border-radius: 12px;
    background: ${props => props.theme.colors.azure};
    :hover {
        cursor: pointer;
    }
`;

export const AddButton: FC<{ onClick: () => void }> = ({ onClick }) => {
    return (
        <BaseButton data-testid="add-diagnosis-button" type="button" onClick={onClick}>
            <AppIcon name="AddIcon" size="small" color="white" />
        </BaseButton>
    );
};

interface LabelSearchInputProps {
    label: string;
    handleDelete?: (val: any) => void;
    options: any;
    placeholder?: string;
    initialValue?: { label: string; value: string };
    handleChange: (val: any) => void;
    ariaLabel: string;
    deletable?: boolean;
    required?: boolean;
    labelNumber?: number;
}
export const LabelSearchInput: FC<LabelSearchInputProps> = ({
    label,
    deletable,
    handleDelete,
    options,
    handleChange,
    initialValue,
    ariaLabel,
    placeholder,
    labelNumber = 1,
    required = true,
}) => {
    const theme = useTheme();
    const { red50 } = theme.colors;

    return (
        <>
            <Box mb={3} mt={4}>
                <Label semibold>{label}</Label>
            </Box>
            <Row>
                <Box width="100%">
                    <Select
                        filterOption={customFilterOption}
                        aria-label={ariaLabel}
                        size="medium"
                        border
                        onSelect={handleChange}
                        isSearchable
                        placeholder={placeholder}
                        options={options}
                        initialValue={initialValue}
                        components={{
                            SelectContainer: ({ children, ...props }) => (
                                <ReactSelectComponents.SelectContainer
                                    {...props}
                                    innerProps={{
                                        ...props.innerProps,
                                        'data-testid': `${label}-select-${labelNumber}`,
                                    }}
                                >
                                    {children}
                                </ReactSelectComponents.SelectContainer>
                            ),
                            Input: ({ children, ...props }) => (
                                <ReactSelectComponents.Input
                                    {...props}
                                    data-testid={`${label}-input-${labelNumber}`}
                                >
                                    {children}
                                </ReactSelectComponents.Input>
                            ),
                        }}
                        formatOptionLabel={(option: any) => (
                            <AppText data-testid={`${label}-option-${option.label}`}>
                                {option.label}
                            </AppText>
                        )}
                    />
                </Box>
                {deletable && (
                    <IconButton
                        iconName={'CloseIcon'}
                        label={'delete-btn'}
                        data-testid={`delete-${label}-${labelNumber}`}
                        hoverFill={red50}
                        onClick={handleDelete}
                    />
                )}
            </Row>
            {required && (
                <Box my={2}>
                    <AppText semibold size="small">
                        Required*
                    </AppText>
                </Box>
            )}
        </>
    );
};
