import { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TrayLayout, ToastTypes } from '@streem/ui-react';
import { Streemshot, EditStreemshot } from './artifact';
import { EditToolbar, SaveEditsToolbar } from './artifact_details_tools';
import ImageEditor from 'tui-image-editor';
import { PrevNextButtons, ShareAndCloseButtons } from './artifact_details_actions';
import { StreemshotDetailsSidebar } from './artifact_details_tray';
import { recordArtifactViewed } from '@streem/analytics';
import { useObservable } from '@streem/sdk-react';
import { useDetailSession } from '../../hooks/detail_session_hooks';
import { removeSegments } from '../../util/routing';
import { convertDataURItoBlob } from '../../util/convert_to_blob';
import appLogger from '../../util/logging/app_logger';
import { useGlobalStore } from '../../hooks/use_global_context';

/**
 * Artifact details view, including notes, tags, image, editing, and navigation
 */
export const StreemshotDetailsView: React.FC<{
    isMobile: boolean;
    readOnly: boolean;
}> = ({ isMobile, readOnly }) => {
    const { artifactSid } = useParams<{ artifactSid: string }>();
    const detailSession = useDetailSession();
    const [trayOpen, setTrayOpen] = useState(isMobile ? false : true);
    const [editMode, setEditMode] = useState(false);
    const [showExitModal, setShowExitModal] = useState(false);
    const [undoStackCount, setUndoStackCount] = useState<number>(0);
    const [revisionOriginalId, setRevisionOriginalId] = useState<string>(undefined);

    const [artifactsOfInterest = []] = useObservable(detailSession.streemshots.responses);
    const activeArtifact = artifactsOfInterest.find(ss => ss.id === artifactSid);
    const history = useHistory();
    const imageEditor = useRef<ImageEditor>(null);
    const { uiStore } = useGlobalStore();

    const buildArtifactUrl = (newArtifactID: string) => {
        return history.location.pathname.split('/').slice(0, -1).concat(newArtifactID).join('/');
    };

    useEffect(() => {
        if (activeArtifact) {
            recordArtifactViewed({
                artifactSid: activeArtifact.id,
                roomSid: detailSession.roomId.toString(),
                streemshot: {},
            });
        }
    }, [activeArtifact, detailSession.roomId]);

    useEffect(() => {
        // if the streemshot is a revision, save the id of the original streemshot in case the user reverts
        // and we need to navigate to show the original
        if (activeArtifact && activeArtifact.streemshotId) {
            setRevisionOriginalId(activeArtifact?.streemshotId);
        }
        if (!activeArtifact && revisionOriginalId) {
            const newRevision = artifactsOfInterest.find(
                item => item.streemshotId === revisionOriginalId,
            );
            history.push(buildArtifactUrl(newRevision.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeArtifact]);

    // loading
    if (!activeArtifact) return null;

    const activeArtifactIndex = artifactsOfInterest.indexOf(activeArtifact);
    const previousArtifact =
        activeArtifactIndex > 0 ? artifactsOfInterest[activeArtifactIndex - 1] : undefined;
    const nextArtifact =
        activeArtifactIndex >= 0 ? artifactsOfInterest[activeArtifactIndex + 1] : undefined;

    const saveStreemshotRevision = async () => {
        const data = imageEditor.current.toDataURL();
        const blob = convertDataURItoBlob(data);
        const idToSend = activeArtifact.streemshotId || activeArtifact.id;
        try {
            // if no edits were made, return to call details page without saving
            if (undoStackCount === 0) {
                history.push(removeSegments(history.location.pathname, 2));
                return;
            }
            await detailSession.streemshots.saveEditedStreemshot(blob, idToSend);
            if (showExitModal) {
                setShowExitModal(false);
            }
            history.push(removeSegments(history.location.pathname, 2));
        } catch (error) {
            appLogger.error('Error saving streemshot revision', error);
            uiStore.addToast({
                content: `Uh oh, we were unable to save the changes to your Streemshot.\n\nPlease try again. `,
                flavor: ToastTypes.ERROR,
                id: 'streemshot-revision-fail',
            });
        }
    };

    return (
        <TrayLayout
            testId="artifact-details-view"
            isOpen={trayOpen}
            onToggleClick={() => {
                if (trayOpen) {
                    (document.activeElement as HTMLElement).blur();
                }
                setTrayOpen(!trayOpen);
            }}
            toggleText={'Details'}
            stageContent={
                <>
                    <ShareAndCloseButtons
                        editing={editMode}
                        setEditMode={() => setEditMode(!editMode)}
                        roomSid={detailSession.roomId.toString()}
                        setShowExitModal={() => setShowExitModal(true)}
                        artifact={activeArtifact}
                    />
                    {!editMode && (
                        <PrevNextButtons
                            previousArtifactSid={previousArtifact?.id}
                            nextArtifactSid={nextArtifact?.id}
                        />
                    )}
                    {!editMode ? (
                        <Streemshot artifact={activeArtifact} />
                    ) : (
                        <EditStreemshot
                            showExitModal={showExitModal}
                            setShowExitModal={setShowExitModal}
                            saveRevision={saveStreemshotRevision}
                            artifact={activeArtifact}
                            imageEditor={imageEditor}
                            undoStackCount={undoStackCount}
                            setUndoStackCount={setUndoStackCount}
                            setEditMode={setEditMode}
                        />
                    )}
                </>
            }
            trayContent={
                <StreemshotDetailsSidebar
                    key={activeArtifact.id}
                    artifact={activeArtifact}
                    isOpen={trayOpen}
                    readOnly={readOnly}
                />
            }
            toolbarCentralContent={
                editMode ? <EditToolbar isTrayOpen={trayOpen} imageEditor={imageEditor} /> : null
            }
            toolbarRightContent={
                editMode ? (
                    <SaveEditsToolbar
                        undoStackCount={undoStackCount}
                        saveRevision={saveStreemshotRevision}
                        imageEditor={imageEditor}
                    />
                ) : null
            }
        />
    );
};
