/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiAnalyticEventArtifactCaptured,
    StreemApiAnalyticEventArtifactCapturedFromJSON,
    StreemApiAnalyticEventArtifactCapturedFromJSONTyped,
    StreemApiAnalyticEventArtifactCapturedToJSON,
    StreemApiAnalyticEventArtifactDeleted,
    StreemApiAnalyticEventArtifactDeletedFromJSON,
    StreemApiAnalyticEventArtifactDeletedFromJSONTyped,
    StreemApiAnalyticEventArtifactDeletedToJSON,
    StreemApiAnalyticEventArtifactFulfilled,
    StreemApiAnalyticEventArtifactFulfilledFromJSON,
    StreemApiAnalyticEventArtifactFulfilledFromJSONTyped,
    StreemApiAnalyticEventArtifactFulfilledToJSON,
    StreemApiAnalyticEventArtifactRequestRejected,
    StreemApiAnalyticEventArtifactRequestRejectedFromJSON,
    StreemApiAnalyticEventArtifactRequestRejectedFromJSONTyped,
    StreemApiAnalyticEventArtifactRequestRejectedToJSON,
    StreemApiAnalyticEventArtifactRequested,
    StreemApiAnalyticEventArtifactRequestedFromJSON,
    StreemApiAnalyticEventArtifactRequestedFromJSONTyped,
    StreemApiAnalyticEventArtifactRequestedToJSON,
    StreemApiAnalyticEventArtifactUpdated,
    StreemApiAnalyticEventArtifactUpdatedFromJSON,
    StreemApiAnalyticEventArtifactUpdatedFromJSONTyped,
    StreemApiAnalyticEventArtifactUpdatedToJSON,
    StreemApiAnalyticEventArtifactViewed,
    StreemApiAnalyticEventArtifactViewedFromJSON,
    StreemApiAnalyticEventArtifactViewedFromJSONTyped,
    StreemApiAnalyticEventArtifactViewedToJSON,
    StreemApiAnalyticEventCameraStatusSet,
    StreemApiAnalyticEventCameraStatusSetFromJSON,
    StreemApiAnalyticEventCameraStatusSetFromJSONTyped,
    StreemApiAnalyticEventCameraStatusSetToJSON,
    StreemApiAnalyticEventCameraSubjectChangeRejected,
    StreemApiAnalyticEventCameraSubjectChangeRejectedFromJSON,
    StreemApiAnalyticEventCameraSubjectChangeRejectedFromJSONTyped,
    StreemApiAnalyticEventCameraSubjectChangeRejectedToJSON,
    StreemApiAnalyticEventCameraSubjectChangeRequested,
    StreemApiAnalyticEventCameraSubjectChangeRequestedFromJSON,
    StreemApiAnalyticEventCameraSubjectChangeRequestedFromJSONTyped,
    StreemApiAnalyticEventCameraSubjectChangeRequestedToJSON,
    StreemApiAnalyticEventCameraSubjectSet,
    StreemApiAnalyticEventCameraSubjectSetFromJSON,
    StreemApiAnalyticEventCameraSubjectSetFromJSONTyped,
    StreemApiAnalyticEventCameraSubjectSetToJSON,
    StreemApiAnalyticEventConnectionStatusChanged,
    StreemApiAnalyticEventConnectionStatusChangedFromJSON,
    StreemApiAnalyticEventConnectionStatusChangedFromJSONTyped,
    StreemApiAnalyticEventConnectionStatusChangedToJSON,
    StreemApiAnalyticEventElementClicked,
    StreemApiAnalyticEventElementClickedFromJSON,
    StreemApiAnalyticEventElementClickedFromJSONTyped,
    StreemApiAnalyticEventElementClickedToJSON,
    StreemApiAnalyticEventFavoriteUpdated,
    StreemApiAnalyticEventFavoriteUpdatedFromJSON,
    StreemApiAnalyticEventFavoriteUpdatedFromJSONTyped,
    StreemApiAnalyticEventFavoriteUpdatedToJSON,
    StreemApiAnalyticEventGuidedExperiencePhaseUpdated,
    StreemApiAnalyticEventGuidedExperiencePhaseUpdatedFromJSON,
    StreemApiAnalyticEventGuidedExperiencePhaseUpdatedFromJSONTyped,
    StreemApiAnalyticEventGuidedExperiencePhaseUpdatedToJSON,
    StreemApiAnalyticEventIdentifyAttempted,
    StreemApiAnalyticEventIdentifyAttemptedFromJSON,
    StreemApiAnalyticEventIdentifyAttemptedFromJSONTyped,
    StreemApiAnalyticEventIdentifyAttemptedToJSON,
    StreemApiAnalyticEventInviteClicked,
    StreemApiAnalyticEventInviteClickedFromJSON,
    StreemApiAnalyticEventInviteClickedFromJSONTyped,
    StreemApiAnalyticEventInviteClickedToJSON,
    StreemApiAnalyticEventInviteCreated,
    StreemApiAnalyticEventInviteCreatedFromJSON,
    StreemApiAnalyticEventInviteCreatedFromJSONTyped,
    StreemApiAnalyticEventInviteCreatedToJSON,
    StreemApiAnalyticEventLocalAudioStatsMeasured,
    StreemApiAnalyticEventLocalAudioStatsMeasuredFromJSON,
    StreemApiAnalyticEventLocalAudioStatsMeasuredFromJSONTyped,
    StreemApiAnalyticEventLocalAudioStatsMeasuredToJSON,
    StreemApiAnalyticEventLocalVideoStatsMeasured,
    StreemApiAnalyticEventLocalVideoStatsMeasuredFromJSON,
    StreemApiAnalyticEventLocalVideoStatsMeasuredFromJSONTyped,
    StreemApiAnalyticEventLocalVideoStatsMeasuredToJSON,
    StreemApiAnalyticEventLoginAttempted,
    StreemApiAnalyticEventLoginAttemptedFromJSON,
    StreemApiAnalyticEventLoginAttemptedFromJSONTyped,
    StreemApiAnalyticEventLoginAttemptedToJSON,
    StreemApiAnalyticEventMicrophoneStatusSet,
    StreemApiAnalyticEventMicrophoneStatusSetFromJSON,
    StreemApiAnalyticEventMicrophoneStatusSetFromJSONTyped,
    StreemApiAnalyticEventMicrophoneStatusSetToJSON,
    StreemApiAnalyticEventModalViewed,
    StreemApiAnalyticEventModalViewedFromJSON,
    StreemApiAnalyticEventModalViewedFromJSONTyped,
    StreemApiAnalyticEventModalViewedToJSON,
    StreemApiAnalyticEventNoteSaved,
    StreemApiAnalyticEventNoteSavedFromJSON,
    StreemApiAnalyticEventNoteSavedFromJSONTyped,
    StreemApiAnalyticEventNoteSavedToJSON,
    StreemApiAnalyticEventPermissionRequested,
    StreemApiAnalyticEventPermissionRequestedFromJSON,
    StreemApiAnalyticEventPermissionRequestedFromJSONTyped,
    StreemApiAnalyticEventPermissionRequestedToJSON,
    StreemApiAnalyticEventRemoteAudioStatsMeasured,
    StreemApiAnalyticEventRemoteAudioStatsMeasuredFromJSON,
    StreemApiAnalyticEventRemoteAudioStatsMeasuredFromJSONTyped,
    StreemApiAnalyticEventRemoteAudioStatsMeasuredToJSON,
    StreemApiAnalyticEventRemoteVideoStatsMeasured,
    StreemApiAnalyticEventRemoteVideoStatsMeasuredFromJSON,
    StreemApiAnalyticEventRemoteVideoStatsMeasuredFromJSONTyped,
    StreemApiAnalyticEventRemoteVideoStatsMeasuredToJSON,
    StreemApiAnalyticEventResolutionChanged,
    StreemApiAnalyticEventResolutionChangedFromJSON,
    StreemApiAnalyticEventResolutionChangedFromJSONTyped,
    StreemApiAnalyticEventResolutionChangedToJSON,
    StreemApiAnalyticEventScreenViewed,
    StreemApiAnalyticEventScreenViewedFromJSON,
    StreemApiAnalyticEventScreenViewedFromJSONTyped,
    StreemApiAnalyticEventScreenViewedToJSON,
    StreemApiAnalyticEventToolMoved,
    StreemApiAnalyticEventToolMovedFromJSON,
    StreemApiAnalyticEventToolMovedFromJSONTyped,
    StreemApiAnalyticEventToolMovedToJSON,
    StreemApiAnalyticEventToolPlaced,
    StreemApiAnalyticEventToolPlacedFromJSON,
    StreemApiAnalyticEventToolPlacedFromJSONTyped,
    StreemApiAnalyticEventToolPlacedToJSON,
    StreemApiAnalyticEventToolRemoved,
    StreemApiAnalyticEventToolRemovedFromJSON,
    StreemApiAnalyticEventToolRemovedFromJSONTyped,
    StreemApiAnalyticEventToolRemovedToJSON,
    StreemApiAnalyticEventTooltipViewed,
    StreemApiAnalyticEventTooltipViewedFromJSON,
    StreemApiAnalyticEventTooltipViewedFromJSONTyped,
    StreemApiAnalyticEventTooltipViewedToJSON,
    StreemApiAnalyticEventVignetteDrawerExpanded,
    StreemApiAnalyticEventVignetteDrawerExpandedFromJSON,
    StreemApiAnalyticEventVignetteDrawerExpandedFromJSONTyped,
    StreemApiAnalyticEventVignetteDrawerExpandedToJSON,
    StreemApiAnalyticEventVignetteElementSelected,
    StreemApiAnalyticEventVignetteElementSelectedFromJSON,
    StreemApiAnalyticEventVignetteElementSelectedFromJSONTyped,
    StreemApiAnalyticEventVignetteElementSelectedToJSON,
    StreemApiAnalyticEventVignetteFinishChanged,
    StreemApiAnalyticEventVignetteFinishChangedFromJSON,
    StreemApiAnalyticEventVignetteFinishChangedFromJSONTyped,
    StreemApiAnalyticEventVignetteFinishChangedToJSON,
    StreemApiAnalyticEventVignetteHotspotClicked,
    StreemApiAnalyticEventVignetteHotspotClickedFromJSON,
    StreemApiAnalyticEventVignetteHotspotClickedFromJSONTyped,
    StreemApiAnalyticEventVignetteHotspotClickedToJSON,
    StreemApiAnalyticEventVignettePortalVisited,
    StreemApiAnalyticEventVignettePortalVisitedFromJSON,
    StreemApiAnalyticEventVignettePortalVisitedFromJSONTyped,
    StreemApiAnalyticEventVignettePortalVisitedToJSON,
    StreemApiAnalyticEventWorkflowAssociated,
    StreemApiAnalyticEventWorkflowAssociatedFromJSON,
    StreemApiAnalyticEventWorkflowAssociatedFromJSONTyped,
    StreemApiAnalyticEventWorkflowAssociatedToJSON,
    StreemApiAnalyticEventWorkflowStarted,
    StreemApiAnalyticEventWorkflowStartedFromJSON,
    StreemApiAnalyticEventWorkflowStartedFromJSONTyped,
    StreemApiAnalyticEventWorkflowStartedToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiAnalyticEvent
 */
export interface StreemApiAnalyticEvent {
    /**
     * 
     * @type {Date}
     * @memberof StreemApiAnalyticEvent
     */
    sentAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiAnalyticEvent
     */
    receivedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEvent
     */
    appPlatform?: StreemApiAnalyticEventAppPlatform;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEvent
     */
    sessionSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEvent
     */
    userSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEvent
     */
    deviceSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEvent
     */
    appSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiAnalyticEvent
     */
    region?: StreemApiAnalyticEventRegion;
    /**
     * 
     * @type {Array<string>}
     * @memberof StreemApiAnalyticEvent
     */
    workflowSids?: Array<string>;
    /**
     * 
     * @type {StreemApiAnalyticEventScreenViewed}
     * @memberof StreemApiAnalyticEvent
     */
    screenViewed?: StreemApiAnalyticEventScreenViewed;
    /**
     * 
     * @type {StreemApiAnalyticEventElementClicked}
     * @memberof StreemApiAnalyticEvent
     */
    elementClicked?: StreemApiAnalyticEventElementClicked;
    /**
     * 
     * @type {object}
     * @memberof StreemApiAnalyticEvent
     */
    appBackgrounded?: object;
    /**
     * 
     * @type {object}
     * @memberof StreemApiAnalyticEvent
     */
    appForegrounded?: object;
    /**
     * 
     * @type {StreemApiAnalyticEventPermissionRequested}
     * @memberof StreemApiAnalyticEvent
     */
    permissionRequested?: StreemApiAnalyticEventPermissionRequested;
    /**
     * 
     * @type {StreemApiAnalyticEventModalViewed}
     * @memberof StreemApiAnalyticEvent
     */
    modalViewed?: StreemApiAnalyticEventModalViewed;
    /**
     * 
     * @type {StreemApiAnalyticEventTooltipViewed}
     * @memberof StreemApiAnalyticEvent
     */
    tooltipViewed?: StreemApiAnalyticEventTooltipViewed;
    /**
     * 
     * @type {StreemApiAnalyticEventLoginAttempted}
     * @memberof StreemApiAnalyticEvent
     */
    loginAttempted?: StreemApiAnalyticEventLoginAttempted;
    /**
     * 
     * @type {StreemApiAnalyticEventIdentifyAttempted}
     * @memberof StreemApiAnalyticEvent
     */
    identifyAttempted?: StreemApiAnalyticEventIdentifyAttempted;
    /**
     * 
     * @type {object}
     * @memberof StreemApiAnalyticEvent
     */
    loggedOut?: object;
    /**
     * 
     * @type {StreemApiAnalyticEventInviteCreated}
     * @memberof StreemApiAnalyticEvent
     */
    inviteCreated?: StreemApiAnalyticEventInviteCreated;
    /**
     * 
     * @type {StreemApiAnalyticEventInviteClicked}
     * @memberof StreemApiAnalyticEvent
     */
    inviteClicked?: StreemApiAnalyticEventInviteClicked;
    /**
     * 
     * @type {StreemApiAnalyticEventConnectionStatusChanged}
     * @memberof StreemApiAnalyticEvent
     */
    connectionStatusChanged?: StreemApiAnalyticEventConnectionStatusChanged;
    /**
     * 
     * @type {StreemApiAnalyticEventResolutionChanged}
     * @memberof StreemApiAnalyticEvent
     */
    resolutionChanged?: StreemApiAnalyticEventResolutionChanged;
    /**
     * 
     * @type {StreemApiAnalyticEventLocalVideoStatsMeasured}
     * @memberof StreemApiAnalyticEvent
     */
    localVideoStatsMeasured?: StreemApiAnalyticEventLocalVideoStatsMeasured;
    /**
     * 
     * @type {StreemApiAnalyticEventLocalAudioStatsMeasured}
     * @memberof StreemApiAnalyticEvent
     */
    localAudioStatsMeasured?: StreemApiAnalyticEventLocalAudioStatsMeasured;
    /**
     * 
     * @type {StreemApiAnalyticEventRemoteVideoStatsMeasured}
     * @memberof StreemApiAnalyticEvent
     */
    remoteVideoStatsMeasured?: StreemApiAnalyticEventRemoteVideoStatsMeasured;
    /**
     * 
     * @type {StreemApiAnalyticEventRemoteAudioStatsMeasured}
     * @memberof StreemApiAnalyticEvent
     */
    remoteAudioStatsMeasured?: StreemApiAnalyticEventRemoteAudioStatsMeasured;
    /**
     * 
     * @type {StreemApiAnalyticEventCameraSubjectChangeRequested}
     * @memberof StreemApiAnalyticEvent
     */
    cameraSubjectChangeRequested?: StreemApiAnalyticEventCameraSubjectChangeRequested;
    /**
     * 
     * @type {StreemApiAnalyticEventCameraSubjectChangeRejected}
     * @memberof StreemApiAnalyticEvent
     */
    cameraSubjectChangeRejected?: StreemApiAnalyticEventCameraSubjectChangeRejected;
    /**
     * 
     * @type {StreemApiAnalyticEventCameraSubjectSet}
     * @memberof StreemApiAnalyticEvent
     */
    cameraSubjectSet?: StreemApiAnalyticEventCameraSubjectSet;
    /**
     * 
     * @type {StreemApiAnalyticEventCameraStatusSet}
     * @memberof StreemApiAnalyticEvent
     */
    cameraStatusSet?: StreemApiAnalyticEventCameraStatusSet;
    /**
     * 
     * @type {StreemApiAnalyticEventMicrophoneStatusSet}
     * @memberof StreemApiAnalyticEvent
     */
    microphoneStatusSet?: StreemApiAnalyticEventMicrophoneStatusSet;
    /**
     * 
     * @type {StreemApiAnalyticEventToolPlaced}
     * @memberof StreemApiAnalyticEvent
     */
    toolPlaced?: StreemApiAnalyticEventToolPlaced;
    /**
     * 
     * @type {StreemApiAnalyticEventToolMoved}
     * @memberof StreemApiAnalyticEvent
     */
    toolMoved?: StreemApiAnalyticEventToolMoved;
    /**
     * 
     * @type {StreemApiAnalyticEventToolRemoved}
     * @memberof StreemApiAnalyticEvent
     */
    toolRemoved?: StreemApiAnalyticEventToolRemoved;
    /**
     * 
     * @type {StreemApiAnalyticEventArtifactRequested}
     * @memberof StreemApiAnalyticEvent
     */
    artifactRequested?: StreemApiAnalyticEventArtifactRequested;
    /**
     * 
     * @type {StreemApiAnalyticEventArtifactRequestRejected}
     * @memberof StreemApiAnalyticEvent
     */
    artifactRequestRejected?: StreemApiAnalyticEventArtifactRequestRejected;
    /**
     * 
     * @type {StreemApiAnalyticEventArtifactCaptured}
     * @memberof StreemApiAnalyticEvent
     */
    artifactCaptured?: StreemApiAnalyticEventArtifactCaptured;
    /**
     * 
     * @type {StreemApiAnalyticEventArtifactFulfilled}
     * @memberof StreemApiAnalyticEvent
     */
    artifactFulfilled?: StreemApiAnalyticEventArtifactFulfilled;
    /**
     * 
     * @type {StreemApiAnalyticEventArtifactUpdated}
     * @memberof StreemApiAnalyticEvent
     */
    artifactUpdated?: StreemApiAnalyticEventArtifactUpdated;
    /**
     * 
     * @type {StreemApiAnalyticEventArtifactDeleted}
     * @memberof StreemApiAnalyticEvent
     */
    artifactDeleted?: StreemApiAnalyticEventArtifactDeleted;
    /**
     * 
     * @type {StreemApiAnalyticEventArtifactViewed}
     * @memberof StreemApiAnalyticEvent
     */
    artifactViewed?: StreemApiAnalyticEventArtifactViewed;
    /**
     * 
     * @type {StreemApiAnalyticEventGuidedExperiencePhaseUpdated}
     * @memberof StreemApiAnalyticEvent
     */
    guidedExperiencePhaseUpdated?: StreemApiAnalyticEventGuidedExperiencePhaseUpdated;
    /**
     * 
     * @type {StreemApiAnalyticEventFavoriteUpdated}
     * @memberof StreemApiAnalyticEvent
     */
    favoriteUpdated?: StreemApiAnalyticEventFavoriteUpdated;
    /**
     * 
     * @type {StreemApiAnalyticEventNoteSaved}
     * @memberof StreemApiAnalyticEvent
     */
    noteSaved?: StreemApiAnalyticEventNoteSaved;
    /**
     * 
     * @type {object}
     * @memberof StreemApiAnalyticEvent
     */
    noteDeleted?: object;
    /**
     * 
     * @type {StreemApiAnalyticEventWorkflowStarted}
     * @memberof StreemApiAnalyticEvent
     */
    workflowStarted?: StreemApiAnalyticEventWorkflowStarted;
    /**
     * 
     * @type {StreemApiAnalyticEventWorkflowAssociated}
     * @memberof StreemApiAnalyticEvent
     */
    workflowAssociated?: StreemApiAnalyticEventWorkflowAssociated;
    /**
     * 
     * @type {StreemApiAnalyticEventVignetteElementSelected}
     * @memberof StreemApiAnalyticEvent
     */
    vignetteElementSelected?: StreemApiAnalyticEventVignetteElementSelected;
    /**
     * 
     * @type {StreemApiAnalyticEventVignetteDrawerExpanded}
     * @memberof StreemApiAnalyticEvent
     */
    vignetteDrawerExpanded?: StreemApiAnalyticEventVignetteDrawerExpanded;
    /**
     * 
     * @type {StreemApiAnalyticEventVignetteFinishChanged}
     * @memberof StreemApiAnalyticEvent
     */
    vignetteFinishChanged?: StreemApiAnalyticEventVignetteFinishChanged;
    /**
     * 
     * @type {StreemApiAnalyticEventVignettePortalVisited}
     * @memberof StreemApiAnalyticEvent
     */
    vignettePortalVisited?: StreemApiAnalyticEventVignettePortalVisited;
    /**
     * 
     * @type {StreemApiAnalyticEventVignetteHotspotClicked}
     * @memberof StreemApiAnalyticEvent
     */
    vignetteHotspotClicked?: StreemApiAnalyticEventVignetteHotspotClicked;
}

export function StreemApiAnalyticEventFromJSON(json: any): StreemApiAnalyticEvent {
    return StreemApiAnalyticEventFromJSONTyped(json, false);
}

export function StreemApiAnalyticEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiAnalyticEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sentAt': !exists(json, 'sent_at') ? undefined : (new Date(json['sent_at'])),
        'receivedAt': !exists(json, 'received_at') ? undefined : (new Date(json['received_at'])),
        'appPlatform': !exists(json, 'app_platform') ? undefined : json['app_platform'],
        'sessionSid': !exists(json, 'session_sid') ? undefined : json['session_sid'],
        'userSid': !exists(json, 'user_sid') ? undefined : json['user_sid'],
        'deviceSid': !exists(json, 'device_sid') ? undefined : json['device_sid'],
        'appSid': !exists(json, 'app_sid') ? undefined : json['app_sid'],
        'region': !exists(json, 'region') ? undefined : json['region'],
        'workflowSids': !exists(json, 'workflow_sids') ? undefined : json['workflow_sids'],
        'screenViewed': !exists(json, 'screen_viewed') ? undefined : StreemApiAnalyticEventScreenViewedFromJSON(json['screen_viewed']),
        'elementClicked': !exists(json, 'element_clicked') ? undefined : StreemApiAnalyticEventElementClickedFromJSON(json['element_clicked']),
        'appBackgrounded': !exists(json, 'app_backgrounded') ? undefined : json['app_backgrounded'],
        'appForegrounded': !exists(json, 'app_foregrounded') ? undefined : json['app_foregrounded'],
        'permissionRequested': !exists(json, 'permission_requested') ? undefined : StreemApiAnalyticEventPermissionRequestedFromJSON(json['permission_requested']),
        'modalViewed': !exists(json, 'modal_viewed') ? undefined : StreemApiAnalyticEventModalViewedFromJSON(json['modal_viewed']),
        'tooltipViewed': !exists(json, 'tooltip_viewed') ? undefined : StreemApiAnalyticEventTooltipViewedFromJSON(json['tooltip_viewed']),
        'loginAttempted': !exists(json, 'login_attempted') ? undefined : StreemApiAnalyticEventLoginAttemptedFromJSON(json['login_attempted']),
        'identifyAttempted': !exists(json, 'identify_attempted') ? undefined : StreemApiAnalyticEventIdentifyAttemptedFromJSON(json['identify_attempted']),
        'loggedOut': !exists(json, 'logged_out') ? undefined : json['logged_out'],
        'inviteCreated': !exists(json, 'invite_created') ? undefined : StreemApiAnalyticEventInviteCreatedFromJSON(json['invite_created']),
        'inviteClicked': !exists(json, 'invite_clicked') ? undefined : StreemApiAnalyticEventInviteClickedFromJSON(json['invite_clicked']),
        'connectionStatusChanged': !exists(json, 'connection_status_changed') ? undefined : StreemApiAnalyticEventConnectionStatusChangedFromJSON(json['connection_status_changed']),
        'resolutionChanged': !exists(json, 'resolution_changed') ? undefined : StreemApiAnalyticEventResolutionChangedFromJSON(json['resolution_changed']),
        'localVideoStatsMeasured': !exists(json, 'local_video_stats_measured') ? undefined : StreemApiAnalyticEventLocalVideoStatsMeasuredFromJSON(json['local_video_stats_measured']),
        'localAudioStatsMeasured': !exists(json, 'local_audio_stats_measured') ? undefined : StreemApiAnalyticEventLocalAudioStatsMeasuredFromJSON(json['local_audio_stats_measured']),
        'remoteVideoStatsMeasured': !exists(json, 'remote_video_stats_measured') ? undefined : StreemApiAnalyticEventRemoteVideoStatsMeasuredFromJSON(json['remote_video_stats_measured']),
        'remoteAudioStatsMeasured': !exists(json, 'remote_audio_stats_measured') ? undefined : StreemApiAnalyticEventRemoteAudioStatsMeasuredFromJSON(json['remote_audio_stats_measured']),
        'cameraSubjectChangeRequested': !exists(json, 'camera_subject_change_requested') ? undefined : StreemApiAnalyticEventCameraSubjectChangeRequestedFromJSON(json['camera_subject_change_requested']),
        'cameraSubjectChangeRejected': !exists(json, 'camera_subject_change_rejected') ? undefined : StreemApiAnalyticEventCameraSubjectChangeRejectedFromJSON(json['camera_subject_change_rejected']),
        'cameraSubjectSet': !exists(json, 'camera_subject_set') ? undefined : StreemApiAnalyticEventCameraSubjectSetFromJSON(json['camera_subject_set']),
        'cameraStatusSet': !exists(json, 'camera_status_set') ? undefined : StreemApiAnalyticEventCameraStatusSetFromJSON(json['camera_status_set']),
        'microphoneStatusSet': !exists(json, 'microphone_status_set') ? undefined : StreemApiAnalyticEventMicrophoneStatusSetFromJSON(json['microphone_status_set']),
        'toolPlaced': !exists(json, 'tool_placed') ? undefined : StreemApiAnalyticEventToolPlacedFromJSON(json['tool_placed']),
        'toolMoved': !exists(json, 'tool_moved') ? undefined : StreemApiAnalyticEventToolMovedFromJSON(json['tool_moved']),
        'toolRemoved': !exists(json, 'tool_removed') ? undefined : StreemApiAnalyticEventToolRemovedFromJSON(json['tool_removed']),
        'artifactRequested': !exists(json, 'artifact_requested') ? undefined : StreemApiAnalyticEventArtifactRequestedFromJSON(json['artifact_requested']),
        'artifactRequestRejected': !exists(json, 'artifact_request_rejected') ? undefined : StreemApiAnalyticEventArtifactRequestRejectedFromJSON(json['artifact_request_rejected']),
        'artifactCaptured': !exists(json, 'artifact_captured') ? undefined : StreemApiAnalyticEventArtifactCapturedFromJSON(json['artifact_captured']),
        'artifactFulfilled': !exists(json, 'artifact_fulfilled') ? undefined : StreemApiAnalyticEventArtifactFulfilledFromJSON(json['artifact_fulfilled']),
        'artifactUpdated': !exists(json, 'artifact_updated') ? undefined : StreemApiAnalyticEventArtifactUpdatedFromJSON(json['artifact_updated']),
        'artifactDeleted': !exists(json, 'artifact_deleted') ? undefined : StreemApiAnalyticEventArtifactDeletedFromJSON(json['artifact_deleted']),
        'artifactViewed': !exists(json, 'artifact_viewed') ? undefined : StreemApiAnalyticEventArtifactViewedFromJSON(json['artifact_viewed']),
        'guidedExperiencePhaseUpdated': !exists(json, 'guided_experience_phase_updated') ? undefined : StreemApiAnalyticEventGuidedExperiencePhaseUpdatedFromJSON(json['guided_experience_phase_updated']),
        'favoriteUpdated': !exists(json, 'favorite_updated') ? undefined : StreemApiAnalyticEventFavoriteUpdatedFromJSON(json['favorite_updated']),
        'noteSaved': !exists(json, 'note_saved') ? undefined : StreemApiAnalyticEventNoteSavedFromJSON(json['note_saved']),
        'noteDeleted': !exists(json, 'note_deleted') ? undefined : json['note_deleted'],
        'workflowStarted': !exists(json, 'workflow_started') ? undefined : StreemApiAnalyticEventWorkflowStartedFromJSON(json['workflow_started']),
        'workflowAssociated': !exists(json, 'workflow_associated') ? undefined : StreemApiAnalyticEventWorkflowAssociatedFromJSON(json['workflow_associated']),
        'vignetteElementSelected': !exists(json, 'vignette_element_selected') ? undefined : StreemApiAnalyticEventVignetteElementSelectedFromJSON(json['vignette_element_selected']),
        'vignetteDrawerExpanded': !exists(json, 'vignette_drawer_expanded') ? undefined : StreemApiAnalyticEventVignetteDrawerExpandedFromJSON(json['vignette_drawer_expanded']),
        'vignetteFinishChanged': !exists(json, 'vignette_finish_changed') ? undefined : StreemApiAnalyticEventVignetteFinishChangedFromJSON(json['vignette_finish_changed']),
        'vignettePortalVisited': !exists(json, 'vignette_portal_visited') ? undefined : StreemApiAnalyticEventVignettePortalVisitedFromJSON(json['vignette_portal_visited']),
        'vignetteHotspotClicked': !exists(json, 'vignette_hotspot_clicked') ? undefined : StreemApiAnalyticEventVignetteHotspotClickedFromJSON(json['vignette_hotspot_clicked']),
    };
}

export function StreemApiAnalyticEventToJSON(value?: StreemApiAnalyticEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sent_at': value.sentAt === undefined ? undefined : (value.sentAt.toISOString()),
        'received_at': value.receivedAt === undefined ? undefined : (value.receivedAt.toISOString()),
        'app_platform': value.appPlatform,
        'session_sid': value.sessionSid,
        'user_sid': value.userSid,
        'device_sid': value.deviceSid,
        'app_sid': value.appSid,
        'region': value.region,
        'workflow_sids': value.workflowSids,
        'screen_viewed': StreemApiAnalyticEventScreenViewedToJSON(value.screenViewed),
        'element_clicked': StreemApiAnalyticEventElementClickedToJSON(value.elementClicked),
        'app_backgrounded': value.appBackgrounded,
        'app_foregrounded': value.appForegrounded,
        'permission_requested': StreemApiAnalyticEventPermissionRequestedToJSON(value.permissionRequested),
        'modal_viewed': StreemApiAnalyticEventModalViewedToJSON(value.modalViewed),
        'tooltip_viewed': StreemApiAnalyticEventTooltipViewedToJSON(value.tooltipViewed),
        'login_attempted': StreemApiAnalyticEventLoginAttemptedToJSON(value.loginAttempted),
        'identify_attempted': StreemApiAnalyticEventIdentifyAttemptedToJSON(value.identifyAttempted),
        'logged_out': value.loggedOut,
        'invite_created': StreemApiAnalyticEventInviteCreatedToJSON(value.inviteCreated),
        'invite_clicked': StreemApiAnalyticEventInviteClickedToJSON(value.inviteClicked),
        'connection_status_changed': StreemApiAnalyticEventConnectionStatusChangedToJSON(value.connectionStatusChanged),
        'resolution_changed': StreemApiAnalyticEventResolutionChangedToJSON(value.resolutionChanged),
        'local_video_stats_measured': StreemApiAnalyticEventLocalVideoStatsMeasuredToJSON(value.localVideoStatsMeasured),
        'local_audio_stats_measured': StreemApiAnalyticEventLocalAudioStatsMeasuredToJSON(value.localAudioStatsMeasured),
        'remote_video_stats_measured': StreemApiAnalyticEventRemoteVideoStatsMeasuredToJSON(value.remoteVideoStatsMeasured),
        'remote_audio_stats_measured': StreemApiAnalyticEventRemoteAudioStatsMeasuredToJSON(value.remoteAudioStatsMeasured),
        'camera_subject_change_requested': StreemApiAnalyticEventCameraSubjectChangeRequestedToJSON(value.cameraSubjectChangeRequested),
        'camera_subject_change_rejected': StreemApiAnalyticEventCameraSubjectChangeRejectedToJSON(value.cameraSubjectChangeRejected),
        'camera_subject_set': StreemApiAnalyticEventCameraSubjectSetToJSON(value.cameraSubjectSet),
        'camera_status_set': StreemApiAnalyticEventCameraStatusSetToJSON(value.cameraStatusSet),
        'microphone_status_set': StreemApiAnalyticEventMicrophoneStatusSetToJSON(value.microphoneStatusSet),
        'tool_placed': StreemApiAnalyticEventToolPlacedToJSON(value.toolPlaced),
        'tool_moved': StreemApiAnalyticEventToolMovedToJSON(value.toolMoved),
        'tool_removed': StreemApiAnalyticEventToolRemovedToJSON(value.toolRemoved),
        'artifact_requested': StreemApiAnalyticEventArtifactRequestedToJSON(value.artifactRequested),
        'artifact_request_rejected': StreemApiAnalyticEventArtifactRequestRejectedToJSON(value.artifactRequestRejected),
        'artifact_captured': StreemApiAnalyticEventArtifactCapturedToJSON(value.artifactCaptured),
        'artifact_fulfilled': StreemApiAnalyticEventArtifactFulfilledToJSON(value.artifactFulfilled),
        'artifact_updated': StreemApiAnalyticEventArtifactUpdatedToJSON(value.artifactUpdated),
        'artifact_deleted': StreemApiAnalyticEventArtifactDeletedToJSON(value.artifactDeleted),
        'artifact_viewed': StreemApiAnalyticEventArtifactViewedToJSON(value.artifactViewed),
        'guided_experience_phase_updated': StreemApiAnalyticEventGuidedExperiencePhaseUpdatedToJSON(value.guidedExperiencePhaseUpdated),
        'favorite_updated': StreemApiAnalyticEventFavoriteUpdatedToJSON(value.favoriteUpdated),
        'note_saved': StreemApiAnalyticEventNoteSavedToJSON(value.noteSaved),
        'note_deleted': value.noteDeleted,
        'workflow_started': StreemApiAnalyticEventWorkflowStartedToJSON(value.workflowStarted),
        'workflow_associated': StreemApiAnalyticEventWorkflowAssociatedToJSON(value.workflowAssociated),
        'vignette_element_selected': StreemApiAnalyticEventVignetteElementSelectedToJSON(value.vignetteElementSelected),
        'vignette_drawer_expanded': StreemApiAnalyticEventVignetteDrawerExpandedToJSON(value.vignetteDrawerExpanded),
        'vignette_finish_changed': StreemApiAnalyticEventVignetteFinishChangedToJSON(value.vignetteFinishChanged),
        'vignette_portal_visited': StreemApiAnalyticEventVignettePortalVisitedToJSON(value.vignettePortalVisited),
        'vignette_hotspot_clicked': StreemApiAnalyticEventVignetteHotspotClickedToJSON(value.vignetteHotspotClicked),
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemApiAnalyticEventAppPlatform {
    IOS = 'APP_PLATFORM_IOS',
    ANDROID = 'APP_PLATFORM_ANDROID',
    WEB = 'APP_PLATFORM_WEB',
    INVALID = 'APP_PLATFORM_INVALID'
}
/**
* @export
* @enum {string}
*/
export enum StreemApiAnalyticEventRegion {
    GLOBAL = 'REGION_GLOBAL',
    US = 'REGION_US',
    CA = 'REGION_CA',
    INVALID = 'REGION_INVALID'
}


