import { Disposition, RefundReason } from '@streem/sdk-core';
import { AppText, Box, Row, styled } from '@streem/ui-react';
import { streem } from 'streem-sdk-protobuf';
import { FC, useLayoutEffect, useMemo } from 'react';
import { LabelSearchInput } from './call_diagnosis';

interface DispositionDetailSelectionProps {
    formValues: {
        disposition: Disposition | undefined;
        shouldRefund?: boolean;
        refundReason?: RefundReason;
        training?: boolean;
    };
    dispositionOptions: streem.api.CompanyRoomOutcomeConfig.ISection;
    customFieldOptions?: {
        refund?: streem.api.CompanyRoomOutcomeConfig.ISection;
        training?: streem.api.CompanyRoomOutcomeConfig.ISection;
    };
    handleSetDisposition: (disp: Disposition) => void;
    handleToggleShouldRefund: () => void;
    handleSetRefundReason: (reason: { code: string; label: string }) => void;
    handleToggleTrainingCall: () => void;
}

export const DROPPED_RECONNECT = 'dropped - reconnect';

export const DispositionDetailSection: FC<DispositionDetailSelectionProps> = ({
    formValues,
    dispositionOptions,
    customFieldOptions,
    handleSetDisposition,
    handleToggleShouldRefund,
    handleSetRefundReason,
    handleToggleTrainingCall,
}) => {
    const {
        disposition,
        shouldRefund = undefined,
        refundReason = undefined,
        training = undefined,
    } = formValues;
    function findObjectIndex<T extends { code: string; label: string }>(object: T, array: T[]) {
        const index = array.findIndex(item => item.code === object.code);
        return index;
    }
    const updateRequestRefundCheckbox = (dispositionCode: string, prevDispositionCode: string) => {
        if (!shouldRefund && dispositionCode === DROPPED_RECONNECT) {
            // Check
            handleToggleShouldRefund();
        }
        if (prevDispositionCode === DROPPED_RECONNECT && shouldRefund) {
            // Uncheck
            handleToggleShouldRefund();
        }
    };
    function getInitialValue<T extends { code: string; label: string }>(object: T, array: T[]) {
        const objectIndexMatch = findObjectIndex(object, array);
        if (objectIndexMatch !== -1) {
            const objectMatch = array[objectIndexMatch];
            return {
                label: objectMatch.label,
                value: objectMatch.code,
            };
        } else {
            return {
                label: '',
                value: '',
            };
        }
    }
    const initialRefundReason = useMemo(() => {
        if (shouldRefund && refundReason) {
            return getInitialValue<RefundReason>(
                refundReason,
                customFieldOptions.refund?.options.map(option => ({
                    code: option.entry.code,
                    label: option.entry.label,
                })),
            );
        } else {
            return { value: '', label: '' };
        }
        // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    }, [shouldRefund, refundReason]);

    useLayoutEffect(() => {
        const dispositionSearchInput = document.querySelector(
            '[aria-label="call disposition options"]',
        ) as HTMLInputElement;
        dispositionSearchInput.focus();
    }, []);

    useLayoutEffect(() => {
        if (shouldRefund) {
            const refundReasonSearchInput = document.querySelector(
                '[aria-label="refund reason options"]',
            ) as HTMLInputElement;
            refundReasonSearchInput.focus();
        } else {
            const dispositionSearchInput = document.querySelector(
                '[aria-label="call disposition options"]',
            ) as HTMLInputElement;
            dispositionSearchInput.focus();
        }
    }, [shouldRefund]);

    return (
        <Box width="100%">
            <LabelSearchInput
                ariaLabel="call disposition options"
                label="Call Disposition"
                placeholder="Search here"
                handleChange={val => {
                    const newDisposition = {
                        code: val.value,
                        label: val.label,
                    };
                    updateRequestRefundCheckbox(newDisposition.code, disposition.code);
                    handleSetDisposition(newDisposition);
                }}
                options={dispositionOptions.options.map(d => ({
                    value: d.entry.code,
                    label: d.entry.label,
                }))}
                initialValue={getInitialValue<Disposition>(
                    disposition,
                    dispositionOptions.options.map(option => ({
                        code: option.entry.code,
                        label: option.entry.label,
                    })),
                )}
            />

            {customFieldOptions?.refund?.options.length > 0 && (
                <>
                    <Row>
                        <BlueCheckBox
                            id="request refund"
                            dataTestId="request-refund-checkbox"
                            description="Request Refund"
                            checked={shouldRefund}
                            handleChange={() => handleToggleShouldRefund()}
                        />
                    </Row>

                    <Box style={{ visibility: shouldRefund ? 'visible' : 'hidden' }}>
                        <LabelSearchInput
                            ariaLabel="refund reason options"
                            placeholder="Search here"
                            label="Reason for Refund"
                            handleChange={val => {
                                handleSetRefundReason({
                                    code: val.value,
                                    label: val.label,
                                });
                            }}
                            options={customFieldOptions.refund?.options.map(r => ({
                                value: r.entry.code,
                                label: r.entry.label,
                            }))}
                            initialValue={initialRefundReason}
                        />
                    </Box>
                </>
            )}

            {customFieldOptions?.training && (
                <Row>
                    <BlueCheckBox
                        id="training call"
                        dataTestId="training-call-checkbox"
                        description="Use this call for training sessions"
                        checked={training}
                        handleChange={() => handleToggleTrainingCall()}
                    />
                </Row>
            )}
        </Box>
    );
};

const BaseInput = styled.input`
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 2px solid ${props => props.theme.colors.grey70};
    text-decoration: none;
    margin-right: 8px;
    margin-left: 8px;
    position: relative;
    :checked {
        border: none;
    }
    :checked::before {
        position: absolute;
        border-radius: 4px;
        width: 24px;
        height: 24px;
        content: '';
        background-color: ${props => props.theme.colors.azure};
    }
    :checked::after {
        content: url('data:image/svg+xml; utf8, <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0711 0.179183C14.5244 0.494573 14.6363 1.11778 14.3209 1.57115L6.32091 13.0712C6.1518 13.3142 5.88364 13.4699 5.58868 13.4961C5.29372 13.5224 5.00229 13.4166 4.79289 13.2072L0.292893 8.7072C-0.0976311 8.31667 -0.0976311 7.68351 0.292893 7.29298C0.683417 6.90246 1.31658 6.90246 1.70711 7.29298L5.36178 10.9477L12.6791 0.429024C12.9945 -0.0243495 13.6177 -0.136207 14.0711 0.179183Z" fill="white"/></svg>');
        display: block;
        position: absolute;
        left: 5px;
        top: 5px;
    }
    :hover {
        cursor: pointer;
    }
`;

interface BlueCheckBoxProps {
    id: string;
    description: string;
    handleChange: () => void;
    checked: boolean;
    dataTestId?: string;
}

const BlueCheckBox: React.FC<BlueCheckBoxProps> = ({
    id,
    description,
    handleChange,
    checked,
    dataTestId,
}) => {
    return (
        <Row justifyContent="center" alignItems="center" my={3}>
            <BaseInput
                tabIndex={0}
                type="checkbox"
                id={id}
                data-testid={dataTestId}
                checked={checked}
                onChange={() => handleChange()}
            />
            <label htmlFor={id}>
                <AppText semibold>{description}</AppText>
            </label>
        </Row>
    );
};
