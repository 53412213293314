import { useState, useCallback } from 'react';
import { IconButton, Switch, ToastTypes, Row, useTheme } from '@streem/ui-react';
import { useCompanySettings } from '../../../hooks/company_settings_hooks';
import { StringSettingLabel } from './field.styles';
import appLogger from '../../../util/logging/app_logger';
import { kebabCase } from '../../../util/strings';
import { StringSettingInput } from './string_setting_input';
import { useGlobalStore } from '../../../hooks/use_global_context';
import { Setting } from '../../../util/company_settings';

interface Props {
    setting: Setting;
    monochrome?: boolean;
}

export function StringSettingField({ setting, monochrome }: Props) {
    const theme = useTheme();
    const companySettings = useCompanySettings();
    const { uiStore } = useGlobalStore();
    const [editValue, setEditValue] = useState(false);
    const onSave = useCallback(
        async (value: string) => {
            try {
                await companySettings?.updateSetting(setting.name, value.trim());
            } catch (error) {
                appLogger.error('Error Updating Setting', setting.name, error);
                uiStore.addToast({
                    content: `Uh oh, we were unable to save the changes to '${setting.label}' at this time.\n\nPlease try refreshing the page and trying again. You may also contact our support staff for assistance.`,
                    flavor: ToastTypes.ERROR,
                    id: `setting-${setting.label}-fail`,
                });
            } finally {
                setEditValue(false);
            }
        },
        [companySettings, setting, uiStore],
    );
    const displayLabel = setting.value || setting.label;
    const hasExistingValue = setting.value !== undefined && setting.value.length > 0;
    return (
        <Row style={{ alignItems: 'center', width: editValue ? '100%' : '' }}>
            {hasExistingValue && !editValue && (
                <>
                    <StringSettingLabel monochrome={monochrome} title={displayLabel}>
                        {displayLabel}
                    </StringSettingLabel>
                    <IconButton
                        fill={monochrome ? theme.colors.phosphorGreen : undefined}
                        iconName="EditIcon"
                        label={`Edit ${setting.label}`}
                        onClick={() => {
                            setEditValue(true);
                        }}
                        background="transparent"
                        data-testid={`${kebabCase(setting.name)}-edit-icon`}
                    />
                </>
            )}
            {editValue && (
                <StringSettingInput
                    name={setting.name}
                    label={setting.label}
                    value={setting.value || ''}
                    onCancel={() => setEditValue(false)}
                    onSave={onSave}
                    monochrome={monochrome}
                />
            )}

            <span style={{ marginBottom: editValue ? '20px' : '' }}>
                <Switch
                    onToggle={() => {
                        if (!hasExistingValue && !editValue) {
                            setEditValue(true);
                        } else if (!hasExistingValue && editValue) {
                            setEditValue(false);
                        } else {
                            onSave('');
                        }
                    }}
                    disabled={editValue}
                    checked={editValue || hasExistingValue}
                    label={`Toggle ${setting.label} Usage`}
                    data-testid={`${kebabCase(setting.name)}-toggle`}
                    monochrome={monochrome}
                />
            </span>
        </Row>
    );
}
