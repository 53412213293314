import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useObserver } from 'mobx-react';
import { CallNotificationListener, InCall } from '@streem/sdk-react';
import { useGlobalStore } from '../../hooks/use_global_context';
import { useSurveys } from '../../hooks/use_surveys';
import { useGetCompanyStore } from '../../hooks/detail_store_hooks';
import { CallNotification } from './call_notification';

export function InCallListener(): JSX.Element {
    const { sdkStore, authStore, companySettingsStore } = useGlobalStore();
    const history = useHistory();
    const displaySurveys = useSurveys();
    const companyStore = useGetCompanyStore();

    const getRoomCallLogHistory = useCallback(() => {
        return sdkStore.getRoomCallLogHistory();
    }, [sdkStore]);

    return useObserver(() => {
        const gpsEnabled = companySettingsStore.gpsEnabled;
        const arEnabled = companySettingsStore.webAREnabled;
        const hideFormattedAddress = companySettingsStore.hideFormattedAddressEnabled;
        const faceToFaceModeEnabled = companySettingsStore.faceToFaceModeEnabled;
        const experimentalFeatures = companySettingsStore.experimentalFeatures;
        const screenshareEnabled = companySettingsStore.screenshareEnabled;
        const expertAvailabilityEnabled = companySettingsStore.expertAvailabilityEnabled;
        const anonymousShareEnabled = companySettingsStore.anonymousShareEnabled;
        const allowPauseResumeRecording = companySettingsStore.allowPauseResumeRecording;
        const chimeNetworkAdaptationEnabled = companySettingsStore.chimeNetworkAdaptationEnabled;
        const chimeLowBandwidthOptimizationEnabled =
            companySettingsStore.chimeLowBandwidthOptimizationEnabled;
        const isEmbed = history.location.pathname.startsWith('/embed');

        if (authStore.isAdmin || authStore.isAgent) {
            return (
                <>
                    {!sdkStore.roomSession?.current && sdkStore.userSession.current && (
                        <CallNotificationListener
                            userSession={sdkStore.userSession.current}
                            callConfig={{
                                faceToFaceModeEnabled,
                                experimentalFeatures,
                                videoPlatformNetworkAdaptationEnabled:
                                    chimeNetworkAdaptationEnabled,
                                chimeLowBandwidthOptimizationEnabled,
                            }}
                            autoAcceptInvites={isEmbed}
                            SystemNotificationComponent={CallNotification}
                        />
                    )}
                    {sdkStore.roomSession?.current && (
                        <InCall
                            getRoomCallLogHistory={getRoomCallLogHistory}
                            toolSettings={{
                                gpsEnabled,
                                arEnabled,
                                hideFormattedAddress,
                                screenshareEnabled,
                                faceToFaceModeEnabled,
                                expertAvailabilityEnabled,
                                anonymousShareEnabled,
                                allowPauseResumeRecording,
                                isEmbed,
                            }}
                            companyInfo={{ companyName: companyStore.result.name }}
                            roomSession={sdkStore.roomSession.current}
                            userSession={sdkStore.userSession.current}
                            onEnd={async roomId => {
                                if (isEmbed) {
                                    history.push(
                                        `/embed/${roomId}/postcall` + window.location.search,
                                    );
                                } else {
                                    if (!companySettingsStore.roomOutcomeFeatureEnabled) {
                                        await displaySurveys(authStore.userId, roomId.toString());
                                    }
                                    history.push(`/mycalls/${roomId}`);
                                }
                            }}
                        />
                    )}
                </>
            );
        } else {
            return <></>;
        }
    });
}
