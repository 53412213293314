import React, { ChangeEvent, useCallback, useState } from 'react';
import { Container } from './page_header.styles';
import {
    Header,
    IconButton,
    RoundedSearch,
    IconNames,
    AppText,
    Avatar,
    Flex,
    Column,
    Box,
    useTheme,
    Tooltip,
} from '@streem/ui-react';
import { recordTooltipViewed } from '@streem/analytics';

interface Props {
    title?: string;
    subtitle?: string;
    avatarSrc?: string;
    avatarDisplayName?: string;
    searchPlaceholder?: string;
    onSearch?: (e: ChangeEvent<HTMLInputElement>) => void;
    iconName?: IconNames;
    iconContext?: string;
    handleClick?: () => void;
    defaultValue?: string;
}

const PageHeader: React.FC<Props> = ({
    title,
    subtitle,
    onSearch,
    iconName,
    iconContext,
    handleClick,
    searchPlaceholder = 'Search',
    avatarSrc = '',
    avatarDisplayName = '',
    defaultValue,
}) => {
    const theme = useTheme();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = 'blue-plus-team-icon-tooltip';

    const handleMouseEnter = useCallback(() => {
        setShowTooltip(true);
        recordTooltipViewed(tooltipId);
    }, []);

    const handleMouseLeave = useCallback(() => {
        setShowTooltip(false);
    }, []);
    return (
        <Container hasIcon={Boolean(iconName)}>
            <Column>
                <Flex>
                    {avatarSrc?.length || avatarDisplayName?.length ? (
                        <Box paddingRight={`${theme.space[4]}px`}>
                            <Avatar
                                src={avatarSrc}
                                displayName={avatarDisplayName}
                                size={'medium'}
                            />
                        </Box>
                    ) : null}
                    <Column>
                        <Box paddingBottom={`${theme.space[2]}px`}>
                            <Header style={{ wordBreak: 'break-word' }} size="xxlarge">
                                {title}
                            </Header>
                        </Box>
                        {subtitle && (
                            <AppText as="h4" size="mediumLarge" headingFontFamily>
                                {subtitle}
                            </AppText>
                        )}
                    </Column>
                </Flex>
            </Column>
            {onSearch && (
                <Column>
                    <RoundedSearch
                        data-testid="admin-header-search"
                        name={searchPlaceholder}
                        label={searchPlaceholder}
                        placeholder={searchPlaceholder}
                        onChange={onSearch}
                        defaultValue={defaultValue}
                    />
                </Column>
            )}
            {iconName && (
                <Column alignItems={!onSearch ? 'flex-end' : 'center'} justifyContent={'center'}>
                    <Box position={onSearch ? undefined : 'absolute'}>
                        <Tooltip
                            message={iconContext}
                            showTooltip={showTooltip}
                            placement="bottom"
                            fallbackPlacements={['bottom']}
                        >
                            <IconButton
                                data-testid="admin-header-button"
                                iconName={iconName}
                                label={iconName}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                background={'azure'}
                                fill={'white'}
                                onClick={handleClick!}
                            />
                        </Tooltip>
                    </Box>
                </Column>
            )}
        </Container>
    );
};

export default PageHeader;
