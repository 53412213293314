import React, { ReactElement, useCallback, useState, useLayoutEffect } from 'react';
import {
    AppText,
    CaretIcon,
    ContentAlignment,
    ListView,
    ListViewProps,
    SortOrder,
    styled,
    TableColumn,
    TableDataRecord,
    Theme,
} from '@streem/ui-react';
import { ListStorePaginated } from '../../stores/list_store_paginated';
import { LoadMoreButton } from './load_more_button';

interface Props {
    store: ListStorePaginated<any, any, any>;
    initialScrollPosition?: number;
}
interface TableState {
    sortedColumnKey?: string;
    sortOrder: SortOrder;
    initialSort: boolean;
}

export function ListViewWithPagination<T extends TableDataRecord>({
    store,
    initialScrollPosition,
    onSort = () => {},
    gridTemplateColumns,
    columns,
    ...rest
}: Props & ListViewProps<T>): ReactElement | null {
    const viewPortRef = React.useRef<HTMLDivElement>(null);

    const [sortState, setSortState] = useState<TableState>({
        sortedColumnKey: 'undefined',
        sortOrder: 'OFF',
        initialSort: true,
    });
    const onColumnSort = useCallback(
        (columnKey: string, sortOrder: SortOrder) => {
            const newState = {
                sortedColumnKey: sortOrder !== 'OFF' ? columnKey : undefined,
                sortOrder: sortOrder,
                initialSort: false,
            };

            setSortState(newState);

            if (onSort) {
                onSort(columnKey, sortOrder);
            }
        },
        [setSortState, onSort],
    );
    const setSort = (column: TableColumn<T>) => {
        if (column.key === sortState.sortedColumnKey) {
            return sortState.sortOrder;
        } else if (column.initialSortColumn && sortState.initialSort) {
            return 'ASC';
        }
        return 'OFF';
    };
    const [showShadow, setShowShadow] = useState<boolean>(false);

    useLayoutEffect(() => {
        if (viewPortRef && viewPortRef.current) {
            viewPortRef.current.scrollTop = initialScrollPosition ?? 0;
        }
    }, [initialScrollPosition]);

    const handleScroll = (event: React.SyntheticEvent) => {
        // Save scroll position in store to handle return to page position
        store.setScrollPosition(event.currentTarget.scrollTop);
        const notScrolled = event.currentTarget.scrollTop === 0;
        if (!notScrolled && !showShadow) {
            setShowShadow(true);
        } else if (notScrolled && showShadow) {
            setShowShadow(false);
        } else {
            return;
        }
    };
    return (
        <>
            <ListViewGrid
                columns={gridTemplateColumns}
                style={{ boxShadow: showShadow ? Theme.shadow.bottom : '' }}
            >
                {columns.map(column => (
                    <ColumnHeader
                        sortable={column.sortable}
                        key={column.key}
                        column={column}
                        onSort={onColumnSort}
                        initialSortOrder={'ASC'}
                        initialSortColumn={column.initialSortColumn}
                        sortOrder={setSort(column)}
                        contentJustify={column.contentJustify}
                    />
                ))}
            </ListViewGrid>
            <ScrollableView onScroll={handleScroll} ref={viewPortRef}>
                <ListView columns={columns} gridTemplateColumns={gridTemplateColumns} {...rest} />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '40px 0',
                    }}
                >
                    {store.hasNextPageToken && (
                        <LoadMoreButton
                            sourceTestId={rest['data-testid']}
                            loadingMore={store.loadingMore}
                            onClick={async () => {
                                const scrollPos = viewPortRef.current!.scrollTop;
                                await store.fetchNextPage();
                                viewPortRef.current!.scrollTop = scrollPos;
                            }}
                        />
                    )}
                </div>
            </ScrollableView>
        </>
    );
}

const ScrollableView = styled.div(() => ({
    padding: '0 10px',
    overflowX: 'auto',
}));

const ListViewGrid = styled.div<{ columns?: string }>(({ columns }) => ({
    display: 'grid',
    gridTemplateColumns: columns ?? '1fr auto auto',
    margin: '0 10px',
    border: `1px solid ${Theme.colors.grey10}`,
}));
interface ColumnHeaderProps<T extends TableDataRecord> {
    sortOrder: SortOrder;
    column: TableColumn<T>;
    onSort?: (columnKey: string, sortOrder: SortOrder) => void;
    sortable?: boolean;
    initialSortOrder?: SortOrder;
    initialSortColumn?: boolean;
    contentJustify?: ContentAlignment;
}

export function ColumnHeader<T extends TableDataRecord>({
    sortOrder,
    column,
    onSort,
    sortable,
    contentJustify,
}: ColumnHeaderProps<T>) {
    const toggleSort = useCallback(
        (e: any) => {
            e.stopPropagation();
            const newSortOrder = sortOrder === 'DESC' ? 'ASC' : 'DESC';

            if (sortable && onSort) {
                onSort(column.key, newSortOrder);
            }
        },
        [sortOrder, onSort, column, sortable],
    );
    return (
        <HeaderCell alignment={contentJustify} data-testid={`col-header-${column.key}`}>
            <HeaderCellButton
                sortable={false}
                onClick={toggleSort}
                disabled={!sortable}
                data-testid={`col-header-sort-button-${column.key}`}
                sortOrder={sortOrder}
            >
                <AppText
                    uppercase={true}
                    style={{ display: 'inline', color: 'black', opacity: 0.7 }}
                >
                    {column.name}
                </AppText>
                {sortable && (
                    <SortCaretContainer
                        aria-label={`Click to sort in ${
                            sortOrder !== 'DESC' ? 'descending' : 'ascending'
                        } order`}
                    >
                        <CaretIcon
                            color={sortOrder === 'OFF' ? Theme.colors.grey20 : Theme.colors.grey90}
                            style={{ transform: sortOrder === 'ASC' ? 'rotate(180deg)' : '' }}
                        />
                    </SortCaretContainer>
                )}
            </HeaderCellButton>
        </HeaderCell>
    );
}

const HeaderCell = styled.div<{ alignment?: ContentAlignment }>(({ alignment }) => ({
    display: 'flex',
    justifyContent: alignment ? alignment : 'flex-start',
    position: 'relative',
    textAlign: 'left',
    paddingBottom: '.25rem',
    paddingLeft: Theme.space[4],
    paddingRight: '5px',
    fontWeight: 'normal',
    userSelect: 'none',
    lineHeight: '30px',
}));

const SortCaretContainer = styled.span({
    marginLeft: '10px',
});

const HeaderCellButton = styled.button<{ sortOrder?: SortOrder; sortable: boolean }>(
    ({ sortable, disabled }) => ({
        background: 'none',
        border: 0,
        padding: 0,
        alignItems: 'center',
        display: 'flex',
        minHeight: '30px',
        marginTop: '3px',
        cursor: !disabled || sortable ? 'pointer' : 'auto',
    }),
    ({ sortOrder }) => {
        return sortOrder === 'OFF'
            ? {
                  '>span': {
                      opacity: '0',
                      transition: 'all .3s',
                  },
                  ':hover >span': {
                      opacity: '100%',
                      transition: 'all .3s',
                  },
              }
            : {};
    },
);
